<template>
    <lazy-until-loaded-tab
        tab-id="spatial_references"
        title="Spatial References"
        v-bind="$attrs"
    >
        <template #tabBody>
            <b-button
                :href="createUrl"
                class="float-right my-2"
                variant="primary"
            >
                Add Spatial Reference
            </b-button>

            <spatial-reference-list :api-url="apiUrl" />
        </template>
    </lazy-until-loaded-tab>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import LazyUntilLoadedTab from '../../LazyUntilLoadedTab.vue';

import SpatialReferenceList from './SpatialReferenceList.vue';

export default Vue.component('spatial-references-tab', {
    components: { LazyUntilLoadedTab, SpatialReferenceList },
    inheritAttrs: false,
    props: {
        apiUrl: VueTypes.string.isRequired,
        createUrl: VueTypes.string.isRequired,
    },
});
</script>
