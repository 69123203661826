var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { vid: _vm.name, name: _vm.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (validationContext) {
          return [
            _c(
              "b-form-group",
              {
                attrs: {
                  "label-for": _vm.id,
                  "invalid-feedback": validationContext.errors[0],
                  description: _vm.helpText,
                  "label-cols-md": "4",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v("\n            " + _vm._s(_vm.label) + " "),
                          _vm.required ? _c("required-asterisk") : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _c(
                  _vm.inputType,
                  {
                    ref: "checkboxInput",
                    tag: "component",
                    class: { "checkbox-group": _vm.multiple },
                    attrs: {
                      id: _vm.id,
                      name: _vm.name,
                      required: _vm.required,
                      disabled: _vm.disabled,
                      options: _vm.selectOptions,
                      state: _vm.getValidationState(validationContext),
                      stacked: _vm.multiple,
                      "text-field": "label",
                    },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  },
                  [
                    _c(
                      "b-form-invalid-feedback",
                      {
                        attrs: {
                          state: _vm.getValidationState(validationContext),
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(validationContext.errors[0]) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }