<template>
    <b-form
        :id="`${fieldName}-filter`"
        class="filter-container"
        @submit.prevent="() => $emit('apply-search-filter')"
    >
        <b-form-input
            :placeholder="`Enter ${data.label}...`"
            :value="value"
            @input="(value) => $emit('input', value)"
        />
        <b-button
            :key="`${fieldName}-button`"
            class="filter-button"
            type="submit"
        >
            <b-icon-search />
        </b-button>
    </b-form>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

export default Vue.component('header-search-field', {
    props: {
        fieldName: VueTypes.string.isRequired,
        data: VueTypes.object.isRequired,
        value: VueTypes.any,
    },
});
</script>
