<template>
    <div>
        <b-dropdown text="Dropdown Button" class="float-right my-2 ml-1" right>
            <template #button-content>
                Bulk Action
                <b-icon-pencil />
            </template>
            <b-dropdown-item-button
                data-toggle="modal"
                :data-target="`#${modalId}`"
                data-backdrop="false"
            >
                Disable Selected Users
            </b-dropdown-item-button>
        </b-dropdown>
        <b-button class="float-right my-2 mx-1" @click="selectAll">
            Select All
        </b-button>
        <b-button class="float-right my-2 mx-1" @click="deselectAll">
            Deselect All
        </b-button>

        <dialog-box
            button-label="Disable"
            dismiss-button-label="Cancel"
            modal-title="Warning"
            button-variant="danger"
            :modal-id="modalId"
            @action-accepted="bulkDisableUsers"
        >
            <template #body>
                <p>Are you sure you want to disable the following users?</p>
                <div class="confirm-selected-users-container">
                    <ul>
                        <li v-for="item in selectedItems" :key="item.pk">
                            {{ item.username }}
                        </li>
                    </ul>
                </div>
            </template>
        </dialog-box>

        <paginated-api-table
            ref="paginatedApiTable"
            :api-url="apiUrl"
            :fields="tableFields"
            :initial-count="initialCount"
            :initial-data-element-id="initialDataElementId"
            :initial-num-pages="initialNumPages"
            :initial-page-size="initialPageSize"
            selectable
            sticky-header
            :refresh="refreshTable"
            @refreshed="refreshTable = false"
            @row-selected="onRowSelected"
        >
            <template #username="cellProps">
                <a :href="cellProps.item.update_url">
                    {{ cellProps.item.username }}
                </a>
            </template>

            <template #corporation="cellProps">
                {{ cellProps.item.corporation_name }}
            </template>

            <template #is_create_admin="cellProps">
                <component
                    :is="getBooleanIcon(cellProps.item, 'is_create_admin')"
                    :variant="
                        getBooleanVariant(cellProps.item, 'is_create_admin')
                    "
                    aria-hidden="true"
                />
            </template>

            <template #date_joined="cellProps">
                {{ getDateFromDatetimeString(cellProps.item.date_joined) }}
            </template>

            <template #last_login="cellProps">
                {{ getDateFromDatetimeString(cellProps.item.last_login) }}
            </template>

            <template #is_active="cellProps">
                <component
                    :is="getBooleanIcon(cellProps.item, 'is_active')"
                    :variant="getBooleanVariant(cellProps.item, 'is_active')"
                    aria-hidden="true"
                />
            </template>
        </paginated-api-table>
    </div>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { createNamespacedHelpers } from 'vuex';

import { csrfHeader } from '../../cookies';
import {
    MODULE_MESSAGES,
    MUTATION_ADD_MESSAGE,
} from '../../store/modules/messages';
import PaginatedApiTable from '../PaginatedApiTable.vue';
import {
    getBooleanIcon,
    getBooleanVariant,
    getDateFromDatetimeString,
} from '../../utils';

const { mapMutations } = createNamespacedHelpers(MODULE_MESSAGES);

export default Vue.component('user-list', {
    components: { PaginatedApiTable },
    props: {
        apiUrl: VueTypes.string.isRequired,
        corporationOptionsUrl: VueTypes.string.isRequired,
        userTypeOptionsUrl: VueTypes.string.isRequired,
        roleOptionsUrl: VueTypes.string.isRequired,
        initialCount: VueTypes.integer.def(0),
        initialDataElementId: VueTypes.string.isRequired,
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
        bulkDisableUsersUrl: VueTypes.string.isRequired,
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'date_joined',
                    label: 'Date Created',
                    tdClass: 'align-middle',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'align-middle',
                    filter: { type: 'header-search-field' },
                    sortable: false,
                },
                {
                    key: 'username',
                    label: 'Username',
                    tdClass: 'align-middle',
                    filter: { type: 'header-search-field' },
                    sortable: true,
                },
                {
                    key: 'corporation',
                    label: 'Corporation',
                    tdClass: 'align-middle',
                    filter: {
                        type: 'header-select-field',
                        optionsUrl: this.corporationOptionsUrl,
                    },
                    sortable: false,
                },
                {
                    key: 'is_active',
                    label: 'Active',
                    tdClass: 'align-middle',
                    sortable: false,
                },
                {
                    key: 'selected',
                    label: 'Select',
                    tdClass: 'align-middle',
                    sortable: false,
                },
            ],
            selectedItems: [],
            modalId: 'disable-users-modal',
            refreshTable: false,
        };
    },
    methods: {
        ...mapMutations([MUTATION_ADD_MESSAGE]),
        getBooleanIcon,
        getBooleanVariant,
        getDateFromDatetimeString,
        selectAll() {
            this.$refs.paginatedApiTable.selectAllRows();
        },
        deselectAll() {
            this.$refs.paginatedApiTable.deselectAllRows();
        },
        onRowSelected(items) {
            this.selectedItems = items;
        },
        bulkDisableUsers() {
            axios
                .post(
                    this.bulkDisableUsersUrl,
                    { users: this.selectedItems.map((item) => item.pk) },
                    { headers: { ...csrfHeader } },
                )
                .then(() => {
                    this[MUTATION_ADD_MESSAGE]({
                        text: 'The selected users were disabled successfully.',
                        variant: 'success',
                    });
                    this.refreshTable = true;
                })
                .catch((error) => {
                    const errorMessage = _get(
                        error,
                        'response.data.error',
                        'There was an error disabling the selected users.',
                    );
                    this[MUTATION_ADD_MESSAGE]({
                        text: errorMessage,
                        variant: 'danger',
                    });
                    this.refreshTable = true;
                });
        },
    },
});
</script>
<style lang="scss" scoped>
.confirm-selected-users-container {
    overflow-y: auto;
    max-height: 200px;
}
</style>
