/**
 * Mixin for components that need to change content based on breakpoint changes.
 */

import breakpointsListener from '../../breakpoints';

export default {
    computed: {
        breakpoints() {
            return breakpointsListener;
        },
    },
};
