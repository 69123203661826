<script>
import Vue from 'vue/dist/vue.esm.js';

import ApiDrivenForm from '../../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            name: {
                component: 'input-field',
                id: 'measurement-unit-name-input',
                label: 'Name',
                required: true,
                customValidationRules: { max: 128 },
            },
            unit_type: {
                component: 'select-field',
                id: 'measurement-unit-unit-type-input',
                label: 'Unit type',
                required: true,
                customValidationRules: { max: 6 },
                options: [
                    { value: 'area', label: 'Area' },
                    { value: 'linear', label: 'Linear' },
                ],
            },
            application_type: {
                component: 'input-field',
                id: 'measurement-unit-application-type-input',
                label: 'Application type',
                required: true,
                customValidationRules: { max: 128 },
            },
            label: {
                component: 'input-field',
                id: 'measurement-unit-label-input',
                label: 'Label',
                required: true,
                customValidationRules: { max: 128 },
            },
        },
    },
];

export default Vue.component('measurement-unit-form', {
    extends: ApiDrivenForm,
    created() {
        this.formSchema = SCHEMA;
    },
    methods: {
        getSuccessMessage() {
            return `Measurement Unit '${this.formData.name}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
