<template>
    <div>
        <paginated-api-table
            v-if="adminModeEnabled"
            :api-url="apiUrl"
            :fields="adminTableFields"
            :initial-count="initialCount"
            :initial-data-element-id="initialDataElementId"
            :initial-num-pages="initialNumPages"
            :initial-page-size="initialPageSize"
            :refresh="refreshTable"
            @refreshed="refreshTable = false"
        >
            <template #name="cellProps">
                {{ cellProps.item.name }}
            </template>

            <template #links="cellProps">
                <div class="row d-flex flex-column">
                    <a v-if="cellProps.item.url" :href="cellProps.item.url">
                        Component URL
                    </a>
                </div>
            </template>

            <template #actionsColumn="cellProps">
                <b-button
                    :href="cellProps.item.update_url"
                    :aria-label="`Edit ${cellProps.item.name}`"
                    class="rounded-circle"
                    variant="primary"
                >
                    <b-icon-pencil />
                </b-button>

                <delete-button
                    :item-to-delete="cellProps.item"
                    :item-name="cellProps.item.name"
                    @item-deleted="refreshTable = true"
                ></delete-button>
            </template>
        </paginated-api-table>

        <paginated-card-list
            v-else
            :api-url="apiUrl"
            :initial-count="initialCount"
            :initial-data-element-id="initialDataElementId"
            :initial-num-pages="initialNumPages"
            :initial-page-size="initialPageSize"
        >
            <template #listBody="props">
                <div
                    v-for="component in props"
                    :key="component.pk"
                    class="d-flex"
                >
                    <project-component-card-button
                        v-if="component.url"
                        :key="component.pk"
                        :title="component.name"
                        :subheading="component.type"
                        :description="component.description"
                        :url="component.url"
                        :logo="component.image"
                    />
                </div>
            </template>

            <template #noData>
                There are currently no components available. Please refresh the
                page and try again, or contact an administrator.
            </template>
        </paginated-card-list>

        <project-component-order-form
            :api-url="reorderApiUrl"
            :show-modal="showModal"
            @hidden="showModal = false"
            @refresh-table="refreshTable = true"
        />
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import IsAdminEnabledMixin from '../mixins/is-admin-enabled';
import PaginatedApiTable from '../PaginatedApiTable.vue';
import PaginatedCardList from '../PaginatedCardList.vue';
import DeleteButton from '../DeleteButton.vue';

import ProjectComponentOrderForm from './ProjectComponentOrderForm.vue';
import ProjectComponentCardButton from './ProjectComponentCardButton.vue';

export default Vue.component('project-component-list', {
    components: {
        DeleteButton,
        PaginatedApiTable,
        PaginatedCardList,
        ProjectComponentCardButton,
        ProjectComponentOrderForm,
    },
    mixins: [IsAdminEnabledMixin],
    props: {
        apiUrl: VueTypes.string.isRequired,
        initialCount: VueTypes.integer.def(0),
        initialDataElementId: VueTypes.string.isRequired,
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
    },
    data() {
        return {
            adminTableFields: [
                {
                    key: 'name',
                    label: 'Component name',
                    tdClass: 'align-middle',
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'align-middle',
                },
                {
                    key: 'links',
                    label: 'Component URL',
                    tdClass: 'align-middle',
                },
                { key: 'actions', tdClass: 'align-middle' },
            ],
            refreshTable: false,
            showModal: false,
        };
    },
    computed: {
        reorderApiUrl() {
            return this.apiUrl + 'reorder/';
        },
    },
    watch: {
        adminModeEnabled(value) {
            this.adjustContentContainerStyle(value);
        },
    },
    mounted() {
        this.adjustContentContainerStyle(this.adminModeEnabled);
    },
    methods: {
        adjustContentContainerStyle(adminModeEnabled) {
            const contentContainer =
                document.getElementById('content-container');
            if (adminModeEnabled) {
                contentContainer.style.padding = '1.25rem';
            } else {
                contentContainer.style.padding = '2rem';
            }
        },
    },
});
</script>
