<!--
    A generic form component that uses an API for POST and PATCH actions.

    Front end validation is run using Vee-Validate. Back end validation errors are also supported.

    The `formSchema` must be provided by the extending component in the `created` method.
    The success method can be customised by overwriting `getSuccessMessage`.
-->
<template>
    <b-overlay :show="isBusy">
        <template #overlay>
            <div class="text-center my-2" style="color: grey">
                <b-spinner class="align-middle mr-1" />
                <strong>
                    <span v-if="overlayText">{{ overlayText }}</span>
                    <span v-else>Loading...</span>
                </strong>
            </div>
        </template>
        <validation-observer ref="observer" #default="{ invalid }">
            <b-form :aria-hidden="isBusy ? 'true' : null">
                <b-alert v-if="infoText" variant="info" show>
                    {{ infoText }}
                </b-alert>

                <b-form-text class="mb-3">
                    <required-field-hint />
                </b-form-text>

                <b-alert
                    v-if="nonFieldErrors && nonFieldErrors.length"
                    variant="danger"
                    show
                >
                    <template v-if="Array.isArray(nonFieldErrors)">
                        <ul class="error-list">
                            <li
                                v-for="(error, index) in nonFieldErrors"
                                :key="index"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </template>
                    <span v-else>
                        {{ nonFieldErrors }}
                    </span>
                </b-alert>
                <b-alert v-else-if="successMessage" variant="success" show>
                    {{ successMessage }}
                </b-alert>

                <div v-for="(formGroup, index) in formSchema" :key="index">
                    <h3 v-if="formGroup.groupName">
                        {{ formGroup.groupName }}
                    </h3>
                    <div :class="{ 'sub-form-group': formGroup.groupName }">
                        <template
                            v-for="(field, fieldName) in formGroup.fields"
                        >
                            <input
                                v-if="field.hidden"
                                :key="fieldName"
                                type="hidden"
                            />
                            <component
                                :is="field.component"
                                v-else
                                :id="field.id"
                                :key="fieldName"
                                v-model="formData[fieldName]"
                                :label="field.label"
                                :name="fieldName"
                                :help-text="field.helpText"
                                :required="field.required || false"
                                :readonly="field.readonly || false"
                                :disabled="isBusy"
                                :block-label="field.blockLabel || false"
                                :large="field.large || false"
                                :custom-validation-rules="
                                    field.customValidationRules
                                "
                                :placeholder="field.placeholder"
                                :type="field.type"
                                :options="field.options"
                                :options-url="field.optionsUrl"
                                :filter-options-url="field.filterOptionsUrl"
                                :mime-types="field.mimeTypes"
                                :uploaded-file-type="field.uploadedFileType"
                                :existing-file-name="
                                    existingFileNames[fieldName]
                                "
                                :multiple="field.multiple"
                                :modal-component="field.modalComponent"
                                :modal-label="field.modalLabel"
                                :modal-api-url="modalApiUrl"
                                :api-url="field.fieldApiUrl"
                                :arcgis-group-id="field.arcgisGroupId"
                                @file-upload-in-progress="
                                    (value) => (uploadInProgress = value)
                                "
                            />
                        </template>
                    </div>
                </div>

                <b-button
                    :block="breakpoints.xs"
                    class="mb-1 mb-sm-0"
                    :disabled="isBusy"
                    @click="cancel"
                >
                    Cancel
                </b-button>

                <b-button
                    :disabled="
                        invalid || uploadInProgress || isBusy || disabled
                    "
                    :block="breakpoints.xs"
                    class="float-right"
                    variant="primary"
                    @click="submit"
                >
                    <template v-if="submitting">
                        <b-spinner small type="grow" />
                        Submitting...
                    </template>

                    <template v-else> Submit </template>
                </b-button>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>

<script>
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { ValidationObserver } from 'vee-validate';

import BreakpointsMixin from '../mixins/breakpoints';
import { getDeserializedData } from '../../utils';
import { csrfHeader } from '../../cookies';

import CheckboxField from './CheckboxField.vue';
import FileField from './FileField.vue';
import InputField from './InputField.vue';
import JsonInputField from './JsonInputField.vue';
import MultipleChoiceDragDropField from './MultipleChoiceDragDropField.vue';
import PolygonField from './PolygonField.vue';
import ModalField from './ModalField.vue';
import RadioFieldGroup from './RadioFieldGroup.vue';
import SelectField from './SelectField.vue';
import URLField from './URLField.vue';
import RequiredFieldHint from './RequiredFieldHint.vue';

export default Vue.component('api-driven-form', {
    components: {
        CheckboxField,
        FileField,
        InputField,
        JsonInputField,
        MultipleChoiceDragDropField,
        PolygonField,
        ModalField,
        RadioFieldGroup,
        SelectField,
        URLField,
        RequiredFieldHint,
        ValidationObserver,
    },
    mixins: [BreakpointsMixin],
    props: {
        apiUrl: VueTypes.string.isRequired,
        infoText: VueTypes.string.def(''),
        initialFormDataElementId: VueTypes.string,
    },
    data() {
        return {
            formData: {},
            formSchema: {},
            existingFileNames: {},
            nonFieldErrors: null,
            rawInitialData: {},
            submitting: false,
            successMessage: null,
            uploadInProgress: false,
            modalApiUrl: null,
            isBusy: false,
            overlayText: '',
            disabled: false,
        };
    },
    computed: {
        actionString() {
            return this.initialFormDataElementId ? 'updated' : 'created';
        },
        flattenedSchema() {
            return this.formSchema.reduce(
                (newSchema, formGroup) => ({
                    ...newSchema,
                    ...formGroup.fields,
                }),
                {},
            );
        },
    },
    beforeMount() {
        // This expects `formSchema` to be set by the extending component in the `created` method.
        // The `formData` must be initialised after creation and before mounting so that form fields
        // like the `FileField` can process the initial data when they are mounted.
        this.formData = this.getInitialData();
    },
    methods: {
        getExistingFormData() {
            this.rawInitialData = getDeserializedData(
                this.initialFormDataElementId,
            );
            return Object.keys(this.rawInitialData).reduce(
                (accumulator, fieldName) => {
                    let value = this.rawInitialData[fieldName];
                    const schema = this.flattenedSchema[fieldName];

                    if (schema && !(value === undefined || value === null)) {
                        if (schema.component === 'file-field') {
                            // Save the fileName and set the internal value to the uploaded file id.
                            this.existingFileNames[fieldName] = value;
                            value =
                                this.rawInitialData[`${fieldName}_id`] || null;
                        }
                        accumulator[fieldName] = value;
                    }
                    return accumulator;
                },
                {},
            );
        },
        getFormData() {
            // Override within the component to modify how form data is sent to the API.
            return this.formData;
        },
        getFreshFormData() {
            // Simple method enabling extending components to set their own logic for the fresh
            // form data.
            return {};
        },
        getInitialData() {
            if (!this.initialFormDataElementId) {
                return this.getFreshFormData();
            }

            return this.getExistingFormData();
        },
        getSuccessMessage() {
            const actionString = this.initialFormDataElementId
                ? 'updated'
                : 'created';
            return `Object was ${actionString} successfully.`;
        },
        handleFormErrors(error) {
            const errors = _get(error, 'response.data', {});

            this.nonFieldErrors = errors.detail || errors.non_field_errors;
            if (!_isEmpty(this.nonFieldErrors)) {
                // Reset the validation state.
                this.$refs.observer.reset();
            } else if (!_isEmpty(errors)) {
                // Run the validation and add the server errors to the form,
                // including any errors for nested sub-forms.
                const cleanedErrors = this.handleSubFormErrors(errors);
                this.$nextTick(async () => {
                    await this.$refs.observer.validate();
                    this.$refs.observer.setErrors(cleanedErrors);
                });
            }
        },
        handleSubFormErrors(errors) {
            // Override to handle errors in forms using nested serializers, i.e.
            // sub-forms who's errors come back as:
            // `{ nestedFieldName: { subFormFieldName: [errors] }`.
            // The `subFormFieldName` will need to be extracted.
            return errors;
        },
        handleFormSuccess(response) {
            // Prioritise the `location` header over the created object's
            // `absolute_url`. Some objects don't have detail pages to navigate
            // to after creation.
            const url =
                _get(response, 'headers.location', null) ||
                _get(response, 'data.absolute_url', null);
            if (url) {
                window.location = url;
            } else {
                this.successMessage = this.getSuccessMessage();
                this.submitting = false;
            }
        },
        cancel() {
            window.history.back();
        },
        submit() {
            this.submitting = true;

            axios({
                method: this.initialFormDataElementId ? 'patch' : 'post',
                url: this.apiUrl,
                data: this.getFormData(),
                headers: { ...csrfHeader },
            })
                .then((response) => {
                    this.handleFormSuccess(response);
                })
                .catch((error) => {
                    this.handleFormErrors(error);
                    this.submitting = false;
                });
        },
    },
});
</script>

<style lang="scss" scoped>
ul.error-list {
    list-style-type: none;
    padding-inline-start: 0;

    &:last-child {
        margin-bottom: 0;
    }
}

.sub-form-group {
    background-color: #e3e7ed;
    padding: 10px 10px 0;
    border: 2px solid #c4cad4;
    margin-bottom: 20px;
}
</style>
