<script>
import Vue from 'vue/dist/vue.esm.js';

import ApiDrivenForm from '../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            uploaded_file: {
                component: 'file-field',
                id: 'bridge-inspection-excel-file',
                label: 'Bridge Inspection Excel File',
                helpText:
                    'Please select the file to convert to a word document.',
                mimeTypes: ['application/vnd.ms-excel'],
                uploadedFileType: 'tools',
                required: true,
            },
        },
    },
];

export default Vue.component('bridge-inspection-conversion-form', {
    extends: ApiDrivenForm,
    created() {
        this.formSchema = SCHEMA;
    },
    methods: {
        getSuccessMessage() {
            return 'Begin file conversion.';
        },
    },
});
</script>
