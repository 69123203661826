var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.addProposalUsers.apply(null, arguments)
            },
          },
        },
        [
          _vm._l(_vm.formFields, function (field, fieldName) {
            return [
              _c(field.component, {
                key: fieldName,
                tag: "component",
                attrs: {
                  id: field.id,
                  label: field.label,
                  name: fieldName,
                  type: field.type,
                  placeholder: field.placeholder,
                  "options-url": field.optionsUrl,
                },
                model: {
                  value: _vm.formData[fieldName],
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, fieldName, $$v)
                  },
                  expression: "formData[fieldName]",
                },
              }),
            ]
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "clearfix" },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right col-sm-2 mb-2",
                  attrs: { disabled: _vm.isAddButtonDisabled, type: "submit" },
                },
                [_vm._v("\n                Add Users\n            ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.items.length !== 0
        ? _c(
            "div",
            { staticClass: "table-container" },
            [
              _c("b-table", {
                staticClass: "mb-0",
                attrs: {
                  items: _vm.items,
                  "sticky-header": true,
                  fields: _vm.tableFields,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }