var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xs-12 col-sm-6 col-lg-3 my-2" }, [
    _c(
      "a",
      {
        staticClass: "d-flex card-button-link h-100",
        attrs: { href: _vm.url },
      },
      [
        _c(
          "b-card",
          {
            staticClass: "rounded flex-grow-1",
            attrs: { "body-class": _vm.bodyClasses },
          },
          [
            _vm.logo
              ? [
                  _c(
                    "div",
                    [
                      _vm.logo
                        ? _c("b-img", {
                            class: _vm.logoClass,
                            attrs: { src: _vm.logo, alt: _vm.title + " logo" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-card-text", { staticClass: "mt-4 title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.title) +
                        "\n                "
                    ),
                  ]),
                ]
              : _c("b-card-title", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
            _vm._v(" "),
            _vm.subheading
              ? _c("b-card-text", { staticClass: "text-muted" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.subheading) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }