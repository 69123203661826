var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.enabled,
          expression: "enabled",
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "clearfix" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-for": "search-field",
                    "label-cols-md": "4",
                    description: _vm.helpText,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.searchLabel) +
                              "\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "search-field", name: "search-field" },
                        model: {
                          value: _vm.searchTermInProgress,
                          callback: function ($$v) {
                            _vm.searchTermInProgress = $$v
                          },
                          expression: "searchTermInProgress",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right col-sm-2",
                          attrs: { block: _vm.breakpoints.xs, type: "submit" },
                        },
                        [
                          _vm._v(
                            "\n                        Search\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showWarning && _vm.warningMessage
        ? _c("div", { staticClass: "text-danger warning-message mb-1" }, [
            _vm._v("\n        " + _vm._s(_vm.warningMessage) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-alert",
        { attrs: { dismissible: "", show: !!_vm.displayMessage } },
        [_vm._v("\n        " + _vm._s(_vm.displayMessage) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(_vm.tableComponent, {
            ref: "table",
            tag: "component",
            staticClass: "mb-0",
            attrs: {
              "api-url": _vm.apiUrl,
              fields: _vm.tableFields,
              params: _vm.params,
              selectable: _vm.selectable,
              "select-mode": _vm.selectMode,
              "sticky-header": _vm.stickyHeader,
            },
            on: { "is-busy": _vm.onIsBusy, "row-selected": _vm.onRowSelected },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.tableFields, function (ref) {
                  var key = ref.key
                  return {
                    key: "cell(" + key + ")",
                    fn: function (data) {
                      return [
                        _vm._t(
                          key,
                          function () {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(key) +
                                  "\n                "
                              ),
                            ]
                          },
                          null,
                          data
                        ),
                      ]
                    },
                  }
                }),
                {
                  key: "url",
                  fn: function (cellProps) {
                    return [
                      _c(
                        "a",
                        {
                          attrs: { href: cellProps.item.url, target: "_blank" },
                        },
                        [_vm._v("View in ArcGIS")]
                      ),
                    ]
                  },
                },
                {
                  key: "actionsColumn",
                  fn: function (cellProps) {
                    return [
                      _c(
                        "b-button",
                        {
                          staticClass: "rounded-circle",
                          attrs: {
                            "aria-label": "select " + cellProps.item.name,
                            variant: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.actionItem(cellProps.item)
                            },
                          },
                        },
                        [_c("b-icon-check-lg")],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }