<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import ApiDrivenForm from '../forms/ApiDrivenForm.vue';

import ProjectUsersSearchForm from './ProjectUsersSearchForm.vue';

const SCHEMA = [
    {
        fields: {
            users: {
                component: 'project-users-search-form',
                id: 'project-users-input',
                required: true,
                fieldApiUrl: '',
            },
        },
    },
];

export default Vue.component('project-users-form', {
    components: {
        ProjectUsersSearchForm,
    },
    extends: ApiDrivenForm,
    props: {
        fieldApiUrl: VueTypes.string.isRequired,
    },
    data() {
        return {
            disabled: true,
            initialItems: null,
        };
    },
    created() {
        this.formSchema = SCHEMA;
        this.formSchema[0].fields.users.fieldApiUrl = this.fieldApiUrl;
    },
    mounted() {
        // Listen for the selected users from the project users search form.
        this.$root.$on('users-selection-updated', (value) => {
            this.formData.users = value;

            if (!this.initialItems) {
                this.initialItems = value;
            } else {
                this.disabled = false;
            }
        });
    },
    methods: {
        getFormData() {
            const formData = structuredClone(this.formData);
            formData.arcgis_group_id = this.rawInitialData.arcgis_group_id;
            return formData;
        },
    },
});
</script>
