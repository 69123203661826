<template>
    <validation-provider
        #default="validationContext"
        :vid="name"
        :name="label"
        :rules="rules"
    >
        <b-form-group
            :label-for="id"
            :invalid-feedback="validationContext.errors[0]"
            :description="helpText"
            :label-cols-md="blockLabel ? '0' : '4'"
        >
            <template #label>
                {{ label }} <required-asterisk v-if="required" />
            </template>

            <component
                :is="fieldComponent"
                :id="id"
                :value="value"
                :name="name"
                :type="type"
                :required="required"
                :readonly="readonly"
                :disabled="disabled"
                :placeholder="placeholder"
                :state="getValidationState(validationContext)"
                @input="(value) => $emit('input', value)"
            />
        </b-form-group>
    </validation-provider>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { ValidationProvider } from 'vee-validate';

import RequiredAsterisk from './RequiredAsterisk.vue';

export default Vue.component('input-field', {
    components: {
        RequiredAsterisk,
        ValidationProvider,
    },
    props: {
        customValidationRules: VueTypes.object,
        id: VueTypes.string.isRequired,
        label: VueTypes.string.isRequired,
        name: VueTypes.string.isRequired,
        helpText: VueTypes.string,
        readonly: Boolean,
        disabled: Boolean,
        required: Boolean,
        type: VueTypes.string.def('text'),
        value: VueTypes.any,
        blockLabel: Boolean,
        placeholder: VueTypes.string,
    },
    computed: {
        fieldComponent() {
            return this.type === 'textarea'
                ? 'b-form-textarea'
                : 'b-form-input';
        },
        rules() {
            return {
                required: this.required,
                ...this.customValidationRules,
            };
        },
    },
    methods: {
        getValidationState({ dirty, errors, validated, valid = null }) {
            return dirty || validated ? valid && !errors.length : null;
        },
    },
});
</script>
