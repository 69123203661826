<script>
import _get from 'lodash/get';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import ApiDrivenForm from '../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            name: {
                component: 'input-field',
                id: 'project-component-name',
                label: 'Name',
                required: true,
                customValidationRules: { max: 255 },
            },
            type: {
                component: 'select-field',
                id: 'project-component-type-input',
                label: 'Type',
                required: true,
                multiple: false,
                optionsUrl: '',
            },
            description: {
                component: 'input-field',
                id: 'project-component-description-input',
                label: 'Description',
                customValidationRules: { max: 600 },
                type: 'textarea',
            },
            image: {
                component: 'file-field',
                id: 'project-component-image',
                label: 'Image',
                helpText: 'Please upload an image for the component.',
                mimeTypes: ['image/jpeg', 'image/png'],
                uploadedFileType: 'project_components',
            },
            url: {
                component: 'url-field',
                id: 'project-component-url',
                label: 'URL',
                required: true,
                customValidationRules: { max: 512 },
            },
        },
    },
];

export default Vue.component('project-component-form', {
    extends: ApiDrivenForm,
    props: {
        organisationPk: VueTypes.integer.isRequired,
        projectPk: VueTypes.integer.isRequired,
        projectComponentTypeOptionsUrl: VueTypes.string.isRequired,
    },
    created() {
        this.formSchema = SCHEMA;
        this.formSchema[0].fields.type.optionsUrl =
            this.projectComponentTypeOptionsUrl;
    },
    beforeMount() {
        this.formData = {
            ...this.getInitialData(),
            // Add the fields for the nested `content_object` form to the
            // initial form data if editing and the data exists.
            ..._get(this.rawInitialData, 'content_object', {}),
        };
    },
    mounted() {
        // Listens for an actioned table item from ApiSearchSelectForm.
        this.$root.$on('action', (data) => {
            this.formData = data;
        });
    },
    methods: {
        getFormData() {
            return this.formData;
        },
        getFreshFormData() {
            return {
                organisation: this.organisationPk,
                project: this.projectPk,
            };
        },
        getSuccessMessage() {
            return `Component '${this.formData.name}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
