<template>
    <div>
        <div v-if="!_isEmpty(appliedFilters)" class="filter-bubble-container">
            <span
                v-for="(value, key) in appliedFilters"
                :key="key"
                class="filter-bubble"
            >
                {{ fields.find((obj) => obj.key === key).label }}: {{ value }}
                <a
                    href="#"
                    :class="{ disabled: isBusy }"
                    @click="removeFilter(key)"
                >
                    <b-spinner v-if="isBusy" small class="align-middle" />
                    <b-icon-x v-else />
                </a>
            </span>
        </div>
        <b-table
            ref="table"
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="currentPage"
            :fields="fields"
            :items="getData"
            :per-page="perPage"
            :selectable="selectable"
            :select-mode="selectMode"
            :sticky-header="
                stickyHeader === true ? TABLE_DEFAULT_HEIGHT : stickyHeader
            "
            responsive
            show-empty
            sort-icon-left
            no-local-sorting
            @row-selected="(items) => $emit('row-selected', items)"
        >
            <template #empty="scope">
                <b-alert :variant="alertVariant" dismissible show>
                    <template v-if="errorMessage">
                        {{ errorMessage }}
                    </template>
                    <template v-else>
                        {{ scope.emptyText }}
                    </template>
                </b-alert>
            </template>

            <template v-for="{ key } in fields" #[`head(${key})`]="data">
                <slot
                    v-bind="data"
                    :name="`${key}-header`"
                    class="table-header"
                >
                    {{ data.label }}

                    <component
                        :is="data.field.filter.type || 'header-search-field'"
                        v-if="data.field.filter"
                        :field-name="key"
                        :data="data"
                        :value="filterData[key]"
                        class="filter-container"
                        @input="(value) => (filterData[key] = value)"
                        @apply-search-filter="() => applySearchFilter(key)"
                        @apply-select-filter="() => applySelectFilter(key)"
                    />
                </slot>
            </template>

            <!--
                Enable components to override the column cell rendering using the
                `cell(fieldKey)` slot provided by `b-table`.
            -->
            <template v-for="{ key } in fields" #[`cell(${key})`]="data">
                <slot v-bind="data" :name="key">
                    {{ data.value }}
                </slot>
            </template>

            <template #cell(actions)="actions">
                <slot v-bind="actions" name="actionsColumn" />
            </template>

            <template #cell(selected)="{ rowSelected }">
                <slot name="selectColumn">
                    <template v-if="rowSelected">
                        <b-icon icon="check-square" variant="success" />
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <b-icon icon="square" />
                        <span class="sr-only">Not selected</span>
                    </template>
                </slot>
            </template>

            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle" />
                    <strong>Loading...</strong>
                </div>
            </template>
        </b-table>

        <b-pagination
            v-if="numPages > 1"
            v-model="currentPage"
            :total-rows="count"
            align="center"
            class="mt-4"
        />
    </div>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import _isEmpty from 'lodash/isEmpty';

import { DEFAULT_ERROR_MESSAGE, TABLE_DEFAULT_HEIGHT } from '../constants';
import { getDeserializedData } from '../utils';

import HeaderSearchField from './forms/HeaderSearchField.vue';
import HeaderSelectField from './forms/HeaderSelectField.vue';

export default Vue.component('paginated-api-table', {
    components: {
        HeaderSearchField,
        HeaderSelectField,
    },
    props: {
        refresh: Boolean,
        apiUrl: VueTypes.string.isRequired,
        fields: VueTypes.arrayOf(VueTypes.object),
        initialCount: VueTypes.integer.def(0),
        initialDataElementId: VueTypes.string,
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
        selectable: Boolean,
        selectMode: VueTypes.string.def('multi'),
        stickyHeader: VueTypes.oneOfType([Boolean, VueTypes.string]).def(false),
    },
    data() {
        return {
            TABLE_DEFAULT_HEIGHT,
            count: this.initialCount,
            currentPage: 1,
            errorMessage: '',
            isBusy: false,
            initialLoad: true,
            numPages: this.initialNumPages,
            perPage: this.initialPageSize,
            sortBy: '',
            sortDesc: false,
            filterData: {},
            currentFilters: {},
            appliedFilters: {},
        };
    },
    computed: {
        alertVariant() {
            return this.errorMessage ? 'danger' : 'info';
        },
    },
    watch: {
        refresh: function (newVal) {
            if (newVal) {
                this.$refs.table.refresh();
                this.$emit('refreshed');
            }
        },
        isBusy(newValue) {
            this.$emit('is-busy', newValue);
        },
    },
    methods: {
        _isEmpty,
        getData(context) {
            if (this.initialLoad && this.initialDataElementId) {
                // Load the initial data without making an API call.
                this.initialLoad = false;
                return getDeserializedData(this.initialDataElementId);
            }

            this.isBusy = true;
            const orderPrefix = this.sortDesc ? '-' : '';
            const order = `${orderPrefix}${this.sortBy}`;

            return axios
                .get(this.apiUrl, {
                    params: {
                        page: context.currentPage,
                        ordering: order,
                        ...this.currentFilters,
                    },
                })
                .then((response) => {
                    this.initialLoad = false;
                    return response.data.results;
                })
                .catch((error) => {
                    this.errorMessage = _get(
                        error,
                        'response.data.detail',
                        DEFAULT_ERROR_MESSAGE,
                    );
                    return [];
                })
                .finally(() => (this.isBusy = false));
        },
        applySearchFilter(key) {
            if (this.filterData[key]) {
                this.currentFilters[key] = this.filterData[key];
                this.appliedFilters[key] = this.currentFilters[key];
            } else {
                delete this.currentFilters[key];
                delete this.appliedFilters[key];
            }

            delete this.filterData[key];
            this.$refs.table.refresh();
        },
        applySelectFilter(key) {
            if (this.filterData[key]) {
                this.currentFilters[key] = this.filterData[key].value;
                this.appliedFilters[key] = this.filterData[key].label;
            } else {
                delete this.currentFilters[key];
                delete this.appliedFilters[key];
            }

            delete this.filterData[key];
            this.$refs.table.refresh();
        },
        removeFilter(key) {
            delete this.currentFilters[key];
            delete this.appliedFilters[key];
            delete this.filterData[key];

            this.$refs.table.refresh();
        },
        selectAllRows() {
            if (this.$refs.table.filteredItems) {
                this.$refs.table.selectAllRows();
            }
        },
        deselectAllRows() {
            if (this.$refs.table.filteredItems) {
                this.$refs.table.clearSelected();
            }
        },
    },
});
</script>

<style lang="scss">
.table thead th {
    vertical-align: top !important;
}

.filter-container {
    display: flex;
    min-width: 200px;
    margin-top: 5px;
}

.filter-bubble-container {
    .filter-bubble {
        border-radius: 25px;
        padding: 2px 10px;
        float: left;
        border: 2px solid #ebebeb;
        margin-right: 5px;
        margin-top: 1em;
        color: #6c757d;
    }
}

.disabled {
    pointer-events: none;
}
</style>
