// Vuex store for handling django messages and messages raised in Vue components.
export const MODULE_MESSAGES = 'messagesModule';

export const MUTATION_ADD_MESSAGE = 'addMessage';
export const MUTATION_REMOVE_MESSAGE = 'removeMessage';

const state = {
    messages: [],
};

const mutations = {
    [MUTATION_ADD_MESSAGE](state, message) {
        state.messages.push(message);
    },
    [MUTATION_REMOVE_MESSAGE](state, message) {
        const index = state.messages.indexOf(message);
        state.messages.splice(index, 1);
    },
};

export const messagesModule = {
    namespaced: true,
    state,
    mutations,
};
