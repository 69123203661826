<template>
    <validation-provider
        #default="validationContext"
        ref="validationProvider"
        :vid="name"
        :name="label"
        :rules="rules"
    >
        <b-form-group
            :label-for="id"
            :invalid-feedback="
                validationContext.errors[0] || uploadErrorMessage
            "
            :description="helpText"
            :label-cols-md="blockLabel ? '0' : '4'"
        >
            <template #label>
                {{ label }} <required-asterisk v-if="required" />
            </template>

            <div
                class="row"
                :class="{
                    'is-invalid':
                        getValidationState(validationContext) === false,
                }"
            >
                <div
                    class="col flex-fill"
                    :class="{
                        'col-sm-auto pr-sm-0 pr-md-0': showRemoveFileButton,
                    }"
                >
                    <!--
                        The internalValue is used for mime-type validation and
                        any other custom validation rules.
                    -->
                    <b-form-file
                        :id="id"
                        ref="fileInput"
                        :value="internalValue"
                        :name="name"
                        :required="required"
                        :accept="acceptedFileTypes"
                        :placeholder="placeholder"
                        :state="
                            fieldState || getValidationState(validationContext)
                        "
                        @input="handleFileChange"
                    />

                    <!--
                        Store the primary key of the uploaded file, this is what
                        is sent when the form is submitted.
                    -->
                    <input
                        :id="`${id}-uploaded-file-id`"
                        :v-model="value"
                        type="hidden"
                    />

                    <b-progress
                        v-if="isUploading || fileUploadStatus"
                        :value="100"
                        :variant="fileUploadStatus || 'info'"
                        :animated="isUploading"
                        :striped="isUploading"
                        height="4px"
                    />
                </div>
                <div
                    v-if="showRemoveFileButton"
                    class="col col-sm-2 mt-2 mt-sm-0"
                >
                    <b-button
                        variant="outline-danger"
                        block
                        @click="clearField"
                    >
                        <span v-if="breakpoints.xs"> Remove file </span>
                        <template v-else>
                            <b-icon-trash />
                            <span class="sr-only">Remove file</span>
                        </template>
                    </b-button>
                </div>
            </div>
        </b-form-group>
    </validation-provider>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { ValidationProvider } from 'vee-validate';

import BreakpointsMixin from '../mixins/breakpoints';
import { csrfHeader } from '../../cookies';

import RequiredAsterisk from './RequiredAsterisk.vue';

const API_UPLOAD_URL = '/api/files/upload/';
const DEFAULT_PLACEHOLDER = 'No file chosen';

export default Vue.component('file-field', {
    components: { RequiredAsterisk, ValidationProvider },
    mixins: [BreakpointsMixin],
    props: {
        customValidationRules: VueTypes.object,
        existingFileName: VueTypes.string,
        id: VueTypes.string.isRequired,
        label: VueTypes.string.isRequired,
        name: VueTypes.string.isRequired,
        helpText: VueTypes.string,
        required: Boolean,
        value: VueTypes.integer,
        uploadedFileType: VueTypes.string.isRequired,
        mimeTypes: VueTypes.arrayOf(VueTypes.string).isRequired,
        blockLabel: Boolean,
    },
    data() {
        return {
            fieldState: null,
            placeholder:
                this.existingFileName.replace(/^\S*\//, '') ||
                DEFAULT_PLACEHOLDER,
            internalValue: null,
            isUploading: false,
            fileUploadStatus: null,
            uploadedFileId: null,
            uploadErrorMessage: '',
        };
    },
    computed: {
        acceptedFileTypes() {
            return this.mimeTypes.join(', ');
        },
        rules() {
            return {
                editableFileMimeTypes: {
                    mimeTypes: this.mimeTypes,
                },
                requiredFile: this.required,
                ...this.customValidationRules,
            };
        },
        showRemoveFileButton() {
            return this.value;
        },
    },
    watch: {
        isUploading(newValue) {
            this.$emit('file-upload-in-progress', newValue);
        },
    },
    methods: {
        clearField() {
            this.$refs.fileInput.reset();
            this.placeholder = DEFAULT_PLACEHOLDER;
            this.$emit('input', null);
        },
        getValidationState({ dirty, errors, validated, valid = null }) {
            // Show an error when file uploads have failed. This is required otherwise,
            // if `fieldState` is set to `false` then this method will determine the
            // validation state and the file field will be "valid" as a file was correctly
            // selected prior to commencing the upload.
            if (this.fileUploadStatus === 'danger') {
                return false;
            }
            return dirty || validated ? valid && !errors.length : null;
        },
        /**
         * Handle the file input event and update the field's validation state.
         *
         * @param value The new value for the file field, may be a File, null or a string.
         * @returns {Promise<void>}
         *
         * Manually trigger Vee-Validate validation when the file input field value changes.
         *
         * Vee-Validate relies on a `v-model` or `value` binding on an input to identify fields
         * it can validate. Vue cannot reliably set the value of a file input field in a way
         * that Vee-Validate would react to. This manual validation enables the file field
         * state to be updated when adding or removing files. It also resolves the issue
         * where required file fields were not validating correctly when empty.
         *
         * More info: https://logaretm.github.io/vee-validate/advanced/model-less-validation.html
         */
        async handleFileChange(value) {
            const { valid } = await this.$refs.validationProvider.validate(
                value,
            );
            this.internalValue = value;
            this.fieldState = valid && value === null ? null : valid;

            if (valid && value instanceof File) {
                this.upload(value);
            }
        },
        upload(value) {
            this.isUploading = true;

            const formData = new FormData();
            formData.append('type', this.uploadedFileType);
            formData.append('uploaded_file', value);

            axios
                .post(API_UPLOAD_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        ...csrfHeader,
                    },
                })
                .then(({ data }) => {
                    this.fileUploadStatus = 'success';
                    this.$emit('input', data.pk);
                })
                .catch(({ response }) => {
                    this.fileUploadStatus = 'danger';
                    this.fieldState = false;
                    this.uploadErrorMessage = _get(
                        response,
                        'data.detail',
                        'There was an error uploading the file.',
                    );
                })
                .finally(() => {
                    this.isUploading = false;
                });
        },
    },
});
</script>

<style lang="scss" class="scoped">
// Hide excessively long filenames.
.custom-file-label {
    overflow: hidden;
}
</style>
