<template>
    <lazy-until-loaded-tab tab-id="themes" title="Themes" v-bind="$attrs">
        <template #tabBody>
            <b-button
                :href="createUrl"
                class="float-right my-2"
                variant="primary"
            >
                Add Theme
            </b-button>

            <themes-list :api-url="apiUrl" />
        </template>
    </lazy-until-loaded-tab>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import LazyUntilLoadedTab from '../../LazyUntilLoadedTab.vue';

import ThemesList from './ThemesList.vue';

export default Vue.component('themes-tab', {
    components: { LazyUntilLoadedTab, ThemesList },
    inheritAttrs: false,
    props: {
        apiUrl: VueTypes.string.isRequired,
        createUrl: VueTypes.string.isRequired,
    },
});
</script>
