<template>
    <validation-provider
        #default="validationContext"
        :vid="name"
        :name="label"
        :rules="rules"
    >
        <b-form-group
            :label-for="id"
            :invalid-feedback="validationContext.errors[0]"
            :description="helpText"
            label-cols-md="4"
        >
            <template #label>
                {{ label }} <required-asterisk v-if="required" />
            </template>

            <!--
            Don't use `v-model` or `value`, otherwise the `validation-provider` will use this input value
            when validating the checkbox group.
            -->
            <b-form-input
                placeholder="Search"
                @input="(value) => (searchInput = value)"
            />

            <input v-model="selected" hidden />

            <b-row class="drag-drop-fields">
                <b-col class="pr-1">
                    <draggable
                        :list="filteredOptions"
                        class="list-group"
                        group="selections"
                    >
                        <b-list-group-item
                            v-for="item in filteredOptions"
                            :key="item.value"
                        >
                            {{ item.label }}
                        </b-list-group-item>
                    </draggable>
                </b-col>
                <b-col class="pl-1">
                    <draggable
                        :list="selectedItems"
                        class="list-group"
                        group="selections"
                    >
                        <div
                            v-if="hasNoSelectedItems"
                            class="d-flex drop-target"
                        >
                            <div class="align-self-center">
                                Drag and drop items here to select.
                            </div>
                        </div>
                        <b-list-group-item
                            v-for="item in selectedItems"
                            v-else
                            :key="item.value"
                        >
                            {{ item.label }}
                        </b-list-group-item>
                    </draggable>
                </b-col>
            </b-row>
        </b-form-group>
    </validation-provider>
</template>

<script>
import _debounce from 'lodash/debounce';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { ValidationProvider } from 'vee-validate';
import axios from 'axios';
import draggable from 'vuedraggable';

import RequiredAsterisk from './RequiredAsterisk.vue';

export default Vue.component('multiple-choice-drag-drop-field', {
    components: { draggable, RequiredAsterisk, ValidationProvider },
    props: {
        customValidationRules: VueTypes.object,
        id: VueTypes.string.isRequired,
        label: VueTypes.string.isRequired,
        name: VueTypes.string.isRequired,
        helpText: VueTypes.string,
        required: Boolean,
        value: VueTypes.oneOfType([
            VueTypes.integer,
            VueTypes.string,
            VueTypes.array,
        ]),
        options: VueTypes.array,
        optionsUrl: VueTypes.string,
        filterParams: VueTypes.object,
    },
    data() {
        return {
            searchInput: '',
            selectOptions: [],
            selectedItems: [],
        };
    },
    computed: {
        apiFilterParameters() {
            return { ...this.filterParams };
        },
        filteredOptions() {
            const unselectedItems = _filter(this.selectOptions, (item) => {
                const existingIndex = this.selectedItems.findIndex((obj) => {
                    return obj.value === item.value;
                });
                return existingIndex === -1;
            });

            if (this.searchInput) {
                return _filter(unselectedItems, (item) => {
                    return (
                        item.label
                            .toLowerCase()
                            .search(this.searchInput.toLowerCase()) > -1
                    );
                });
            }

            return unselectedItems;
        },
        hasNoSelectedItems() {
            return _isEmpty(this.selectedItems);
        },
        rules() {
            return {
                required: this.required,
                ...this.customValidationRules,
            };
        },
        selected: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
    watch: {
        apiFilterParameters() {
            this.debouncedGetOptions();
        },
        selectedItems(newValue) {
            this.selected = newValue.map((item) => item.value);
        },
    },
    created() {
        if (this.optionsUrl) {
            this.getOptions();
            this.debouncedGetOptions = _debounce(this.getOptions, 300);
        } else if (this.options) {
            this.selectOptions = this.options;
            this.addSelections();
        } else {
            throw new Error(
                'Must provide either `options` or `optionsUrl` prop.',
            );
        }
    },
    methods: {
        addSelections() {
            // Move any existing selections to the second list.
            if (Array.isArray(this.value) && !_isEmpty(this.value)) {
                this.value.forEach((value) => {
                    const oldIndex = this.selectOptions.findIndex(
                        (item) => item.value === value,
                    );
                    if (oldIndex > -1) {
                        const item = this.selectOptions[oldIndex];
                        const existingIndex = this.selectedItems.findIndex(
                            (obj) => {
                                return obj.value === item.value;
                            },
                        );
                        if (existingIndex === -1) {
                            this.selectedItems.push(item);
                        }
                    }
                });
            }
        },
        getOptions() {
            axios
                .get(this.optionsUrl, { params: this.apiFilterParameters })
                .then(({ data }) => {
                    this.selectOptions = data.results;
                    this.addSelections();
                });
        },
        getValidationState({ dirty, errors, validated, valid = null }) {
            return dirty || validated ? valid && !errors.length : null;
        },
    },
});
</script>

<style lang="scss" scoped>
.drag-drop-fields {
    max-height: 400px;
    overflow-y: scroll;
}

.drop-target {
    box-shadow: inset 0 0 4px 4px #0003;
    height: 100px;
    padding: 20px;
}
</style>
