<template>
    <component :is="htmlTag" v-bind="$attrs">
        <span id="json-display-content">{{ formattedJson }}</span>
    </component>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import { getDeserializedData } from '../utils';

export default Vue.component('json-display-field', {
    props: {
        htmlTag: VueTypes.string.def('dd'),
        jsonDataElementId: VueTypes.string.isRequired,
    },
    data() {
        return {
            formattedJson: null,
        };
    },
    created() {
        const rawData = getDeserializedData(this.jsonDataElementId);
        this.formattedJson = JSON.stringify(rawData, null, 4);
    },
});
</script>

<style lang="scss" scoped>
#json-display-content {
    // Preserve white space sequences and break on new lines, so the JSON is
    // formatted nicely.
    white-space: pre-wrap;
}
</style>
