var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.adminModeEnabled
    ? _c("b-button", _vm._b({}, "b-button", _vm.$attrs, false), [
        _vm._v("\n    " + _vm._s(_vm.buttonText) + "\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }