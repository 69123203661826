<template>
    <b-button v-if="adminModeEnabled" v-bind="$attrs">
        {{ buttonText }}
    </b-button>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import IsAdminEnabledMixin from './mixins/is-admin-enabled';

export default Vue.component('admin-only-button', {
    mixins: [IsAdminEnabledMixin],
    props: {
        buttonText: VueTypes.string.isRequired,
    },
});
</script>
