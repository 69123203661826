<template>
    <paginated-api-table
        :api-url="apiUrl"
        :fields="adminTableFields"
        :initial-count="initialCount"
        :initial-num-pages="initialNumPages"
        :initial-page-size="initialPageSize"
        :refresh="refreshTable"
        @refreshed="refreshTable = false"
    >
        <template #pk="cellProps">
            <a :href="cellProps.item.absolute_url">
                {{ cellProps.item.pk }}
            </a>
        </template>

        <template #actionsColumn="cellProps">
            <b-button
                :href="cellProps.item.update_url"
                :aria-label="`Edit ${cellProps.item.name}`"
                class="rounded-circle"
                variant="primary"
            >
                <b-icon-pencil />
            </b-button>

            <delete-button
                :item-to-delete="cellProps.item"
                :item-name="cellProps.item.name"
                @item-deleted="refreshTable = true"
            ></delete-button>
        </template>
    </paginated-api-table>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import PaginatedApiTable from '../../PaginatedApiTable.vue';
import DeleteButton from '../../DeleteButton.vue';

export default Vue.component('themes-list', {
    components: { DeleteButton, PaginatedApiTable },
    props: {
        apiUrl: VueTypes.string.isRequired,
        initialCount: VueTypes.integer.def(0),
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
    },
    data() {
        return {
            adminTableFields: [
                {
                    key: 'pk',
                    label: 'Id',
                    tdClass: 'align-middle',
                },
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'align-middle',
                },
                { key: 'actions', tdClass: 'align-middle' },
            ],
            refreshTable: false,
        };
    },
});
</script>
