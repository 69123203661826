<template>
    <b-tab :title="title" v-bind="$attrs" @click="setActive">
        <template v-if="isActive">
            <slot name="tabBody"></slot>
        </template>
    </b-tab>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

export default Vue.component('lazy-until-loaded-tab', {
    inheritAttrs: false,
    props: {
        tabId: VueTypes.string.isRequired,
        title: VueTypes.string.isRequired,
    },
    data() {
        return {
            isActive: false,
        };
    },
    created() {
        if (
            Object.prototype.hasOwnProperty.call(this.$attrs, 'active') ||
            this.$attrs.active
        ) {
            this.setActive();
        }
    },
    methods: {
        addTabIdToUrl() {
            const url = new URL(window.location.href);
            url.searchParams.set('tab', this.tabId);
            window.history.pushState({}, '', url.href);
        },
        setActive() {
            // Set the tab to active once the tab is loaded. This will keep the tab
            // state around if the user navigates away, but only loads the content in
            // the `tabBody` slot once the user visits it for the first time.
            this.isActive = true;
            this.addTabIdToUrl();
        },
    },
});
</script>
