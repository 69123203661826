var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.errorMessage
        ? _c(
            "b-alert",
            { attrs: { variant: "danger", dismissible: "", show: "" } },
            [_vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n    ")]
          )
        : _vm.isLoading
        ? _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("b-progress", {
                attrs: {
                  value: 100,
                  variant: "info",
                  label: "Loading...",
                  animated: "",
                },
              }),
            ],
            1
          )
        : _vm.objects && _vm.objects.length
        ? [
            _vm._t("listHeader"),
            _vm._v(" "),
            _vm._t("listBody", null, null, _vm.objects),
            _vm._v(" "),
            _vm._t("pagination", function () {
              return [
                _vm.numPages > 1
                  ? _c("b-pagination", {
                      staticClass: "mt-4",
                      attrs: { "total-rows": _vm.count, align: "center" },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ]
        : _c(
            "b-alert",
            { attrs: { variant: "info", show: "" } },
            [
              _vm._t("noData", function () {
                return [
                  _vm._v(
                    "\n            There are currently no items available. Please refresh the page\n            and try again, or contact an administrator.\n        "
                  ),
                ]
              }),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }