var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.messages, function (message) {
      return _c(
        "div",
        { key: message.key },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: message.variant, dismissible: "", show: "" },
              on: {
                dismissed: function ($event) {
                  return _vm.dismissMessage(message)
                },
              },
            },
            [_vm._v("\n            " + _vm._s(message.text) + "\n        ")]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }