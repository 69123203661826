var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("paginated-api-table", {
    attrs: {
      refresh: _vm.refreshTable,
      "api-url": _vm.apiUrl,
      fields: _vm.adminTableFields,
      "initial-count": _vm.initialCount,
      "initial-num-pages": _vm.initialNumPages,
      "initial-page-size": _vm.initialPageSize,
    },
    on: {
      refreshed: function ($event) {
        _vm.refreshTable = false
      },
    },
    scopedSlots: _vm._u([
      {
        key: "pk",
        fn: function (cellProps) {
          return [
            _c("a", { attrs: { href: cellProps.item.absolute_url } }, [
              _vm._v(
                "\n            " + _vm._s(cellProps.item.pk) + "\n        "
              ),
            ]),
          ]
        },
      },
      {
        key: "actionsColumn",
        fn: function (cellProps) {
          return [
            _c(
              "b-button",
              {
                staticClass: "rounded-circle",
                attrs: {
                  href: cellProps.item.update_url,
                  "aria-label": "Edit " + cellProps.item.name,
                  variant: "primary",
                },
              },
              [_c("b-icon-pencil")],
              1
            ),
            _vm._v(" "),
            _c("delete-button", {
              attrs: {
                "item-to-delete": cellProps.item,
                "item-name": cellProps.item.name,
              },
              on: {
                "item-deleted": function ($event) {
                  _vm.refreshTable = true
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }