var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "lazy-until-loaded-tab",
    _vm._b(
      {
        attrs: {
          "tab-id": "measurement_widgets",
          title: "Measurement Widgets",
        },
        scopedSlots: _vm._u([
          {
            key: "tabBody",
            fn: function () {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "float-right my-2",
                    attrs: { href: _vm.createUrl, variant: "primary" },
                  },
                  [_vm._v("\n            Add Measurement Wdiget\n        ")]
                ),
                _vm._v(" "),
                _c("measurement-widget-list", {
                  attrs: { "api-url": _vm.apiUrl },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "lazy-until-loaded-tab",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }