var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-2", staticStyle: { width: "100%" } }, [
    _c(
      "a",
      {
        staticClass: "d-flex card-button-link h-100",
        attrs: { href: _vm.url, target: "_blank", rel: "noopener noreferrer" },
      },
      [
        _c(
          "b-card",
          {
            staticClass: "rounded flex-grow-1",
            attrs: { "body-class": _vm.bodyClasses },
          },
          [
            _c(
              "b-row",
              [
                _vm.logo
                  ? _c(
                      "b-col",
                      [
                        _c("b-card-img", {
                          class: _vm.logoClass,
                          attrs: {
                            src: _vm.logo,
                            alt: _vm.title + " logo",
                            left: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-col",
                  _vm._b({}, "b-col", _vm.cardTextProps, false),
                  [
                    _c("b-card-title", { staticClass: "title" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.title) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.subheading
                      ? _c("b-card-text", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.subheading) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.description
                      ? _c("b-card-text", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.description) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }