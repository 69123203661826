<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { DateTime } from 'luxon';

import ApiDrivenForm from '../forms/ApiDrivenForm.vue';

import ProposalUsersForm from './ProposalUsersForm.vue';

const SCHEMA = [
    {
        fields: {
            name: {
                component: 'input-field',
                id: 'proposal-name-input',
                label: 'Proposal Name',
                required: true,
                customValidationRules: { max: 150 },
                placeholder: 'Proposal Name',
            },
            number: {
                component: 'input-field',
                id: 'proposal-number-input',
                label: 'Proposal Number',
                required: true,
                customValidationRules: { max: 150 },
                placeholder: 'Proposal Number',
            },
            date: {
                component: 'input-field',
                id: 'proposal-date-input',
                label: 'Proposal Date',
                type: 'date',
                placeholder: 'Proposal Date',
            },
            proposed_by: {
                component: 'input-field',
                id: 'proposal-proposed-by-input',
                label: 'Proposed By',
                customValidationRules: { max: 150 },
                placeholder: 'Proposed By',
            },
            estimated_project_cost: {
                component: 'input-field',
                id: 'proposal-estimated-project-cost-input',
                label: 'Estimated Project Cost',
                type: 'number',
                placeholder: '$ -',
            },
            service_fee: {
                component: 'input-field',
                id: 'proposal-service-fee-input',
                label: 'Service Fee',
                type: 'number',
                placeholder: '$ -',
            },
        },
    },
    {
        groupName: 'Users',
        fields: {
            users: {
                component: 'proposal-users-form',
                id: 'proposal-users-input',
                optionsUrl: '',
                fieldApiUrl: '',
            },
        },
    },
    {
        fields: {
            approved_by: {
                component: 'input-field',
                id: 'proposal-approved-by-input',
                label: 'Approved By',
                customValidationRules: { max: 150 },
                placeholder: 'Approved By',
            },
            approval_document: {
                component: 'file-field',
                id: 'proposal-approval-document-input',
                label: 'Approval Document',
                mimeTypes: [],
                uploadedFileType: 'reporting',
            },
            status: {
                component: 'select-field',
                id: 'proposal-status-input',
                label: 'Proposal status',
                options: [],
            },
        },
    },
];

export default Vue.component('proposal-form', {
    components: { ProposalUsersForm },
    extends: ApiDrivenForm,
    props: {
        userTypeOptionsUrl: VueTypes.string.isRequired,
        userTypeCostOptionsUrl: VueTypes.string.isRequired,
        statusChoices: VueTypes.arrayOf(VueTypes.object),
    },
    created() {
        this.formSchema = SCHEMA;

        this.formSchema[1].fields.users.optionsUrl = this.userTypeOptionsUrl;
        this.formSchema[1].fields.users.fieldApiUrl =
            this.userTypeCostOptionsUrl;
        this.formSchema[2].fields.status.options = this.statusChoices;
    },
    mounted() {
        // Listen for the proposal users from the proposal users form component.
        this.$root.$on('proposal-users-updated', (value) => {
            this.formData.users = value;
        });
    },
    methods: {
        getFreshFormData() {
            return {
                date: DateTime.now().toFormat('yyyy-MM-dd'),
                status: 'pending',
            };
        },
        getFormData() {
            return this.formData;
        },
        getSuccessMessage() {
            return `Proposal '${this.formData.name}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
