var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "float-right mb-2",
          attrs: { disabled: _vm.loadingCsvData },
          on: { click: _vm.downloadCsvTemplate },
        },
        [_vm._v("\n        Download CSV\n    ")]
      ),
      _vm._v(" "),
      _c("paginated-api-table", {
        ref: "table",
        attrs: {
          "api-url": _vm.apiUrl,
          fields: _vm.tableFields,
          "initial-count": _vm.initialCount,
          "initial-data-element-id": _vm.initialDataElementId,
          "initial-num-pages": _vm.initialNumPages,
          "initial-page-size": _vm.initialPageSize,
        },
        scopedSlots: _vm._u([
          {
            key: "approval_record",
            fn: function (cellProps) {
              return [
                cellProps.item.approval_record
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: cellProps.item.approval_record.download_url,
                          download: cellProps.item.approval_record.name,
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(cellProps.item.approval_record.name) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "actionsColumn",
            fn: function (cellProps) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "rounded-circle",
                    attrs: {
                      href: cellProps.item.update_url,
                      "aria-label": "Edit " + cellProps.item.project_name,
                      variant: "primary",
                    },
                  },
                  [_c("b-icon-pencil")],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }