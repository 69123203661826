/**
 * Mixin for components that need to know if the admin
 * mode is enabled or not.
 */

import { createNamespacedHelpers } from 'vuex';

import { IS_ADMIN_ENABLED, MODULE_ADMIN } from '../../store/modules/admin';

const { mapState: mapAdminState } = createNamespacedHelpers(MODULE_ADMIN);

export default {
    computed: {
        ...mapAdminState({
            adminModeEnabled: IS_ADMIN_ENABLED,
        }),
    },
};
