var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-checkbox",
    {
      staticClass: "admin-mode-button",
      attrs: {
        value: _vm.adminModeEnabled,
        "button-variant": _vm.buttonVariant,
        name: "admin-mode-toggle",
        button: "",
      },
      on: { change: _vm.toggleAdminMode },
    },
    [_vm._v("\n    " + _vm._s(_vm.buttonText) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }