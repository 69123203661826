var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    ref: "validationProvider",
    attrs: { vid: _vm.name, name: _vm.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (validationContext) {
          return [
            _c(
              "b-form-group",
              {
                attrs: {
                  "label-for": _vm.id,
                  "invalid-feedback":
                    validationContext.errors[0] || _vm.uploadErrorMessage,
                  description: _vm.helpText,
                  "label-cols-md": _vm.blockLabel ? "0" : "4",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v("\n            " + _vm._s(_vm.label) + " "),
                          _vm.required ? _c("required-asterisk") : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "row",
                    class: {
                      "is-invalid":
                        _vm.getValidationState(validationContext) === false,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col flex-fill",
                        class: {
                          "col-sm-auto pr-sm-0 pr-md-0":
                            _vm.showRemoveFileButton,
                        },
                      },
                      [
                        _c("b-form-file", {
                          ref: "fileInput",
                          attrs: {
                            id: _vm.id,
                            value: _vm.internalValue,
                            name: _vm.name,
                            required: _vm.required,
                            accept: _vm.acceptedFileTypes,
                            placeholder: _vm.placeholder,
                            state:
                              _vm.fieldState ||
                              _vm.getValidationState(validationContext),
                          },
                          on: { input: _vm.handleFileChange },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            id: _vm.id + "-uploaded-file-id",
                            "v-model": _vm.value,
                            type: "hidden",
                          },
                        }),
                        _vm._v(" "),
                        _vm.isUploading || _vm.fileUploadStatus
                          ? _c("b-progress", {
                              attrs: {
                                value: 100,
                                variant: _vm.fileUploadStatus || "info",
                                animated: _vm.isUploading,
                                striped: _vm.isUploading,
                                height: "4px",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showRemoveFileButton
                      ? _c(
                          "div",
                          { staticClass: "col col-sm-2 mt-2 mt-sm-0" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-danger", block: "" },
                                on: { click: _vm.clearField },
                              },
                              [
                                _vm.breakpoints.xs
                                  ? _c("span", [_vm._v(" Remove file ")])
                                  : [
                                      _c("b-icon-trash"),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("Remove file"),
                                      ]),
                                    ],
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }