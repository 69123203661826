var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.adminModeEnabled
    ? _c(
        "span",
        [
          _vm.textual
            ? _c(
                "b-button",
                {
                  attrs: {
                    variant: "danger",
                    "data-toggle": "modal",
                    "data-target": "#" + _vm.modalId,
                    "data-backdrop": "false",
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n    ")]
              )
            : _c(
                "b-button",
                {
                  staticClass: "rounded-circle",
                  attrs: {
                    "aria-label": "Delete " + _vm.itemName,
                    variant: "danger",
                    "data-toggle": "modal",
                    "data-target": "#" + _vm.modalId,
                    "data-backdrop": "false",
                  },
                },
                [_c("b-icon-trash")],
                1
              ),
          _vm._v(" "),
          _c("dialog-box", {
            attrs: {
              "button-label": "Delete",
              "dismiss-button-label": "Cancel",
              "modal-title": "Warning",
              "button-variant": "danger",
              "modal-id": _vm.modalId,
            },
            on: {
              "action-accepted": function ($event) {
                return _vm.deleteItem(_vm.itemToDelete)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("p", [
                        _vm._v(
                          'Are you sure you want to delete "' +
                            _vm._s(_vm.itemName) +
                            '"?'
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("This action cannot be reversed.")]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1788265224
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }