var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    {
      attrs: { show: _vm.isBusy },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "text-center my-2",
                  staticStyle: { color: "grey" },
                },
                [
                  _c("b-spinner", { staticClass: "align-middle mr-1" }),
                  _vm._v(" "),
                  _c("strong", [
                    _vm.overlayText
                      ? _c("span", [_vm._v(_vm._s(_vm.overlayText))])
                      : _c("span", [_vm._v("Loading...")]),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "b-form",
                  { attrs: { "aria-hidden": _vm.isBusy ? "true" : null } },
                  [
                    _vm.infoText
                      ? _c(
                          "b-alert",
                          { attrs: { variant: "info", show: "" } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.infoText) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-form-text",
                      { staticClass: "mb-3" },
                      [_c("required-field-hint")],
                      1
                    ),
                    _vm._v(" "),
                    _vm.nonFieldErrors && _vm.nonFieldErrors.length
                      ? _c(
                          "b-alert",
                          { attrs: { variant: "danger", show: "" } },
                          [
                            Array.isArray(_vm.nonFieldErrors)
                              ? [
                                  _c(
                                    "ul",
                                    { staticClass: "error-list" },
                                    _vm._l(
                                      _vm.nonFieldErrors,
                                      function (error, index) {
                                        return _c("li", { key: index }, [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(error) +
                                              "\n                        "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              : _c("span", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.nonFieldErrors) +
                                      "\n                "
                                  ),
                                ]),
                          ],
                          2
                        )
                      : _vm.successMessage
                      ? _c(
                          "b-alert",
                          { attrs: { variant: "success", show: "" } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.successMessage) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.formSchema, function (formGroup, index) {
                      return _c("div", { key: index }, [
                        formGroup.groupName
                          ? _c("h3", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(formGroup.groupName) +
                                  "\n                "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: { "sub-form-group": formGroup.groupName } },
                          [
                            _vm._l(
                              formGroup.fields,
                              function (field, fieldName) {
                                return [
                                  field.hidden
                                    ? _c("input", {
                                        key: fieldName,
                                        attrs: { type: "hidden" },
                                      })
                                    : _c(field.component, {
                                        key: fieldName,
                                        tag: "component",
                                        attrs: {
                                          id: field.id,
                                          label: field.label,
                                          name: fieldName,
                                          "help-text": field.helpText,
                                          required: field.required || false,
                                          readonly: field.readonly || false,
                                          disabled: _vm.isBusy,
                                          "block-label":
                                            field.blockLabel || false,
                                          large: field.large || false,
                                          "custom-validation-rules":
                                            field.customValidationRules,
                                          placeholder: field.placeholder,
                                          type: field.type,
                                          options: field.options,
                                          "options-url": field.optionsUrl,
                                          "filter-options-url":
                                            field.filterOptionsUrl,
                                          "mime-types": field.mimeTypes,
                                          "uploaded-file-type":
                                            field.uploadedFileType,
                                          "existing-file-name":
                                            _vm.existingFileNames[fieldName],
                                          multiple: field.multiple,
                                          "modal-component":
                                            field.modalComponent,
                                          "modal-label": field.modalLabel,
                                          "modal-api-url": _vm.modalApiUrl,
                                          "api-url": field.fieldApiUrl,
                                          "arcgis-group-id":
                                            field.arcgisGroupId,
                                        },
                                        on: {
                                          "file-upload-in-progress": function (
                                            value
                                          ) {
                                            return (_vm.uploadInProgress =
                                              value)
                                          },
                                        },
                                        model: {
                                          value: _vm.formData[fieldName],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              fieldName,
                                              $$v
                                            )
                                          },
                                          expression: "formData[fieldName]",
                                        },
                                      }),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-1 mb-sm-0",
                        attrs: {
                          block: _vm.breakpoints.xs,
                          disabled: _vm.isBusy,
                        },
                        on: { click: _vm.cancel },
                      },
                      [_vm._v("\n                Cancel\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "float-right",
                        attrs: {
                          disabled:
                            invalid ||
                            _vm.uploadInProgress ||
                            _vm.isBusy ||
                            _vm.disabled,
                          block: _vm.breakpoints.xs,
                          variant: "primary",
                        },
                        on: { click: _vm.submit },
                      },
                      [
                        _vm.submitting
                          ? [
                              _c("b-spinner", {
                                attrs: { small: "", type: "grow" },
                              }),
                              _vm._v(
                                "\n                    Submitting...\n                "
                              ),
                            ]
                          : [_vm._v(" Submit ")],
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }