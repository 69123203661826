<template>
    <div class="my-2" style="width: 100%">
        <a
            :href="url"
            class="d-flex card-button-link h-100"
            target="_blank"
            rel="noopener noreferrer"
        >
            <b-card :body-class="bodyClasses" class="rounded flex-grow-1">
                <b-row>
                    <b-col v-if="logo">
                        <b-card-img
                            :src="logo"
                            :alt="`${title} logo`"
                            :class="logoClass"
                            left
                        />
                    </b-col>
                    <b-col v-bind="cardTextProps">
                        <b-card-title class="title">
                            {{ title }}
                        </b-card-title>
                        <b-card-text v-if="subheading" class="text-muted">
                            {{ subheading }}
                        </b-card-text>
                        <b-card-text v-if="description">
                            {{ description }}
                        </b-card-text>
                    </b-col>
                </b-row>
            </b-card>
        </a>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

export default Vue.component('project-component-card-button', {
    props: {
        title: VueTypes.string.isRequired,
        subheading: VueTypes.string.def(''),
        description: VueTypes.string.def(''),
        url: VueTypes.string.isRequired,
        logo: VueTypes.string,
        logoClass: VueTypes.string.def('card-button-logo'),
    },
    computed: {
        bodyClasses() {
            const defaultClasses = 'd-flex flex-column';

            return this.logo
                ? `${defaultClasses} justify-content-between`
                : `${defaultClasses} justify-content-end`;
        },
        cardTextProps() {
            if (this.logo) {
                return { md: 9 };
            }
            return { md: 12 };
        },
    },
});
</script>

<style lang="scss" scoped>
.card-button-link {
    color: inherit;

    &:hover,
    &:focus {
        .card {
            border-color: var(--primary);
            box-shadow: 0 0 10px 0 var(--primary);
            outline: none;
        }
    }
}

.card-button-logo {
    width: 100%;
    max-height: 140px;
    object-fit: contain;
}
</style>
