var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown",
        {
          staticClass: "float-right my-2 ml-1",
          attrs: { text: "Dropdown Button", right: "" },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _vm._v("\n            Bulk Action\n            "),
                  _c("b-icon-pencil"),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "b-dropdown-item-button",
            {
              attrs: {
                "data-toggle": "modal",
                "data-target": "#" + _vm.modalId,
                "data-backdrop": "false",
              },
            },
            [_vm._v("\n            Disable Selected Users\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        { staticClass: "float-right my-2 mx-1", on: { click: _vm.selectAll } },
        [_vm._v("\n        Select All\n    ")]
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "float-right my-2 mx-1",
          on: { click: _vm.deselectAll },
        },
        [_vm._v("\n        Deselect All\n    ")]
      ),
      _vm._v(" "),
      _c("dialog-box", {
        attrs: {
          "button-label": "Disable",
          "dismiss-button-label": "Cancel",
          "modal-title": "Warning",
          "button-variant": "danger",
          "modal-id": _vm.modalId,
        },
        on: { "action-accepted": _vm.bulkDisableUsers },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "Are you sure you want to disable the following users?"
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "confirm-selected-users-container" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.selectedItems, function (item) {
                      return _c("li", { key: item.pk }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.username) +
                            "\n                    "
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("paginated-api-table", {
        ref: "paginatedApiTable",
        attrs: {
          "api-url": _vm.apiUrl,
          fields: _vm.tableFields,
          "initial-count": _vm.initialCount,
          "initial-data-element-id": _vm.initialDataElementId,
          "initial-num-pages": _vm.initialNumPages,
          "initial-page-size": _vm.initialPageSize,
          selectable: "",
          "sticky-header": "",
          refresh: _vm.refreshTable,
        },
        on: {
          refreshed: function ($event) {
            _vm.refreshTable = false
          },
          "row-selected": _vm.onRowSelected,
        },
        scopedSlots: _vm._u([
          {
            key: "username",
            fn: function (cellProps) {
              return [
                _c("a", { attrs: { href: cellProps.item.update_url } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(cellProps.item.username) +
                      "\n            "
                  ),
                ]),
              ]
            },
          },
          {
            key: "corporation",
            fn: function (cellProps) {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(cellProps.item.corporation_name) +
                    "\n        "
                ),
              ]
            },
          },
          {
            key: "is_create_admin",
            fn: function (cellProps) {
              return [
                _c(_vm.getBooleanIcon(cellProps.item, "is_create_admin"), {
                  tag: "component",
                  attrs: {
                    variant: _vm.getBooleanVariant(
                      cellProps.item,
                      "is_create_admin"
                    ),
                    "aria-hidden": "true",
                  },
                }),
              ]
            },
          },
          {
            key: "date_joined",
            fn: function (cellProps) {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.getDateFromDatetimeString(cellProps.item.date_joined)
                    ) +
                    "\n        "
                ),
              ]
            },
          },
          {
            key: "last_login",
            fn: function (cellProps) {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.getDateFromDatetimeString(cellProps.item.last_login)
                    ) +
                    "\n        "
                ),
              ]
            },
          },
          {
            key: "is_active",
            fn: function (cellProps) {
              return [
                _c(_vm.getBooleanIcon(cellProps.item, "is_active"), {
                  tag: "component",
                  attrs: {
                    variant: _vm.getBooleanVariant(cellProps.item, "is_active"),
                    "aria-hidden": "true",
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }