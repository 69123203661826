<script>
import Vue from 'vue/dist/vue.esm.js';

import ApiDrivenForm from '../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            name: {
                component: 'input-field',
                id: 'corporation-name-input',
                label: 'Name',
                required: true,
                customValidationRules: { max: 150 },
                placeholder: 'Corporation Name',
            },
            email_domain: {
                component: 'input-field',
                id: 'corporation-email_domain-input',
                label: 'Email Domain',
                required: true,
                customValidationRules: { max: 150 },
                placeholder: 'Corporation Email Domain e.g. @wsp.com',
            },
            company_phone: {
                component: 'input-field',
                id: 'corporation-company-phone-input',
                label: 'Company Phone',
                required: true,
                customValidationRules: { max: 15, phoneNumber: true },
                placeholder: '04########',
            },
            state: {
                component: 'input-field',
                id: 'corporation-state-input',
                label: 'State',
                required: true,
                customValidationRules: { max: 150 },
                placeholder: 'Head Office State',
            },
            city: {
                component: 'input-field',
                id: 'corporation-city-input',
                label: 'City',
                required: true,
                customValidationRules: { max: 150 },
                placeholder: 'Head Office City',
            },
            postcode: {
                component: 'input-field',
                id: 'corporation-postcode-input',
                label: 'Postcode',
                required: true,
                customValidationRules: { max: 8 },
                placeholder: '####',
                type: 'number',
            },
            address: {
                component: 'input-field',
                id: 'corporation-address-input',
                label: 'Address',
                required: true,
                customValidationRules: { max: 250 },
                placeholder: 'Head Office Address',
            },
        },
    },
];

export default Vue.component('corporation-form', {
    extends: ApiDrivenForm,
    props: {},
    created() {
        this.formSchema = SCHEMA;
    },
    methods: {
        getFormData() {
            return this.formData;
        },
        getSuccessMessage() {
            return `Corporation '${this.formData.name}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
