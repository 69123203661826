<template>
    <lazy-until-loaded-tab
        tab-id="tile_schemas"
        title="Tile schemas"
        v-bind="$attrs"
    >
        <template #tabBody>
            <b-button
                :href="createUrl"
                class="float-right my-2"
                variant="primary"
            >
                Add Tile Schema
            </b-button>

            <tile-schema-list :api-url="apiUrl" />
        </template>
    </lazy-until-loaded-tab>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import LazyUntilLoadedTab from '../../LazyUntilLoadedTab.vue';

import TileSchemaList from './TileSchemaList.vue';

export default Vue.component('tile-schemas-tab', {
    components: { LazyUntilLoadedTab, TileSchemaList },
    inheritAttrs: false,
    props: {
        apiUrl: VueTypes.string.isRequired,
        createUrl: VueTypes.string.isRequired,
    },
});
</script>
