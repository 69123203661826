<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import axios from 'axios';
import { createNamespacedHelpers } from 'vuex';

import {
    MODULE_MESSAGES,
    MUTATION_ADD_MESSAGE,
} from '../../store/modules/messages';
import ApiDrivenForm from '../forms/ApiDrivenForm.vue';
const { mapMutations } = createNamespacedHelpers(MODULE_MESSAGES);

const SCHEMA = [
    {
        fields: {
            username: {
                id: 'user-username-input',
                hidden: true,
            },
            email: {
                component: 'input-field',
                id: 'user-email-input',
                label: 'Email Address',
                required: true,
                helpText: 'Email address will be the username in Esri Portal.',
                customValidationRules: { max: 254 },
            },
            new_password: {
                component: 'input-field',
                id: 'user-password-input',
                label: 'Password',
                required: false,
                type: 'password',
                helpText: 'Required for client users. Ignored for WSP users.',
                customValidationRules: { min: 8, max: 128 },
            },
            first_name: {
                component: 'input-field',
                id: 'user-first-name-input',
                label: 'First Name',
                required: true,
                customValidationRules: { max: 30 },
            },
            last_name: {
                component: 'input-field',
                id: 'user-last-name-input',
                label: 'Last Name',
                required: true,
                customValidationRules: { max: 150 },
            },
            is_create_admin: {
                component: 'checkbox-field',
                id: 'user-create-admin-input',
                label: 'WSP Create Admin',
                required: false,
            },
            user_type: {
                component: 'select-field',
                id: 'user-type-input',
                label: 'User Type',
                required: true,
                multiple: false,
                optionsUrl: '',
                readonly: false,
                helpText: '',
            },
            role: {
                component: 'select-field',
                id: 'user-role-input',
                label: 'Role',
                required: true,
                multiple: false,
                optionsUrl: '',
                readonly: false,
                helpText: '',
            },
            additional_information: {
                component: 'input-field',
                id: 'user-info-input',
                label: 'Additional Information',
                required: false,
                type: 'textarea',
                multiple: true,
                optionsUrl: '',
            },
            disabled: {
                component: 'checkbox-field',
                id: 'user-disabled-input',
                label: 'Disabled',
                required: false,
            },
            projects: {
                component: 'modal-field',
                id: 'user-projects-input',
                label: 'Projects',
                required: false,
                modalComponent: 'project-search-form',
                modalLabel: 'Add to Projects',
            },
            notify: {
                component: 'checkbox-field',
                id: 'user-notify-input',
                label: 'Notify user about newly assigned projects?',
                required: false,
            },
        },
    },
];

export default Vue.component('user-form', {
    extends: ApiDrivenForm,
    props: {
        userTypeOptionsUrl: VueTypes.string.isRequired,
        roleOptionsUrl: VueTypes.string.isRequired,
        projectsApiUrl: VueTypes.string.isRequired,
        arcgisSearchApiUrl: VueTypes.string,
    },
    data() {
        return {
            modalApiUrl: this.projectsApiUrl,
            overlayText: 'Searching Esri Portal users...',
        };
    },
    created() {
        this.formSchema = SCHEMA;
        this.formSchema[0].fields.role.optionsUrl = this.roleOptionsUrl;
        this.formSchema[0].fields.user_type.optionsUrl =
            this.userTypeOptionsUrl;

        // Remove fields from the Edit form
        if (this.initialFormDataElementId) {
            delete this.formSchema[0].fields.email;
            delete this.formSchema[0].fields.new_password;
        }
    },
    mounted() {
        // Listen for the selected projects from the projects modal component.
        this.$root.$on('projects-value-submitted', (value) => {
            this.formData.projects = value;
        });

        // If in create mode, when email input is blurred, do a search on Esri Portal
        // to check if user already exists.
        if (!this.initialFormDataElementId && this.arcgisSearchApiUrl) {
            document
                .getElementById('user-email-input')
                .addEventListener('blur', this.searchArcgisForUser);
        }
    },
    methods: {
        ...mapMutations([MUTATION_ADD_MESSAGE]),
        getFormData() {
            const formData = structuredClone(this.formData);

            // Django doesn't like it when the password attribute isn't in the request, but
            // doesn't care if it's blank. This happens when the password input isn't touched at all.
            if (!('new_password' in formData)) {
                formData.new_password = '';
            }

            formData.username = formData.email;
            return formData;
        },
        getSuccessMessage() {
            return `User '${this.formData.first_name} ${this.formData.last_name}' was ${this.actionString} successfully.`;
        },
        resetFields() {
            this.formSchema[0].fields.email.helpText =
                'Only for Create; use email as username in Esri.';

            delete this.formData.user_type;
            this.formSchema[0].fields.user_type.helpText = '';
            this.formSchema[0].fields.user_type.readonly = false;

            delete this.formData.role;
            this.formSchema[0].fields.role.helpText = '';
            this.formSchema[0].fields.role.readonly = false;
        },
        searchArcgisForUser() {
            if (!this.formData.email) {
                return;
            }

            this.isBusy = true;
            axios
                .get(this.arcgisSearchApiUrl, {
                    params: { search_term: this.formData.email },
                })
                .then((response) => {
                    this.resetFields();

                    if (response.data.user) {
                        this.formSchema[0].fields.email.helpText =
                            'User exists in Esri Portal, user type and role have been populated';

                        if (response.data.user.user_type) {
                            this.formData.user_type =
                                response.data.user.user_type;
                            this.formSchema[0].fields.user_type.readonly = true;
                            this.formSchema[0].fields.user_type.helpText =
                                'Populated from user type defined in Esri Portal';
                        }
                        if (response.data.user.role) {
                            this.formData.role = response.data.user.role;
                            this.formSchema[0].fields.role.readonly = true;
                            this.formSchema[0].fields.role.helpText =
                                'Populated from role defined in Esri Portal';
                        }
                    }
                })
                .catch(() => {
                    this[MUTATION_ADD_MESSAGE]({
                        text: 'An error occurred while searching the Esri Portal.',
                        variant: 'danger',
                    });
                })
                .finally(() => (this.isBusy = false));
        },
    },
});
</script>
