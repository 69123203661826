import Vue from 'vue/dist/vue.esm.js';
// eslint-disable-next-line import/order
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import './vue_components/SiteWrapper.vue';

// Install Bootstrap Vue and the icon plugin.
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Import BootstrapVue CSS files.
import 'bootstrap-vue/dist/bootstrap-vue.css';

import vSelect from 'vue-select';

// Install VeeValidate and register the validation rules.
// eslint-disable-next-line import/order
import registerValidationRules from './validation-rules';
registerValidationRules();

import store from './store';
import AddUsersToProjectsForm from './vue_components/tools/AddUsersToProjectsForm.vue';
import AdminModeToggleButton from './vue_components/AdminModeToggleButton.vue';
import AdminOnlyButton from './vue_components/AdminOnlyButton.vue';
import AdminProjectList from './vue_components/projects/AdminProjectList.vue';
import AlertMessages from './vue_components/AlertMessages.vue';
import ApiSearchSelectForm from './vue_components/forms/ApiSearchSelectForm.vue';
import BridgeConversionForm from './vue_components/tools/BridgeConversionForm.vue';
import BridgeConversionList from './vue_components/tools/BridgeConversionList.vue';
import CardButton from './vue_components/CardButton.vue';
import CorporationForm from './vue_components/accounts/CorporationForm.vue';
import CorporationList from './vue_components/accounts/CorporationList.vue';
import DeleteButton from './vue_components/DeleteButton.vue';
import DialogBox from './vue_components/DialogBox.vue';
import ImportProjectUsersFileForm from './vue_components/tools/ImportProjectUsersFileForm.vue';
import ImportProjectUsersTaskList from './vue_components/tools/ImportProjectUsersTaskList.vue';
import JsonDisplayField from './vue_components/JsonDisplayField.vue';
import LazyUntilLoadedTab from './vue_components/LazyUntilLoadedTab.vue';
import MeasurementUnitForm from './vue_components/maps/widgets/MeasurementUnitForm.vue';
import MeasurementUnitsList from './vue_components/maps/widgets/MeasurementUnitsList.vue';
import MeasurementWidgetForm from './vue_components/maps/widgets/MeasurementWidgetForm.vue';
import MeasurementWidgetTab from './vue_components/maps/widgets/MeasurementWidgetTab.vue';
import OrganisationForm from './vue_components/organisations/OrganisationForm.vue';
import OrganisationList from './vue_components/organisations/OrganisationList.vue';
import PolygonField from './vue_components/forms/PolygonField.vue';
import PrintServiceForm from './vue_components/maps/printing/PrintServiceForm.vue';
import PrintServiceList from './vue_components/maps/printing/PrintServiceList.vue';
import ProjectComponentForm from './vue_components/projects/ProjectComponentForm.vue';
import ProjectComponentList from './vue_components/projects/ProjectComponentList.vue';
import ProjectComponentOrderForm from './vue_components/projects/ProjectComponentOrderForm.vue';
import ProjectForm from './vue_components/projects/ProjectForm.vue';
import ProjectGroupForm from './vue_components/accounts/ProjectGroupForm.vue';
import ProjectGroupList from './vue_components/accounts/ProjectGroupList.vue';
import ProjectList from './vue_components/projects/ProjectList.vue';
import ProjectSearchForm from './vue_components/projects/ProjectSearchForm.vue';
import ProjectServiceFeeList from './vue_components/reporting/ProjectServiceFeeList.vue';
import ProjectUsersForm from './vue_components/projects/ProjectUsersForm.vue';
import ProposalForm from './vue_components/reporting/ProposalForm.vue';
import ProposalList from './vue_components/reporting/ProposalList.vue';
import SpatialReferenceForm from './vue_components/maps/spatialReferences/SpatialReferenceForm.vue';
import SpatialReferencesTab from './vue_components/maps/spatialReferences/SpatialReferencesTab.vue';
import ThemeForm from './vue_components/maps/themes/ThemeForm.vue';
import ThemesTab from './vue_components/maps/themes/ThemesTab.vue';
import TileSchemaList from './vue_components/maps/tileschemas/TileSchemaList.vue';
import TileSchemaForm from './vue_components/maps/tileschemas/TileSchemaForm.vue';
import TileSchemasTab from './vue_components/maps/tileschemas/TileSchemasTab.vue';
import UserForm from './vue_components/accounts/UserForm.vue';
import UserList from './vue_components/accounts/UserList.vue';

Vue.component('v-select', vSelect);

new Vue({
    el: '#vue-app',
    components: {
        AddUsersToProjectsForm,
        AdminModeToggleButton,
        AdminOnlyButton,
        AdminProjectList,
        AlertMessages,
        ApiSearchSelectForm,
        BridgeConversionForm,
        BridgeConversionList,
        CardButton,
        CorporationForm,
        CorporationList,
        DeleteButton,
        DialogBox,
        ImportProjectUsersFileForm,
        ImportProjectUsersTaskList,
        JsonDisplayField,
        LazyUntilLoadedTab,
        MeasurementUnitForm,
        MeasurementUnitsList,
        MeasurementWidgetForm,
        MeasurementWidgetTab,
        OrganisationForm,
        OrganisationList,
        PolygonField,
        PrintServiceForm,
        PrintServiceList,
        ProjectComponentForm,
        ProjectComponentList,
        ProjectComponentOrderForm,
        ProjectForm,
        ProjectGroupForm,
        ProjectGroupList,
        ProjectList,
        ProjectSearchForm,
        ProjectServiceFeeList,
        ProjectUsersForm,
        ProposalForm,
        ProposalList,
        SpatialReferenceForm,
        SpatialReferencesTab,
        ThemeForm,
        ThemesTab,
        TileSchemaList,
        TileSchemaForm,
        TileSchemasTab,
        UserForm,
        UserList,
    },
    store,
});
