<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import truncate from 'lodash/truncate';

import { stripHtml } from '../../utils';
import ApiSearchSelectForm from '../forms/ApiSearchSelectForm.vue';

export default Vue.component('project-form-select-existing-group', {
    extends: ApiSearchSelectForm,
    props: {
        arcgisGroupId: VueTypes.string,
    },
    data() {
        return {
            enabled: !!this.arcgisGroupId, // Hidden by default for Create Project only.
            helpText: 'Search for and select from the list of Portal Groups',
            searchLabel: 'Portal Group',
            tableFields: [
                { key: 'name', label: 'Name', tdClass: 'align-left' },
                {
                    key: 'description',
                    tdClass: 'align-left',
                    formatter: (value) =>
                        truncate(stripHtml(value), { length: 500 }),
                },
                { key: 'selected', label: 'Select', tdClass: 'align-left' },
            ],
            selectable: true,
            selectMode: 'single',
            stickyHeader: '450px',
        };
    },
    mounted() {
        // Listens for a selected value from ProjectFormIsExistingGroup.
        this.$root.$on('is-existing-group', (value) => {
            if (value === 'N/A') {
                this.enabled = false;
            } else {
                this.enabled = !!value;
            }
        });

        if (this.arcgisGroupId) {
            this.setParams();
        }
    },
    methods: {
        setParams() {
            const baseParams = this.arcgisGroupId
                ? { current_group: this.arcgisGroupId }
                : {};
            this.params = {
                ...baseParams,
                search_term: this.searchTermInProgress,
            };
        },
        onIsBusy(value) {
            // Preselect the initial result on the Update form.
            if (this.arcgisGroupId && !value) {
                const index = this.$refs.table.items.findIndex(
                    (row) => row.arcgis_group_id === this.arcgisGroupId,
                );
                if (index >= 0) {
                    this.$refs.table.$refs.table.selectRow(index);
                }
            }
        },
        onRowSelected(items) {
            if (items && items.length && items[0].existing_project_name) {
                this.showWarning = true;
                this.warningMessage = `Warning: Esri group ${items[0].name} is already linked to project ${items[0].existing_project_name}`;
                this.$root.$emit('row-selected', []);
            } else {
                this.showWarning = false;
                this.$root.$emit('row-selected', items);
            }
        },
    },
});
</script>
