var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.showModal,
        title: "Reorder project components",
        "ok-title": "Save",
        size: "lg",
        centered: "",
        scrollable: "",
      },
      on: {
        hidden: function ($event) {
          return _vm.$emit("hidden")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function (ref) {
            var cancel = ref.cancel
            var close = ref.close
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveOrder(close)
                    },
                  },
                },
                [_vm._v("\n            Save\n        ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm.errorMessage
        ? _c(
            "b-alert",
            { attrs: { variant: _vm.alertVariant, dismissible: "", show: "" } },
            [_vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "table table-striped" },
        [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("ID")]),
              _vm._v(" "),
              _c("th", [_vm._v("Name")]),
              _vm._v(" "),
              _c("th", [_vm._v("Type")]),
              _vm._v(" "),
              _c("th", [_vm._v("URL")]),
              _vm._v(" "),
              _c("th"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            {
              class: { "is-dragging": _vm.dragging },
              attrs: { tag: "tbody" },
              on: {
                start: function ($event) {
                  _vm.dragging = true
                },
                end: function ($event) {
                  _vm.dragging = false
                },
              },
              model: {
                value: _vm.items,
                callback: function ($$v) {
                  _vm.items = $$v
                },
                expression: "items",
              },
            },
            _vm._l(_vm.items, function (item) {
              return _c("tr", { key: item.pk }, [
                _c("td", { staticClass: "align-middle" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.pk) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "align-middle" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.name) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "align-middle" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.getCapitalisedText(item.type)) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "align-middle" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.url) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "align-middle" },
                  [
                    _c("b-icon", {
                      attrs: {
                        icon: "grid-3x2-gap",
                        variant: "dark",
                        rotate: "90",
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }