<template>
    <lazy-until-loaded-tab
        tab-id="measurement_widgets"
        title="Measurement Widgets"
        v-bind="$attrs"
    >
        <template #tabBody>
            <b-button
                :href="createUrl"
                class="float-right my-2"
                variant="primary"
            >
                Add Measurement Wdiget
            </b-button>

            <measurement-widget-list :api-url="apiUrl" />
        </template>
    </lazy-until-loaded-tab>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import LazyUntilLoadedTab from '../../LazyUntilLoadedTab.vue';

import MeasurementWidgetList from './MeasurementWidgetList.vue';

export default Vue.component('measurement-widget-tab', {
    components: { LazyUntilLoadedTab, MeasurementWidgetList },
    inheritAttrs: false,
    props: {
        apiUrl: VueTypes.string.isRequired,
        createUrl: VueTypes.string.isRequired,
    },
});
</script>
