<template>
    <div>
        <b-alert v-if="errorMessage" variant="danger" dismissible show>
            {{ errorMessage }}
        </b-alert>

        <div v-else-if="isLoading" class="mt-5">
            <b-progress
                :value="100"
                variant="info"
                label="Loading..."
                animated
            />
        </div>

        <template v-else-if="objects && objects.length">
            <slot name="listHeader" />

            <slot v-bind="objects" name="listBody" />

            <slot name="pagination">
                <b-pagination
                    v-if="numPages > 1"
                    v-model="currentPage"
                    :total-rows="count"
                    align="center"
                    class="mt-4"
                />
            </slot>
        </template>

        <b-alert v-else variant="info" show>
            <slot name="noData">
                There are currently no items available. Please refresh the page
                and try again, or contact an administrator.
            </slot>
        </b-alert>
    </div>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import { DEFAULT_ERROR_MESSAGE } from '../constants';
import { getDeserializedData } from '../utils';

export default Vue.component('paginated-card-list', {
    props: {
        apiUrl: VueTypes.string.isRequired,
        initialCount: VueTypes.integer.def(0),
        initialDataElementId: VueTypes.string.isRequired,
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
    },
    data() {
        return {
            count: this.initialCount,
            currentPage: 1,
            errorMessage: '',
            isLoading: false,
            numPages: this.initialNumPages,
            objects: getDeserializedData(this.initialDataElementId),
            pageSize: this.initialPageSize,
        };
    },
    watch: {
        currentPage() {
            this.getData();
        },
    },
    methods: {
        getData() {
            this.isLoading = true;

            axios
                .get(this.apiUrl, { params: { page: this.currentPage } })
                .then((response) => {
                    this.count = response.data.count;
                    this.currentPage = response.data.current_page;
                    this.numPages = response.data.num_pages;
                    this.pageSize = response.data.page_size;
                    this.objects = response.data.results;
                })
                .catch((error) => {
                    this.errorMessage = _get(
                        error,
                        'response.data.detail',
                        DEFAULT_ERROR_MESSAGE,
                    );
                })
                .finally(() => {
                    // Delay showing results slightly, to avoid the loading icon flashing quickly
                    // on and off screen during fast responses.
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 300);
                });
        },
    },
});
</script>
