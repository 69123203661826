var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { vid: _vm.name, name: _vm.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (validationContext) {
          return [
            _c(
              "b-form-group",
              {
                attrs: {
                  "label-for": _vm.id,
                  "invalid-feedback": validationContext.errors[0],
                  description: _vm.helpText,
                  "label-cols-md": _vm.blockLabel ? "0" : "4",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v("\n            " + _vm._s(_vm.label) + " "),
                          _vm.required ? _c("required-asterisk") : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _c(_vm.fieldComponent, {
                  tag: "component",
                  attrs: {
                    id: _vm.id,
                    value: _vm.value,
                    name: _vm.name,
                    type: _vm.type,
                    required: _vm.required,
                    readonly: _vm.readonly,
                    disabled: _vm.disabled,
                    placeholder: _vm.placeholder,
                    state: _vm.getValidationState(validationContext),
                  },
                  on: {
                    input: function (value) {
                      return _vm.$emit("input", value)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }