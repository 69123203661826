var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("radio-field-group", {
    attrs: {
      id: "is-existing-group",
      "help-text": "A new Group in Portal will be created if required.",
      label: "Is there an existing Portal Group?",
      name: "is_existing_group",
      required: "",
      options: [
        { text: "Yes", value: true },
        { text: "No", value: false },
        { text: "Not required", value: "N/A" },
      ],
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }