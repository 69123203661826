<template>
    <div>
        <b-button
            class="float-right mb-2"
            :disabled="loadingCsvData"
            @click="downloadCsvTemplate"
        >
            Download CSV
        </b-button>
        <paginated-api-table
            ref="table"
            :api-url="apiUrl"
            :fields="tableFields"
            :initial-count="initialCount"
            :initial-data-element-id="initialDataElementId"
            :initial-num-pages="initialNumPages"
            :initial-page-size="initialPageSize"
        >
            <template #approval_record="cellProps">
                <a
                    v-if="cellProps.item.approval_record"
                    :href="cellProps.item.approval_record.download_url"
                    :download="cellProps.item.approval_record.name"
                >
                    {{ cellProps.item.approval_record.name }}
                </a>
            </template>
            <template #actionsColumn="cellProps">
                <b-button
                    :href="cellProps.item.update_url"
                    :aria-label="`Edit ${cellProps.item.project_name}`"
                    class="rounded-circle"
                    variant="primary"
                >
                    <b-icon-pencil />
                </b-button>
            </template>
        </paginated-api-table>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import axios from 'axios';
import { DateTime } from 'luxon';

import PaginatedApiTable from '../PaginatedApiTable.vue';
import { formatDatetimeToDefault } from '../../utils';

export default Vue.component('project-service-fee-list', {
    components: {
        PaginatedApiTable,
    },
    props: {
        apiUrl: VueTypes.string.isRequired,
        initialCount: VueTypes.integer.def(0),
        initialDataElementId: VueTypes.string.isRequired,
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
        downloadApiUrl: VueTypes.string.isRequired,
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'project_name',
                    tdClass: 'align-middle',
                },

                {
                    key: 'project_code',
                    tdClass: 'align-middle',
                },
                {
                    key: 'create_date',
                    formatter: formatDatetimeToDefault,
                    tdClass: 'align-middle',
                },
                {
                    key: 'project_cost',
                    tdClass: 'align-middle',
                },
                {
                    key: 'service_fee',
                    tdClass: 'align-middle',
                },
                {
                    key: 'approved_by',
                    tdClass: 'align-middle',
                },
                {
                    key: 'approval_record',
                    tdClass: 'align-middle',
                },
                {
                    key: 'payment_status',
                    tdClass: 'align-middle',
                },
                {
                    key: 'notes',
                    tdClass: 'align-middle',
                },
                {
                    key: 'actions',
                    tdClass: 'align-middle',
                },
            ],
            loadingCsvData: false,
            loadedData: null,
        };
    },
    methods: {
        downloadCsvTemplate() {
            axios.get(this.downloadApiUrl).then(({ data }) => {
                let blob = new Blob([data], {
                    type: 'text/csv',
                });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `Project_Service_Fee_${DateTime.now().toFormat(
                    'yyyy_MM_dd',
                )}.csv`;
                link.click();

                this.loadingCsvData = false;
            });
        },
    },
});
</script>
