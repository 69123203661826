<template>
    <div>
        <div v-for="message in messages" :key="message.key">
            <b-alert
                :variant="message.variant"
                dismissible
                show
                @dismissed="dismissMessage(message)"
            >
                {{ message.text }}
            </b-alert>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types';
import Vue from 'vue/dist/vue.esm.js';
import { createNamespacedHelpers, mapState } from 'vuex';

import {
    MODULE_MESSAGES,
    MUTATION_ADD_MESSAGE,
    MUTATION_REMOVE_MESSAGE,
} from '../store/modules/messages';

const { mapMutations } = createNamespacedHelpers(MODULE_MESSAGES);

export default Vue.component('alert-messages', {
    props: {
        djangoMessages: VueTypes.array,
    },
    computed: {
        ...mapState('messagesModule', ['messages']),
    },
    created() {
        this.djangoMessages.forEach((message, index) => {
            this[MUTATION_ADD_MESSAGE]({
                text: message.text,
                variant: message.variant.replace('alert-', ''),
                key: 'django' + index,
            });
        });
    },
    methods: {
        ...mapMutations([MUTATION_ADD_MESSAGE, MUTATION_REMOVE_MESSAGE]),
        dismissMessage(message) {
            this[MUTATION_REMOVE_MESSAGE](message);
        },
    },
});
</script>
