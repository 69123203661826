var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      staticClass: "filter-container",
      attrs: { id: _vm.fieldName + "-filter" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return function () {
            return _vm.$emit("apply-search-filter")
          }.apply(null, arguments)
        },
      },
    },
    [
      _c("b-form-input", {
        attrs: {
          placeholder: "Enter " + _vm.data.label + "...",
          value: _vm.value,
        },
        on: {
          input: function (value) {
            return _vm.$emit("input", value)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "b-button",
        {
          key: _vm.fieldName + "-button",
          staticClass: "filter-button",
          attrs: { type: "submit" },
        },
        [_c("b-icon-search")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }