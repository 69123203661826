<script>
import Vue from 'vue/dist/vue.esm.js';

import ApiDrivenForm from '../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            name: {
                component: 'input-field',
                id: 'organisation-name-input',
                label: 'Name',
                required: true,
            },
            slug: {
                component: 'input-field',
                id: 'organisation-slug-input',
                label: 'Slug',
                required: true,
                helpText:
                    'Please enter a suitable name for the Organisation URL using only letters, numbers, underscores or hyphens.',
                customValidationRules: { alpha_dash: true },
            },
            background: {
                component: 'file-field',
                id: 'organisation-background-input',
                label: 'Background',
                helpText:
                    'Please upload an image to use as the background for the Organisation pages.',
                mimeTypes: ['image/jpeg', 'image/png'],
                uploadedFileType: 'organisations',
            },
            logo: {
                component: 'file-field',
                id: 'organisation-logo-input',
                label: 'Logo',
                helpText:
                    'Please upload an image to use as the logo within the Organisation pages.',
                mimeTypes: ['image/jpeg', 'image/png'],
                uploadedFileType: 'organisations',
            },
            menu_icon: {
                component: 'file-field',
                id: 'organisation-menu-icon-input',
                label: 'Menu icon',
                helpText:
                    'Please upload an image to use as the logo in the navigation menu.',
                mimeTypes: ['image/jpeg', 'image/png'],
                uploadedFileType: 'organisations',
            },
            favicon: {
                component: 'file-field',
                id: 'organisation-favicon-input',
                label: 'Favicon',
                helpText:
                    'Please upload an image to use in the website tab for the Organisation pages.',
                mimeTypes: ['image/jpeg', 'image/png'],
                uploadedFileType: 'organisations',
            },
            is_published: {
                component: 'checkbox-field',
                id: 'organisation-is-published-input',
                label: 'Is this Organisation published?',
                helpText:
                    'Authorised users will only see Organisations that are set to Published.',
            },
        },
    },
];

export default Vue.component('organisation-form', {
    extends: ApiDrivenForm,
    created() {
        this.formSchema = SCHEMA;
    },
    methods: {
        getSuccessMessage() {
            return `Organisation '${this.formData.name}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
