<script>
import Vue from 'vue/dist/vue.esm.js';

import ApiDrivenForm from '../../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            srid: {
                component: 'input-field',
                id: 'spatial-reference-srid-input',
                label: 'SRID',
                type: 'number',
                required: true,
            },
            description: {
                component: 'input-field',
                id: 'spatial-reference-description-input',
                label: 'Description',
                type: 'textarea',
                required: true,
                customValidationRules: { max: 255 },
            },
        },
    },
];

export default Vue.component('spatial-reference-form', {
    extends: ApiDrivenForm,
    created() {
        this.formSchema = SCHEMA;
    },
    methods: {
        getSuccessMessage() {
            return `Spatial Reference '${this.formData.srid}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
