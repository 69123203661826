var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "float-right mb-2",
          attrs: { disabled: _vm.loadingCsvData },
          on: { click: _vm.downloadCsv },
        },
        [_vm._v("\n        Export CSV\n    ")]
      ),
      _vm._v(" "),
      _c("paginated-api-table", {
        attrs: {
          "api-url": _vm.apiUrl,
          fields: _vm.tableFields,
          "initial-count": _vm.initialCount,
          "initial-data-element-id": _vm.initialDataElementId,
          "initial-num-pages": _vm.initialNumPages,
          "initial-page-size": _vm.initialPageSize,
        },
        scopedSlots: _vm._u([
          {
            key: "number",
            fn: function (cellProps) {
              return [
                _c("a", { attrs: { href: cellProps.item.update_url } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(cellProps.item.number) +
                      "\n            "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }