<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import ApiDrivenForm from '../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            name: {
                component: 'input-field',
                id: 'project-group-name-input',
                label: 'Name',
                required: true,
                customValidationRules: { max: 150 },
            },
            authorised_components: {
                component: 'select-field',
                id: 'project-group-project-components-input',
                label: 'Project Components',
                required: true,
                multiple: true,
                optionsUrl: '',
            },
            users: {
                component: 'select-field',
                id: 'project-group-users-input',
                label: 'Users',
                required: true,
                multiple: true,
                optionsUrl: '',
            },
        },
    },
];

export default Vue.component('project-group-form', {
    extends: ApiDrivenForm,
    props: {
        userOptionsUrl: VueTypes.string.isRequired,
        projectComponentsOptionsUrl: VueTypes.string.isRequired,
    },
    created() {
        this.formSchema = SCHEMA;
        this.formSchema.users.optionsUrl = this.userOptionsUrl;
        this.formSchema.authorised_components.optionsUrl =
            this.projectComponentsOptionsUrl;
    },
    methods: {
        getSuccessMessage() {
            return `Project Group '${this.formData.name}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
