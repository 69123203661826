<template>
    <validation-provider
        #default="validationContext"
        :vid="name"
        :name="label"
        :rules="rules"
    >
        <b-form-group
            :label-for="id"
            :invalid-feedback="validationContext.errors[0]"
            :description="helpText"
            :label-cols-md="blockLabel ? '0' : '4'"
        >
            <template #label>
                {{ label }} <required-asterisk v-if="required" />
            </template>

            <b-form-input
                :id="id"
                v-model="urlString"
                :name="name"
                :required="required"
                :state="getValidationState(validationContext)"
                lazy-formatter
                @input="(value) => $emit('input', value)"
            />
        </b-form-group>
    </validation-provider>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { ValidationProvider } from 'vee-validate';

import RequiredAsterisk from './RequiredAsterisk.vue';

export default Vue.component('url-field', {
    components: {
        RequiredAsterisk,
        ValidationProvider,
    },
    props: {
        customValidationRules: VueTypes.object,
        id: VueTypes.string.isRequired,
        label: VueTypes.string.isRequired,
        name: VueTypes.string.isRequired,
        helpText: VueTypes.string,
        required: Boolean,
        type: VueTypes.string.def('text'),
        value: VueTypes.any,
        blockLabel: Boolean,
        placeholder: VueTypes.string,
    },
    data() {
        return {
            urlString: null,
            isValidState: null,
        };
    },
    computed: {
        rules() {
            return {
                required: this.required,
                validUrl: this.urlString,
                ...this.customValidationRules,
            };
        },
    },
    watch: {
        value(newValue) {
            this.urlString = newValue;
        },
    },
    created() {
        if (this.value) {
            this.urlString = this.value;
        }
    },
    methods: {
        getValidationState({ dirty, errors, validated, valid = null }) {
            const state = dirty || validated ? valid && !errors.length : null;
            this.isValidState = state;
            return state;
        },
    },
});
</script>
