<template>
    <div>
        <b-form @submit.prevent="addProposalUsers">
            <template v-for="(field, fieldName) in formFields">
                <component
                    :is="field.component"
                    :id="field.id"
                    :key="fieldName"
                    v-model="formData[fieldName]"
                    :label="field.label"
                    :name="fieldName"
                    :type="field.type"
                    :placeholder="field.placeholder"
                    :options-url="field.optionsUrl"
                />
            </template>
            <div class="clearfix">
                <b-button
                    class="float-right col-sm-2 mb-2"
                    :disabled="isAddButtonDisabled"
                    type="submit"
                >
                    Add Users
                </b-button>
            </div>
        </b-form>
        <div v-if="items.length !== 0" class="table-container">
            <b-table
                class="mb-0"
                :items="items"
                :sticky-header="true"
                :fields="tableFields"
            >
            </b-table>
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import axios from 'axios';

import InputField from '../forms/InputField.vue';

export default Vue.component('proposal-users-form', {
    components: { InputField },
    props: {
        optionsUrl: VueTypes.string.isRequired,
        apiUrl: VueTypes.string.isRequired,
        value: VueTypes.any,
    },
    data() {
        return {
            items: this.value || [],
            formData: {},
            formFields: {
                user_type: {
                    component: 'select-field',
                    id: 'proposal-user-type-input',
                    label: 'User Type',
                    placeholder: 'User Type',
                    optionsUrl: this.optionsUrl,
                },
                user_count: {
                    component: 'input-field',
                    id: 'proposal-user-count-input',
                    label: 'User Count',
                    type: 'number',
                    placeholder: '##',
                },
                user_fee: {
                    component: 'input-field',
                    id: 'proposal-user-fee-input',
                    label: 'User Fee',
                    placeholder: '$ -',
                },
            },
            userTypeOptions: null,
            userTypeCostOptions: null,
            tableFields: [
                {
                    key: 'user_type',
                    label: 'User Type',
                    formatter: (value) => {
                        if (this.userTypeOptions) {
                            return this.userTypeOptions.find(
                                (option) => option.value === value,
                            ).label;
                        }
                        return value;
                    },
                    tdClass: 'align-middle',
                },
                {
                    key: 'user_count',
                    label: 'User Count',
                    tdClass: 'align-middle',
                },
                {
                    key: 'user_fee',
                    label: 'User Fee',
                    formatter: (value) => `$${value}`,
                    tdClass: 'align-middle',
                },
            ],
        };
    },
    computed: {
        isAddButtonDisabled() {
            return !(
                this.formData.user_type &&
                this.formData.user_count &&
                this.formData.user_fee
            );
        },
    },
    watch: {
        'formData.user_type'(newVal) {
            if (newVal) {
                this.formData.user_fee = this.userTypeCostOptions.find(
                    (option) => option.value === newVal,
                ).label;
            }
        },
    },
    created() {
        if (this.optionsUrl) {
            axios.get(this.optionsUrl).then(({ data }) => {
                this.userTypeOptions = data.results;
            });
        }
        if (this.apiUrl) {
            axios.get(this.apiUrl).then(({ data }) => {
                this.userTypeCostOptions = data.results;
            });
        }
    },
    methods: {
        addProposalUsers() {
            this.items.push(this.formData);
            this.formData = {};
            this.$root.$emit('proposal-users-updated', this.items);
        },
    },
});
</script>

<style lang="scss" scoped>
.table-container {
    border: solid 1px #ccc;
    margin-bottom: 20px;
    background: white;
}
</style>
