<template>
    <validation-provider
        #default="validationContext"
        :vid="name"
        :name="label"
        :rules="rules"
    >
        <b-form-group
            :label-for="id"
            :invalid-feedback="validationContext.errors[0]"
            :description="helpText"
            :state="getValidationState(validationContext)"
            label-cols-md="4"
        >
            <template #label>
                {{ label }} <required-asterisk v-if="required" />
            </template>

            <v-select
                :id="id"
                v-model="selected"
                :multiple="multiple"
                :options="selectOptions"
                :required="required"
                :disabled="readonly || disabled"
                :reduce="(selected) => selected.value"
            ></v-select>
        </b-form-group>
    </validation-provider>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { ValidationProvider } from 'vee-validate';
import axios from 'axios';

import RequiredAsterisk from './RequiredAsterisk.vue';

export default Vue.component('select-field', {
    components: {
        RequiredAsterisk,
        ValidationProvider,
    },
    props: {
        customValidationRules: VueTypes.object,
        id: VueTypes.string.isRequired,
        label: VueTypes.string.isRequired,
        name: VueTypes.string.isRequired,
        helpText: VueTypes.string,
        required: Boolean,
        value: VueTypes.oneOfType([
            VueTypes.integer,
            VueTypes.string,
            VueTypes.array,
        ]),
        multiple: Boolean,
        options: VueTypes.array,
        optionsUrl: VueTypes.string,
        readonly: Boolean,
        disabled: Boolean,
    },
    data() {
        return {
            selectOptions: [],
        };
    },
    computed: {
        rules() {
            return {
                required: this.required,
                ...this.customValidationRules,
            };
        },
        selected: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
    created() {
        if (this.optionsUrl) {
            axios.get(this.optionsUrl).then(({ data }) => {
                this.selectOptions = data.results;
            });
        } else if (this.options) {
            this.selectOptions = this.options;
        } else {
            throw new Error(
                'Must provide either `options` or `optionsUrl` prop.',
            );
        }
    },
    methods: {
        getValidationState({ dirty, errors, validated, valid = null }) {
            return dirty || validated ? valid && !errors.length : null;
        },
    },
});
</script>
