<script>
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import { DEFAULT_ERROR_MESSAGE } from '../constants';

import ApiTable from './ApiTable.vue';

export default Vue.component('api-search-table', {
    extends: ApiTable,
    props: {
        params: VueTypes.object,
    },
    data() {
        return {
            items: [],
        };
    },
    watch: {
        params() {
            this.getData();
            this.$refs.table.refresh();
        },
    },
    methods: {
        getData() {
            if (this.initialLoad) {
                // Load the initial data without making an API call.
                this.initialLoad = false;
                this.items = [];
            }

            this.isBusy = true;

            return axios
                .get(this.apiUrl, { params: this.params })
                .then((response) => {
                    this.initialLoad = false;
                    this.items = response.data;
                })
                .catch(() => {
                    this.errorMessage = DEFAULT_ERROR_MESSAGE;
                    this.items = [];
                })
                .finally(() => (this.isBusy = false));
        },
    },
});
</script>
