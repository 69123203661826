var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tab",
    _vm._b(
      { attrs: { title: _vm.title }, on: { click: _vm.setActive } },
      "b-tab",
      _vm.$attrs,
      false
    ),
    [_vm.isActive ? [_vm._t("tabBody")] : _vm._e()],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }