var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("paginated-api-table", {
    attrs: {
      refresh: _vm.refreshTable,
      "api-url": _vm.apiUrl,
      fields: _vm.adminTableFields,
      "initial-count": _vm.initialCount,
      "initial-num-pages": _vm.initialNumPages,
      "initial-page-size": _vm.initialPageSize,
    },
    on: {
      refreshed: function ($event) {
        _vm.refreshTable = false
      },
    },
    scopedSlots: _vm._u([
      {
        key: "actionsColumn",
        fn: function (cellProps) {
          return [
            _c(
              "b-button",
              {
                attrs: {
                  disabled:
                    cellProps.item.num_converted !=
                    cellProps.item.number_of_bridges,
                  variant: "primary",
                },
                on: {
                  click: function ($event) {
                    return _vm.download(cellProps.item.pk)
                  },
                },
              },
              [_c("b-icon-download"), _vm._v(" Download\n        ")],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }