<template>
    <div class="col-xs-12 col-sm-6 col-lg-3 my-2">
        <a :href="url" class="d-flex card-button-link h-100">
            <b-card :body-class="bodyClasses" class="rounded flex-grow-1">
                <template v-if="logo">
                    <div>
                        <b-img
                            v-if="logo"
                            :src="logo"
                            :alt="`${title} logo`"
                            :class="logoClass"
                        />
                    </div>
                    <b-card-text class="mt-4 title">
                        {{ title }}
                    </b-card-text>
                </template>

                <b-card-title v-else class="title">{{ title }}</b-card-title>

                <b-card-text v-if="subheading" class="text-muted">
                    {{ subheading }}
                </b-card-text>
            </b-card>
        </a>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

export default Vue.component('card-button', {
    props: {
        title: VueTypes.string.isRequired,
        subheading: VueTypes.string.def(''),
        url: VueTypes.string.isRequired,
        logo: VueTypes.string,
        logoClass: VueTypes.string.def('card-button-logo'),
    },
    computed: {
        bodyClasses() {
            const defaultClasses = 'd-flex flex-column';

            return this.logo
                ? `${defaultClasses} justify-content-between`
                : `${defaultClasses} justify-content-end`;
        },
    },
});
</script>

<style lang="scss" scoped>
.card-button-link {
    color: inherit;

    &:hover,
    &:focus {
        .card {
            border-color: var(--primary);
            box-shadow: 0 0 10px 0 var(--primary);
            outline: none;
        }
    }
}

.card-button-logo {
    max-width: 100%;
    max-height: 140px;
}
</style>
