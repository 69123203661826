<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import ApiDrivenForm from '../../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            name: {
                component: 'input-field',
                id: 'measurement-widget-name-input',
                label: 'Name',
                required: true,
                customValidationRules: { max: 128 },
            },
            type: {
                component: 'select-field',
                id: 'measurement-widget-type-input',
                label: 'Type',
                required: true,
                customValidationRules: { max: 14 },
                options: [
                    { value: 'area_perimeter', label: 'Area / Perimeter' },
                    { value: 'buffer', label: 'Buffer' },
                    { value: 'measurement', label: 'Measurement' },
                ],
            },
            description: {
                component: 'input-field',
                id: 'measurement-widget-description-input',
                label: 'Description',
                required: true,
                customValidationRules: { max: 255 },
            },
            default_area_unit: {
                component: 'select-field',
                id: 'measurement-widget-default-area-unit-input',
                label: 'Default area unit',
                optionsUrl: '',
            },
            default_linear_unit: {
                component: 'select-field',
                id: 'measurement-widget-default-linear-unit-input',
                label: 'Default linear unit',
                required: true,
                optionsUrl: '',
            },
            measurement_units: {
                component: 'select-field',
                id: 'measurement-widget-measurement-units-input',
                multiple: true,
                label: 'Measurement units',
                optionsUrl: '',
            },
        },
    },
];

export default Vue.component('measurement-widget-form', {
    extends: ApiDrivenForm,
    props: {
        measurementUnitOptionsUrl: VueTypes.string.isRequired,
    },
    created() {
        this.formSchema = SCHEMA;
        this.formSchema.default_area_unit.optionsUrl = `${this.measurementUnitOptionsUrl}?unit_type=area`;
        this.formSchema.default_linear_unit.optionsUrl = `${this.measurementUnitOptionsUrl}?unit_type=linear`;
        this.formSchema.measurement_units.optionsUrl =
            this.measurementUnitOptionsUrl;
    },
    methods: {
        getSuccessMessage() {
            return `Measurement Widget '${this.formData.name}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
