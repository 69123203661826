<template>
    <div>
        <paginated-api-table
            v-if="adminModeEnabled"
            :api-url="apiUrl"
            :fields="adminTableFields"
            :initial-count="initialCount"
            :initial-data-element-id="initialDataElementId"
            :initial-num-pages="initialNumPages"
            :initial-page-size="initialPageSize"
            :refresh="refreshTable"
            @refreshed="refreshTable = false"
        >
            <template #name="cellProps">
                <a :href="cellProps.item.absolute_url">
                    {{ cellProps.item.name }}
                </a>
            </template>

            <template #is_published="cellProps">
                <component
                    :is="getPublishedIcon(cellProps.item)"
                    :variant="getPublishedVariant(cellProps.item)"
                    aria-hidden="true"
                />
                <span class="sr-only">
                    {{ getPublishedText(cellProps.item) }}
                </span>
            </template>

            <template #actionsColumn="cellProps">
                <b-button
                    :href="cellProps.item.update_url"
                    :aria-label="`Edit ${cellProps.item.name}`"
                    class="rounded-circle"
                    variant="primary"
                >
                    <b-icon-pencil />
                </b-button>

                <delete-button
                    :item-to-delete="cellProps.item"
                    :item-name="cellProps.item.name"
                    @item-deleted="refreshTable = true"
                ></delete-button>
            </template>
        </paginated-api-table>

        <paginated-card-list
            v-else
            :api-url="apiUrl"
            :initial-count="initialCount"
            :initial-data-element-id="initialDataElementId"
            :initial-num-pages="initialNumPages"
            :initial-page-size="initialPageSize"
        >
            <template #listHeader>
                <h6 class="card-subtitle mt-2">
                    Select an organisation to view current projects.
                </h6>
            </template>

            <template #listBody="props">
                <div class="row mt-5 d-flex">
                    <card-button
                        v-for="organisation in props"
                        :key="organisation.pk"
                        :title="organisation.name"
                        :url="organisation.absolute_url"
                        :logo="organisation.logo"
                    />
                </div>
            </template>

            <template #noData>
                There are currently no organisations available. Please refresh
                the page and try again, or contact an administrator.
            </template>
        </paginated-card-list>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import CardButton from '../CardButton.vue';
import PaginatedApiTable from '../PaginatedApiTable.vue';
import PaginatedCardList from '../PaginatedCardList.vue';
import IsAdminEnabledMixin from '../mixins/is-admin-enabled';
import {
    formatDatetimeToDefault,
    getPublishedIcon,
    getPublishedText,
    getPublishedVariant,
} from '../../utils';

export default Vue.component('organisation-list', {
    components: {
        CardButton,
        PaginatedApiTable,
        PaginatedCardList,
    },
    mixins: [IsAdminEnabledMixin],
    props: {
        apiUrl: VueTypes.string.isRequired,
        initialCount: VueTypes.integer.def(0),
        initialDataElementId: VueTypes.string.isRequired,
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
    },
    data() {
        return {
            adminTableFields: [
                {
                    key: 'name',
                    label: 'Organisation name',
                    tdClass: 'align-middle',
                },
                {
                    key: 'created',
                    formatter: formatDatetimeToDefault,
                    tdClass: 'align-middle',
                },
                {
                    key: 'is_published',
                    formatter: (value) => (value ? 'Yes' : 'No'),
                    tdClass: 'align-middle',
                },
                { key: 'actions', tdClass: 'align-middle' },
            ],
            refreshTable: false,
        };
    },
    methods: {
        getPublishedIcon,
        getPublishedText,
        getPublishedVariant,
    },
});
</script>
