var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-table", {
    ref: "table",
    attrs: {
      busy: _vm.isBusy,
      fields: _vm.fields,
      items: _vm.items,
      selectable: _vm.selectable,
      "select-mode": _vm.selectMode,
      responsive: "",
      "show-empty": "",
      "sticky-header":
        _vm.stickyHeader === true ? _vm.TABLE_DEFAULT_HEIGHT : _vm.stickyHeader,
      borderless: _vm.borderless,
      hover: _vm.hover,
      small: _vm.small,
    },
    on: {
      "update:busy": function ($event) {
        _vm.isBusy = $event
      },
      "row-selected": function (items) {
        return _vm.$emit("row-selected", items)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "empty",
          fn: function (scope) {
            return [
              _c(
                "b-alert",
                {
                  attrs: {
                    variant: _vm.alertVariant,
                    dismissible: "",
                    show: "",
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.errorMessage || scope.emptyText) +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
        },
        _vm._l(_vm.fields, function (ref) {
          var key = ref.key
          return {
            key: "cell(" + key + ")",
            fn: function (data) {
              return [
                _vm._t(
                  key,
                  function () {
                    return [
                      _vm._v(
                        "\n            " + _vm._s(data.value) + "\n        "
                      ),
                    ]
                  },
                  null,
                  data
                ),
              ]
            },
          }
        }),
        {
          key: "cell(actions)",
          fn: function (actions) {
            return [_vm._t("actionsColumn", null, null, actions)]
          },
        },
        {
          key: "cell(selected)",
          fn: function (ref) {
            var rowSelected = ref.rowSelected
            return [
              _vm._t("selectColumn", function () {
                return [
                  rowSelected
                    ? [
                        _c("b-icon", {
                          attrs: { icon: "check-square", variant: "success" },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Selected"),
                        ]),
                      ]
                    : [
                        _c("b-icon", { attrs: { icon: "square" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Not selected"),
                        ]),
                      ],
                ]
              }),
            ]
          },
        },
        {
          key: "table-busy",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center my-2" },
                [
                  _c("b-spinner", { staticClass: "align-middle" }),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Loading...")]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }