<template>
    <validation-provider
        #default="validationContext"
        :vid="name"
        :name="label"
        :rules="rules"
    >
        <b-form-group
            :label-for="id"
            :invalid-feedback="validationContext.errors[0]"
            :description="helpText"
            label-cols-md="4"
        >
            <template #label>
                {{ label }} <required-asterisk v-if="required" />
            </template>

            <div :id="mapElementId" class="map-container"></div>

            <b-form-input
                :id="id"
                v-model="valueProxy"
                :name="name"
                :required="required"
                :state="getValidationState(validationContext)"
            />
        </b-form-group>
    </validation-provider>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { ValidationProvider } from 'vee-validate';
import L from 'leaflet';
import 'leaflet-draw';
import wkt from 'terraformer-wkt-parser';

const SYDNEY_CBD_LAT_LONG = [-33.8708, 151.2073];
const INITIAL_ZOOM_LEVEL = 13;
const TILE_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

export default Vue.component('polygon-field', {
    components: { ValidationProvider },
    props: {
        customValidationRules: VueTypes.object,
        id: VueTypes.string.isRequired,
        label: VueTypes.string.isRequired,
        name: VueTypes.string.isRequired,
        helpText: VueTypes.string,
        required: Boolean,
        value: VueTypes.oneOfType([
            VueTypes.string,
            VueTypes.shape({
                type: VueTypes.string,
                coordinates: VueTypes.array,
            }).loose,
        ]),
    },
    data() {
        return {
            initialValue: '',
            map: null,
            drawnItems: null,
        };
    },
    computed: {
        mapElementId() {
            return `${this.id}-map`;
        },
        rules() {
            return {
                required: this.required,
                ...this.customValidationRules,
            };
        },
        valueProxy: {
            get() {
                if (typeof this.value === 'object' && this.value !== null) {
                    return wkt.convert(this.value);
                }
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
    created() {
        if (this.value) {
            // Store the initial value so it can be added to the map selector.
            this.initialValue = this.value;
        }
    },
    mounted() {
        this.setUpMap();
    },
    methods: {
        getValidationState({ dirty, errors, validated, valid = null }) {
            return dirty || validated ? valid && !errors.length : null;
        },
        setUpMap() {
            this.map = L.map(this.mapElementId).setView(
                SYDNEY_CBD_LAT_LONG,
                INITIAL_ZOOM_LEVEL,
            );

            L.tileLayer(TILE_URL, {
                attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> and contributors',
            }).addTo(this.map);

            this.drawnItems = new L.FeatureGroup();
            this.map.addLayer(this.drawnItems);

            // Add any existing drawn item to the layer for when the user is editing an object.
            if (this.initialValue) {
                const geoJSONLayer = L.geoJSON(this.initialValue);
                geoJSONLayer.eachLayer((layer) => {
                    this.drawnItems.addLayer(layer);
                });
            }

            // This control is for drawing the polygon, it is removed when the drawing
            // is completed so the user cannot add more than one shape.
            const drawControlPolygonOnly = new L.Control.Draw({
                draw: {
                    polygon: true,
                    polyline: false,
                    rectangle: false,
                    circle: false,
                    marker: false,
                    circlemarker: false,
                },
                edit: {
                    featureGroup: this.drawnItems,
                },
            });

            // This control is for editing the drawn polygons only.
            const drawControlEditOnly = new L.Control.Draw({
                draw: false,
                edit: {
                    featureGroup: this.drawnItems,
                },
            });

            // If any layers exist, then only allow editing.
            if (this.drawnItems.getLayers().length > 0) {
                this.map.addControl(drawControlEditOnly);
            } else {
                this.map.addControl(drawControlPolygonOnly);
            }

            // Remove the draw polygon control and replace it with the edit only control.
            // This stops users adding more than one polygon.
            this.map.on(L.Draw.Event.CREATED, ({ layer }) => {
                this.drawnItems.addLayer(layer);

                const polygon = layer.toGeoJSON();
                this.valueProxy = wkt.convert(polygon.geometry);

                this.map.removeControl(drawControlPolygonOnly);
                this.map.addControl(drawControlEditOnly);
            });

            this.map.on(L.Draw.Event.EDITED, ({ layers }) => {
                const polygon = layers.getLayers()[0].toGeoJSON();
                this.valueProxy = wkt.convert(polygon.geometry);
            });

            // Replace the edit control with the draw control once all polygons are deleted.
            this.map.on(L.Draw.Event.DELETED, () => {
                if (this.drawnItems.getLayers().length === 0) {
                    this.valueProxy = '';
                    this.map.removeControl(drawControlEditOnly);
                    this.map.addControl(drawControlPolygonOnly);
                }
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.map-container {
    height: 400px;
}
</style>
