/**
 * All validation rules available to Vue components using the VeeValidate
 * ValidationProvider component.
 *
 * New rules can be added after the registration of the VeeValidate rules.
 */
import * as rules from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { messages } from 'vee-validate/dist/locale/en.json';

export default () => {
    // Register the default VeeValidate rules and messages.
    Object.keys(rules).forEach((rule) => {
        extend(rule, {
            ...rules[rule],
            // Wrap the interpolated field name in quotes to help it stand out.
            message: messages[rule]
                ? messages[rule].replace('{_field_}', '"{_field_}"')
                : '',
        });
    });

    // Override the `alpha_dash` message.
    extend('alpha_dash', {
        ...rules.alpha_dash,
        message:
            'The "{_field_}" field may only contain alpha-numeric characters as well as dashes and underscores',
    });

    // Custom validation to replace the `mimes` rule for file fields that are used for creation and
    // editing.
    extend('editableFileMimeTypes', {
        params: ['mimeTypes'],
        validate(value, { mimeTypes }) {
            // Validate using the default validation rule.
            return rules.mimes.validate(value, mimeTypes);
        },
        message(field, args) {
            return `The "${field}" field must have a valid file type, supported types are: ${args.mimeTypes.join(
                ', ',
            )}`;
        },
    });

    extend('requiredFile', {
        computesRequired: true,
        validate: (value) => {
            // Validate using the default validation rule.
            return rules.required.validate(value);
        },
        message: messages.required.replace('{_field_}', '"{_field_}"'),
    });

    extend('validJson', {
        validate: (value) => {
            try {
                JSON.parse(value);
                return true;
            } catch (e) {
                return false;
            }
        },
        message: 'Please enter valid JSON.',
    });

    extend('phoneNumber', {
        validate: (value) => /^([0-9]|\+|-|\s)*$/.test(value),
        message:
            'The "{_field_}" field may only contain numbers, +, -, and spaces',
    });

    extend('validUrl', {
        validate: (value) => {
            try {
                new URL(value);
                return true;
            } catch (e) {
                return false;
            }
        },
        message: 'Please enter a valid URL.',
    });
};
