<template>
    <span v-if="adminModeEnabled">
        <b-button
            v-if="textual"
            variant="danger"
            data-toggle="modal"
            :data-target="`#${modalId}`"
            data-backdrop="false"
        >
            {{ buttonText }}
        </b-button>

        <b-button
            v-else
            :aria-label="`Delete ${itemName}`"
            class="rounded-circle"
            variant="danger"
            data-toggle="modal"
            :data-target="`#${modalId}`"
            data-backdrop="false"
        >
            <b-icon-trash />
        </b-button>

        <dialog-box
            button-label="Delete"
            dismiss-button-label="Cancel"
            modal-title="Warning"
            button-variant="danger"
            :modal-id="modalId"
            @action-accepted="deleteItem(itemToDelete)"
        >
            <template #body>
                <p>Are you sure you want to delete "{{ itemName }}"?</p>
                <p>This action cannot be reversed.</p>
            </template>
        </dialog-box>
    </span>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { createNamespacedHelpers } from 'vuex';

import { csrfHeader } from '../cookies';
import {
    MODULE_MESSAGES,
    MUTATION_ADD_MESSAGE,
} from '../store/modules/messages';

import DialogBox from './DialogBox.vue';
import IsAdminEnabledMixin from './mixins/is-admin-enabled';

const { mapMutations } = createNamespacedHelpers(MODULE_MESSAGES);

export default Vue.component('delete-button', {
    components: { DialogBox },
    mixins: [IsAdminEnabledMixin],
    props: {
        itemToDelete: VueTypes.object.isRequired,
        itemName: VueTypes.oneOfType([VueTypes.string, VueTypes.number])
            .isRequired,
        textual: Boolean,
        buttonText: VueTypes.string.def(''),
        redirectUrl: VueTypes.string.def(''),
        adminMode: VueTypes.bool.def(false),
    },
    computed: {
        modalId() {
            return `delete-modal-${this.itemToDelete.pk}`;
        },
    },
    methods: {
        ...mapMutations([MUTATION_ADD_MESSAGE]),
        deleteItem: function (item) {
            axios
                .delete(item.absolute_api_url, {
                    headers: { ...csrfHeader },
                })
                .then(() => {
                    this.$emit('item-deleted');
                    this[MUTATION_ADD_MESSAGE]({
                        text: `'${this.itemToDelete.name}' was deleted successfully.`,
                        variant: 'success',
                        key: this.itemToDelete.pk,
                    });
                    if (this.redirectUrl) {
                        window.location = this.redirectUrl;
                    }
                })
                .catch((error) => {
                    const errorMessage = _get(
                        error,
                        'response.data',
                        `There was an error deleting '${this.itemToDelete.name}'.`,
                    );
                    this[MUTATION_ADD_MESSAGE]({
                        text: errorMessage,
                        variant: 'danger',
                        key: this.itemToDelete.pk,
                    });
                });
        },
    },
});
</script>

<style scoped>
#confirmation-text {
    white-space: pre-line;
}
</style>
