<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import ApiDrivenForm from '../../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            name: {
                component: 'input-field',
                id: 'print-service-name-input',
                label: 'Name',
                required: true,
                customValidationRules: { max: 255 },
            },
            print_server: {
                component: 'select-field',
                id: 'print-service-print-server-input',
                label: 'Print server',
                required: true,
                optionsUrl: '',
            },
            url: {
                component: 'input-field',
                id: 'print-service-url-input',
                label: 'URL',
                required: true,
                customValidationRules: { max: 512 },
            },
            print_templates: {
                component: 'select-field',
                id: 'print-service-print-templates-input',
                label: 'Print templates',
                multiple: true,
                required: true,
                optionsUrl: '',
            },
            copyright: {
                component: 'input-field',
                id: 'print-service-copyright-input',
                label: 'Copyright',
                customValidationRules: { max: 128 },
            },
        },
    },
];

export default Vue.component('print-service-form', {
    extends: ApiDrivenForm,
    props: {
        printServerOptionsUrl: VueTypes.string.isRequired,
        printTemplateOptionUrl: VueTypes.string.isRequired,
    },
    created() {
        this.formSchema = SCHEMA;
        this.formSchema.print_server.optionsUrl = this.printServerOptionsUrl;
        this.formSchema.print_templates.optionsUrl =
            this.printTemplateOptionUrl;
    },
    methods: {
        getSuccessMessage() {
            return `Print service '${this.formData.name}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
