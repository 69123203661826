<script>
import Vue from 'vue/dist/vue.esm.js';

import { getDeserializedData } from '../../utils';
import DualTableSearchSelectForm from '../forms/DualTableSearchSelectForm.vue';

export default Vue.component('project-search-form', {
    extends: DualTableSearchSelectForm,
    data() {
        return {
            searchLabel: 'Search projects by name.',
            sourceTableLabel: 'Click a project name to add.',
            targetTableLabel: 'Click a project name to remove.',
            tableFields: [
                { key: 'name', label: 'Projects', tdClass: 'align-left' },
            ],
            selectedTableFields: [
                {
                    key: 'name',
                    label: 'Add to these Projects',
                    tdClass: 'align-left',
                },
            ],
            allowEmptySearch: true,
        };
    },
    methods: {
        getExistingSelections() {
            if (this.value) {
                return structuredClone(this.value);
            }
            if (this.initialFormDataElementId) {
                return getDeserializedData(this.initialFormDataElementId)
                    .projects;
            }
            return [];
        },
    },
});
</script>
