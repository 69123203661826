<script>
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import { DEFAULT_ERROR_MESSAGE } from '../constants';

import ApiTable from './ApiTable.vue';

export default Vue.component('continuous-scroll-api-search-table', {
    extends: ApiTable,
    props: {
        params: VueTypes.object,
    },
    data() {
        return {
            items: [],
            isLoadingMore: false,
        };
    },
    watch: {
        params() {
            this.getData();
            this.$refs.table.refresh();
        },
        isLoadingMore(newValue) {
            this.$emit('is-loading-more', newValue);
        },
    },
    methods: {
        getData() {
            if (this.initialLoad) {
                // Load the initial data without making an API call.
                this.initialLoad = false;
                this.items = [];
            }

            if (this.params.page) {
                this.isLoadingMore = true;
            } else {
                this.isBusy = true;
            }

            return axios
                .get(this.apiUrl, { params: this.params })
                .then((response) => {
                    this.initialLoad = false;

                    if (this.params.page) {
                        this.items = [
                            ...this.items.slice(0, -1),
                            ...response.data.results,
                        ];
                    } else {
                        this.items = response.data.results;
                    }

                    if (
                        response.data.current_page !== response.data.num_pages
                    ) {
                        this.items.push({
                            type: 'loadMore',
                            nextPage: response.data.current_page + 1,
                        });
                    }
                })
                .catch(() => {
                    this.errorMessage = DEFAULT_ERROR_MESSAGE;
                    this.items = [];
                })
                .finally(() => {
                    this.isBusy = false;
                    this.isLoadingMore = false;
                });
        },
    },
});
</script>
