<script>
import Vue from 'vue/dist/vue.esm.js';
import { createNamespacedHelpers } from 'vuex';

import {
    MODULE_ADMIN,
    MUTATION_SET_IS_ADMIN_ENABLED,
} from '../store/modules/admin';

import BreakpointsMixin from './mixins/breakpoints';

const { mapMutations } = createNamespacedHelpers(MODULE_ADMIN);

let SiteWrapper = Vue.component('site-wrapper', {
    mixins: [BreakpointsMixin],
    props: {
        isAdmin: Boolean,
    },
    data() {
        return {
            showMobileSidebar: false,
        };
    },
    mounted: function () {
        this.init();
    },
    methods: {
        init() {
            let adminModeEnabled = false;
            if (this.isAdmin) {
                const userPreference = JSON.parse(
                    localStorage.getItem('adminModeEnabled'),
                );

                adminModeEnabled = userPreference === null || userPreference;
            }
            this[MUTATION_SET_IS_ADMIN_ENABLED](adminModeEnabled);
        },
        toggleMobileSidebar() {
            this.showMobileSidebar = !this.showMobileSidebar;
        },
        ...mapMutations([MUTATION_SET_IS_ADMIN_ENABLED]),
    },
});

export default SiteWrapper;
</script>
