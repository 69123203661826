<template>
    <paginated-api-table
        :refresh="refreshTable"
        :api-url="apiUrl"
        :fields="adminTableFields"
        :initial-count="initialCount"
        :initial-num-pages="initialNumPages"
        :initial-page-size="initialPageSize"
        @refreshed="refreshTable = false"
    >
        <template #actionsColumn="cellProps">
            <b-button
                :disabled="
                    cellProps.item.num_converted !=
                    cellProps.item.number_of_bridges
                "
                variant="primary"
                @click="download(cellProps.item.pk)"
            >
                <b-icon-download /> Download
            </b-button>
        </template>
    </paginated-api-table>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import { csrfHeader } from '../../cookies';
import PaginatedApiTable from '../PaginatedApiTable.vue';

export default Vue.component('bridge-inspection-list', {
    components: {
        PaginatedApiTable,
    },
    props: {
        apiUrl: VueTypes.string.isRequired,
        initialCount: VueTypes.integer.def(0),
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
    },
    data() {
        return {
            adminTableFields: [
                {
                    key: 'uploaded_file_name',
                    label: 'Uploaded File',
                    tdClass: 'align-middle',
                },
                {
                    key: 'progress',
                    label: 'Progress',
                    tdClass: 'align-middle',
                },
                { key: 'actions', tdClass: 'align-middle' },
            ],
            refreshTable: false,
            timer: '',
            isDisabled: true,
        };
    },
    created() {
        this.timer = setInterval(this.makeRefreshTable, 18000);
    },
    destroyed() {
        clearInterval(this.timer);
    },
    methods: {
        makeRefreshTable() {
            this.refreshTable = true;
        },
        getApiUrl(pk) {
            return `/api/tools/bridge_conversion/${pk}/download`;
        },
        download(pk) {
            axios({
                method: 'get',
                url: this.getApiUrl(pk),
                responseType: 'blob',
                headers: { ...csrfHeader },
            })
                .then((response) => {
                    let blob = new Blob([response.data], {
                        type: 'application/force-download',
                    });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'bridge_inspection_documents.zip';
                    link.click();
                    this.handleFormSuccess(response);
                })
                .catch(({ response }) => {
                    this.uploadErrorMessage = _get(
                        response,
                        'data.detail',
                        'There was an error downloading the file.',
                    );
                });
        },
    },
});
</script>
