var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { vid: _vm.name, name: _vm.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (validationContext) {
          return [
            _c(
              "b-form-group",
              {
                attrs: {
                  "label-for": _vm.id,
                  "invalid-feedback": validationContext.errors[0],
                  description: _vm.helpText,
                  "label-cols-md": "4",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v("\n            " + _vm._s(_vm.label) + " "),
                          _vm.required ? _c("required-asterisk") : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _c("b-form-input", {
                  attrs: { placeholder: "Search" },
                  on: {
                    input: function (value) {
                      return (_vm.searchInput = value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected,
                      expression: "selected",
                    },
                  ],
                  attrs: { hidden: "" },
                  domProps: { value: _vm.selected },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.selected = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "drag-drop-fields" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "pr-1" },
                      [
                        _c(
                          "draggable",
                          {
                            staticClass: "list-group",
                            attrs: {
                              list: _vm.filteredOptions,
                              group: "selections",
                            },
                          },
                          _vm._l(_vm.filteredOptions, function (item) {
                            return _c(
                              "b-list-group-item",
                              { key: item.value },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.label) +
                                    "\n                    "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "pl-1" },
                      [
                        _c(
                          "draggable",
                          {
                            staticClass: "list-group",
                            attrs: {
                              list: _vm.selectedItems,
                              group: "selections",
                            },
                          },
                          [
                            _vm.hasNoSelectedItems
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex drop-target" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "align-self-center" },
                                      [
                                        _vm._v(
                                          "\n                            Drag and drop items here to select.\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._l(_vm.selectedItems, function (item) {
                                  return _c(
                                    "b-list-group-item",
                                    { key: item.value },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(item.label) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }