var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.htmlTag,
    _vm._b({ tag: "component" }, "component", _vm.$attrs, false),
    [
      _c("span", { attrs: { id: "json-display-content" } }, [
        _vm._v(_vm._s(_vm.formattedJson)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }