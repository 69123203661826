var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm._isEmpty(_vm.appliedFilters)
        ? _c(
            "div",
            { staticClass: "filter-bubble-container" },
            _vm._l(_vm.appliedFilters, function (value, key) {
              return _c("span", { key: key, staticClass: "filter-bubble" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.fields.find(function (obj) {
                        return obj.key === key
                      }).label
                    ) +
                    ": " +
                    _vm._s(value) +
                    "\n            "
                ),
                _c(
                  "a",
                  {
                    class: { disabled: _vm.isBusy },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.removeFilter(key)
                      },
                    },
                  },
                  [
                    _vm.isBusy
                      ? _c("b-spinner", {
                          staticClass: "align-middle",
                          attrs: { small: "" },
                        })
                      : _c("b-icon-x"),
                  ],
                  1
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-table", {
        ref: "table",
        attrs: {
          busy: _vm.isBusy,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          "current-page": _vm.currentPage,
          fields: _vm.fields,
          items: _vm.getData,
          "per-page": _vm.perPage,
          selectable: _vm.selectable,
          "select-mode": _vm.selectMode,
          "sticky-header":
            _vm.stickyHeader === true
              ? _vm.TABLE_DEFAULT_HEIGHT
              : _vm.stickyHeader,
          responsive: "",
          "show-empty": "",
          "sort-icon-left": "",
          "no-local-sorting": "",
        },
        on: {
          "update:busy": function ($event) {
            _vm.isBusy = $event
          },
          "update:sortBy": function ($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function ($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function ($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function ($event) {
            _vm.sortDesc = $event
          },
          "row-selected": function (items) {
            return _vm.$emit("row-selected", items)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "empty",
              fn: function (scope) {
                return [
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        variant: _vm.alertVariant,
                        dismissible: "",
                        show: "",
                      },
                    },
                    [
                      _vm.errorMessage
                        ? [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.errorMessage) +
                                "\n                "
                            ),
                          ]
                        : [
                            _vm._v(
                              "\n                    " +
                                _vm._s(scope.emptyText) +
                                "\n                "
                            ),
                          ],
                    ],
                    2
                  ),
                ]
              },
            },
            _vm._l(_vm.fields, function (ref) {
              var key = ref.key
              return {
                key: "head(" + key + ")",
                fn: function (data) {
                  return [
                    _vm._t(
                      key + "-header",
                      function () {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(data.label) +
                              "\n\n                "
                          ),
                          data.field.filter
                            ? _c(
                                data.field.filter.type || "header-search-field",
                                {
                                  tag: "component",
                                  staticClass: "filter-container",
                                  attrs: {
                                    "field-name": key,
                                    data: data,
                                    value: _vm.filterData[key],
                                  },
                                  on: {
                                    input: function (value) {
                                      return (_vm.filterData[key] = value)
                                    },
                                    "apply-search-filter": function () {
                                      return _vm.applySearchFilter(key)
                                    },
                                    "apply-select-filter": function () {
                                      return _vm.applySelectFilter(key)
                                    },
                                  },
                                }
                              )
                            : _vm._e(),
                        ]
                      },
                      null,
                      data
                    ),
                  ]
                },
              }
            }),
            _vm._l(_vm.fields, function (ref) {
              var key = ref.key
              return {
                key: "cell(" + key + ")",
                fn: function (data) {
                  return [
                    _vm._t(
                      key,
                      function () {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(data.value) +
                              "\n            "
                          ),
                        ]
                      },
                      null,
                      data
                    ),
                  ]
                },
              }
            }),
            {
              key: "cell(actions)",
              fn: function (actions) {
                return [_vm._t("actionsColumn", null, null, actions)]
              },
            },
            {
              key: "cell(selected)",
              fn: function (ref) {
                var rowSelected = ref.rowSelected
                return [
                  _vm._t("selectColumn", function () {
                    return [
                      rowSelected
                        ? [
                            _c("b-icon", {
                              attrs: {
                                icon: "check-square",
                                variant: "success",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Selected"),
                            ]),
                          ]
                        : [
                            _c("b-icon", { attrs: { icon: "square" } }),
                            _vm._v(" "),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Not selected"),
                            ]),
                          ],
                    ]
                  }),
                ]
              },
            },
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center my-2" },
                    [
                      _c("b-spinner", { staticClass: "align-middle" }),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Loading...")]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _vm.numPages > 1
        ? _c("b-pagination", {
            staticClass: "mt-4",
            attrs: { "total-rows": _vm.count, align: "center" },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }