<template>
    <div>
        <import-project-users-file-form :api-url="apiUrl" />
        <div>
            <div class="sample-table clearfix">
                <span class="float-left col-sm-10 pl-0">
                    Header (first row) should contain the following field names
                    exactly as they appear below
                </span>
                <b-button
                    class="float-right col-sm-2"
                    :disabled="loadingSampleData"
                    @click="downloadCsvTemplate"
                >
                    Download CSV template
                </b-button>
            </div>
            <h5>Required Fields</h5>
            <b-table :items="sampleData" :busy="loadingSampleData" bordered>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle" />
                        <strong>Loading...</strong>
                    </div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import axios from 'axios';

import ImportProjectUsersFileForm from './ImportProjectUsersFileForm.vue';

export default Vue.component('add-users-to-projects-form', {
    components: {
        ImportProjectUsersFileForm,
    },
    props: {
        apiUrl: VueTypes.string.isRequired,
        sampleDataUrl: VueTypes.string.isRequired,
    },
    data() {
        return {
            loadingSampleData: true,
            sampleData: [],
        };
    },
    computed: {
        csvContent() {
            const headings = Object.keys(this.sampleData[0]).join(',');
            const values = Object.values(this.sampleData[0]).join(',');
            return `${headings}\n${values}`;
        },
    },
    created() {
        axios.get(this.sampleDataUrl).then(({ data }) => {
            this.sampleData = data;
            this.loadingSampleData = false;
        });
    },
    methods: {
        downloadCsvTemplate() {
            let blob = new Blob([this.csvContent], {
                type: 'text/csv',
            });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'sample.csv';
            link.click();
        },
    },
});
</script>

<style lang="scss" scoped>
.sample-table {
    margin-top: 75px;
    margin-bottom: 10px;
}
</style>
