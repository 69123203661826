<template>
    <div
        :id="modalId"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal"
        aria-hidden="true"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="modalLabel" class="modal-title">
                        {{ modalTitle }}
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        Are you sure you want to continue?
                    </slot>
                </div>
                <div class="modal-footer">
                    <b-button variant="secondary" data-dismiss="modal">
                        {{ dismissButtonLabel }}
                    </b-button>
                    <b-button
                        :variant="buttonVariant"
                        data-dismiss="modal"
                        @click="actionAccepted"
                    >
                        {{ buttonLabel }}
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

export default Vue.component('dialog-box', {
    props: {
        buttonLabel: VueTypes.string.isRequired,
        dismissButtonLabel: VueTypes.string.isRequired,
        modalTitle: VueTypes.string.isRequired,
        buttonVariant: VueTypes.string.isRequired,
        modalId: VueTypes.string.isRequired,
    },
    methods: {
        actionAccepted: function () {
            this.$emit('action-accepted');
        },
    },
});
</script>
<style scoped>
.modal {
    background: rgb(0 0 0 / 40%);
}
</style>
