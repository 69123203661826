<template>
    <b-form :id="`${fieldName}-filter`" class="filter-container">
        <div v-if="isBusy" class="text-center my-2">
            <b-spinner class="align-middle" />
        </div>
        <v-select
            v-else
            :clearable="false"
            :options="selectOptions"
            :placeholder="`Select ${data.label}...`"
            :value="value"
            style="width: 100%"
            @input="select"
        />
    </b-form>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import axios from 'axios';

export default Vue.component('header-select-field', {
    props: {
        fieldName: VueTypes.string.isRequired,
        data: VueTypes.object.isRequired,
        value: VueTypes.any,
    },
    data() {
        return {
            isBusy: false,
            selectOptions: [],
        };
    },
    created() {
        if (this.data.field.filter.optionsUrl) {
            this.isBusy = true;
            axios.get(this.data.field.filter.optionsUrl).then(({ data }) => {
                this.selectOptions = data.results;
                this.isBusy = false;
            });
        } else if (this.data.field.filter.options) {
            this.selectOptions = this.data.field.filter.options;
        } else {
            throw new Error(
                `Must provide either \`options\` or \`optionsUrl\` for \`${this.fieldName}\` filter.`,
            );
        }
    },
    methods: {
        select(value) {
            this.$emit('input', value);
            this.$emit('apply-select-filter');
        },
    },
});
</script>
