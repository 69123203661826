<template>
    <paginated-api-table
        :refresh="refreshTable"
        :api-url="apiUrl"
        :fields="adminTableFields"
        :initial-count="initialCount"
        :initial-num-pages="initialNumPages"
        :initial-page-size="initialPageSize"
        @refreshed="refreshTable = false"
    />
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import { formatDatetimeToDefault, getCapitalisedText } from '../../utils';
import PaginatedApiTable from '../PaginatedApiTable.vue';

export default Vue.component('import-project-users-task-list', {
    components: {
        PaginatedApiTable,
    },
    props: {
        apiUrl: VueTypes.string.isRequired,
        initialCount: VueTypes.integer.def(0),
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
    },
    data() {
        return {
            adminTableFields: [
                {
                    key: 'csv_file_name',
                    label: 'CSV File',
                    tdClass: 'align-middle',
                },
                {
                    key: 'status',
                    label: 'Status',
                    formatter: getCapitalisedText,
                    tdClass: 'align-middle',
                },
                {
                    key: 'errors',
                    label: 'Errors',
                    formatter: (value) => value || '-',
                    tdClass: 'align-middle',
                },
                {
                    key: 'created',
                    label: 'Uploaded',
                    formatter: formatDatetimeToDefault,
                    tdClass: 'align-middle',
                },
                {
                    key: 'modified',
                    label: 'Last Updated',
                    formatter: formatDatetimeToDefault,
                    tdClass: 'align-middle',
                },
            ],
            refreshTable: false,
            timer: '',
        };
    },
    created() {
        this.timer = setInterval(this.triggerRefreshTable, 18000);
    },
    destroyed() {
        clearInterval(this.timer);
    },
    methods: {
        triggerRefreshTable() {
            this.refreshTable = true;
        },
    },
});
</script>
