var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("paginated-api-table", {
    attrs: {
      "api-url": _vm.apiUrl,
      fields: _vm.tableFields,
      "initial-count": _vm.initialCount,
      "initial-data-element-id": _vm.initialDataElementId,
      "initial-num-pages": _vm.initialNumPages,
      "initial-page-size": _vm.initialPageSize,
    },
    scopedSlots: _vm._u([
      {
        key: "actionsColumn",
        fn: function (cellProps) {
          return [
            _c(
              "b-button",
              {
                staticClass: "rounded-circle",
                attrs: {
                  href: cellProps.item.update_url,
                  "aria-label": "Edit " + cellProps.item.name,
                  variant: "primary",
                },
              },
              [_c("b-icon-pencil")],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }