var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      staticClass: "filter-container",
      attrs: { id: _vm.fieldName + "-filter" },
    },
    [
      _vm.isBusy
        ? _c(
            "div",
            { staticClass: "text-center my-2" },
            [_c("b-spinner", { staticClass: "align-middle" })],
            1
          )
        : _c("v-select", {
            staticStyle: { width: "100%" },
            attrs: {
              clearable: false,
              options: _vm.selectOptions,
              placeholder: "Select " + _vm.data.label + "...",
              value: _vm.value,
            },
            on: { input: _vm.select },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }