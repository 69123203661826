var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-for": _vm.id,
        description: _vm.helpText,
        "label-cols-md": "4",
      },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function () {
            return [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "modal-value-preview" }, [
        _c("i", [_vm._v(_vm._s(_vm.modalValuePreview))]),
      ]),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: _vm.modalId,
              expression: "modalId",
            },
          ],
          attrs: { id: _vm.id, disabled: _vm.disabled },
        },
        [_vm._v("\n        " + _vm._s(_vm.modalLabel) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { id: _vm.modalId, title: _vm.modalLabel, size: "lg" },
          on: { ok: _vm.handleOk },
        },
        [
          _c(_vm.modalComponent, {
            tag: "component",
            attrs: {
              "api-url": _vm.modalApiUrl,
              name: _vm.name,
              value: _vm.modalValue,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }