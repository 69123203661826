<template>
    <b-table
        ref="table"
        :busy.sync="isBusy"
        :fields="fields"
        :items="items"
        :selectable="selectable"
        :select-mode="selectMode"
        responsive
        show-empty
        :sticky-header="
            stickyHeader === true ? TABLE_DEFAULT_HEIGHT : stickyHeader
        "
        :borderless="borderless"
        :hover="hover"
        :small="small"
        @row-selected="(items) => $emit('row-selected', items)"
    >
        <template #empty="scope">
            <b-alert :variant="alertVariant" dismissible show>
                {{ errorMessage || scope.emptyText }}
            </b-alert>
        </template>

        <!--
                Enable components to override the column cell rendering using the
                `cell(fieldKey)` slot provided by `b-table`.
            -->
        <template v-for="{ key } in fields" #[`cell(${key})`]="data">
            <slot v-bind="data" :name="key">
                {{ data.value }}
            </slot>
        </template>

        <template #cell(actions)="actions">
            <slot v-bind="actions" name="actionsColumn" />
        </template>

        <template #cell(selected)="{ rowSelected }">
            <slot name="selectColumn">
                <template v-if="rowSelected">
                    <b-icon icon="check-square" variant="success" />
                    <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                    <b-icon icon="square" />
                    <span class="sr-only">Not selected</span>
                </template>
            </slot>
        </template>

        <template #table-busy>
            <div class="text-center my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
            </div>
        </template>
    </b-table>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import { DEFAULT_ERROR_MESSAGE, TABLE_DEFAULT_HEIGHT } from '../constants';
import { getDeserializedData } from '../utils';

export default Vue.component('api-table', {
    props: {
        refresh: Boolean,
        apiUrl: VueTypes.string.isRequired,
        fields: VueTypes.arrayOf(VueTypes.object),
        initialCount: VueTypes.integer.def(0),
        initialDataElementId: VueTypes.string,
        selectable: Boolean,
        selectMode: VueTypes.string.def(undefined),
        stickyHeader: VueTypes.oneOfType([Boolean, VueTypes.string]).def(false),
        borderless: Boolean,
        hover: Boolean,
        small: Boolean,
    },
    data() {
        return {
            TABLE_DEFAULT_HEIGHT,
            count: this.initialCount,
            errorMessage: '',
            isBusy: false,
            initialLoad: true,
            items: [],
        };
    },
    computed: {
        alertVariant() {
            return this.errorMessage ? 'danger' : 'info';
        },
    },
    watch: {
        refresh: function (newVal) {
            if (newVal) {
                this.$refs.table.refresh();
                this.$emit('refreshed');
            }
        },
        isBusy(newValue) {
            this.$emit('is-busy', newValue);
        },
    },
    methods: {
        getData() {
            if (this.initialLoad && this.initialDataElementId) {
                // Load the initial data without making an API call.
                this.initialLoad = false;
                this.items = getDeserializedData(this.initialDataElementId);
            }

            this.isBusy = true;

            return axios
                .get(this.apiUrl)
                .then((response) => {
                    this.initialLoad = false;
                    this.items = response.data;
                })
                .catch((error) => {
                    this.errorMessage = _get(
                        error,
                        'response.data.detail',
                        DEFAULT_ERROR_MESSAGE,
                    );
                    this.items = [];
                })
                .finally(() => (this.isBusy = false));
        },
    },
});
</script>
