<script>
import Vue from 'vue/dist/vue.esm.js';

import ApiDrivenForm from '../forms/ApiDrivenForm.vue';

const SCHEMA = [
    {
        fields: {
            csv_file: {
                component: 'file-field',
                id: 'import-project-users-csv-file',
                label: 'Add Users to Projects from a file',
                mimeTypes: ['text/csv'],
                uploadedFileType: 'tools',
                required: true,
                blockLabel: true,
            },
        },
    },
];

export default Vue.component('import-project-users-file-form', {
    extends: ApiDrivenForm,
    created() {
        this.formSchema = SCHEMA;
    },
    methods: {
        getSuccessMessage() {
            return 'Starting import process.';
        },
    },
});
</script>
