<template>
    <validation-provider
        #default="validationContext"
        :vid="name"
        :name="label"
        :rules="rules"
    >
        <b-form-group
            :label-for="id"
            :invalid-feedback="validationContext.errors[0]"
            :description="helpText"
            label-cols-md="4"
        >
            <template #label>
                {{ label }} <required-asterisk v-if="required" />
            </template>

            <component
                :is="inputType"
                :id="id"
                ref="checkboxInput"
                v-model="selected"
                :name="name"
                :required="required"
                :disabled="disabled"
                :options="selectOptions"
                :state="getValidationState(validationContext)"
                :stacked="multiple"
                :class="{ 'checkbox-group': multiple }"
                text-field="label"
            >
                <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                >
                    {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
            </component>
        </b-form-group>
    </validation-provider>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { ValidationProvider } from 'vee-validate';
import axios from 'axios';

import RequiredAsterisk from './RequiredAsterisk.vue';

export default Vue.component('checkbox-field', {
    components: { RequiredAsterisk, ValidationProvider },
    props: {
        customValidationRules: VueTypes.object,
        id: VueTypes.string.isRequired,
        label: VueTypes.string.isRequired,
        name: VueTypes.string.isRequired,
        helpText: VueTypes.string,
        required: Boolean,
        multiple: Boolean,
        disabled: Boolean,
        options: VueTypes.array,
        optionsUrl: VueTypes.string,
        value: VueTypes.oneOfType([VueTypes.bool, VueTypes.array]),
    },
    data() {
        return {
            selectOptions: [],
        };
    },
    computed: {
        inputType() {
            return this.multiple ? 'b-form-checkbox-group' : 'b-form-checkbox';
        },
        rules() {
            return {
                required: this.required,
                ...this.customValidationRules,
            };
        },
        selected: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
    created() {
        if (!this.multiple) {
            // Don't continue checking the configuration for single checkbox fields, they don't
            // need an optionsUrl or list of options.
            return;
        }

        if (this.optionsUrl) {
            axios.get(this.optionsUrl).then(({ data }) => {
                this.selectOptions = data.results;
            });
        } else if (this.options) {
            this.selectOptions = this.options;
        } else {
            throw new Error(
                'Must provide either `options` or `optionsUrl` prop.',
            );
        }
    },
    methods: {
        getValidationState({ dirty, errors, validated, valid = null }) {
            return dirty || validated ? valid && !errors.length : null;
        },
    },
});
</script>

<style lang="scss" scoped>
.checkbox-group {
    max-height: 400px;
    overflow-y: auto;
}
</style>
