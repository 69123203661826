<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { DateTime } from 'luxon';

import ApiDrivenForm from '../forms/ApiDrivenForm.vue';

import ProjectFormIsExistingGroup from './ProjectFormIsExistingGroup.vue';
import ProjectFormSelectExistingGroup from './ProjectFormSelectExistingGroup.vue';

const SCHEMA = [
    {
        fields: {
            organisation: {
                id: 'project-organisation-input',
                hidden: true,
            },
            arcgis_group_id: {
                id: 'arcgis-group-id-input',
                hidden: true,
            },
            name: {
                component: 'input-field',
                id: 'project-name-input',
                label: 'Name',
                required: true,
                placeholder: 'Project Name',
                helpText:
                    'Name to be displayed to the user on the organisation projects page.',
            },
            is_existing_group: {
                component: 'project-form-is-existing-group',
                id: 'project-form-is-existing-group-input',
            },
            select_existing_group: {
                component: 'project-form-select-existing-group',
                id: 'project-form-select-existing-group-input',
                fieldApiUrl: '',
                arcgisGroupId: '',
            },
            arcgis_group_name: {
                component: 'input-field',
                id: 'project-arcgis-group-name-input',
                label: 'Portal Group Name',
                required: true,
                placeholder: 'Project Portal Group Name',
                helpText:
                    'Please use the same name as the projects UBW generated U Drive folder name e.g. PS12345_UDrive_Project_Name.',
                hidden: false,
                readonly: false,
            },
            slug: {
                component: 'input-field',
                id: 'project-slug-input',
                label: 'Slug',
                required: true,
                placeholder: 'Project Slug',
                helpText:
                    'Please enter a suitable name for the Project URL using only letters, numbers, underscores or hyphens.',
                customValidationRules: { alpha_dash: true },
            },
            project_code: {
                component: 'input-field',
                id: 'project-code-input',
                label: 'Project Code',
                required: true,
                placeholder: 'PS####',
                helpText: 'Please enter the Project code',
            },
            subheading: {
                component: 'input-field',
                id: 'project-subheading-input',
                label: 'Subheading',
                placeholder: 'Project Subheading',
                helpText: 'Please enter a brief summary of the Project.',
                customValidationRules: { max: 100 },
            },
            description: {
                component: 'input-field',
                id: 'project-description-input',
                label: 'Description',
                placeholder: 'Project Description',
                helpText: 'Please enter a description of the Project.',
                customValidationRules: { max: 5000 },
                type: 'textarea',
            },
            background: {
                component: 'file-field',
                id: 'project-background-input',
                label: 'Background',
                helpText:
                    'Please upload an image to use as the background for the Project pages.',
                mimeTypes: ['image/jpeg', 'image/png'],
                uploadedFileType: 'projects',
            },
            logo: {
                component: 'file-field',
                id: 'project-logo-input',
                label: 'Logo',
                helpText:
                    'Please upload an image to use as the logo within the Project pages.',
                mimeTypes: ['image/jpeg', 'image/png'],
                uploadedFileType: 'projects',
            },
        },
    },
    {
        groupName: 'Payment details',
        fields: {
            project_cost: {
                component: 'input-field',
                id: 'project-cost-input',
                label: 'Project Cost',
                type: 'number',
                placeholder: '$ -',
            },
            agreed_service_fee: {
                component: 'input-field',
                id: 'agreed-service-fee-input',
                label: 'Agreed service fee',
                type: 'number',
                placeholder: '$ -',
            },
            approved_by: {
                component: 'input-field',
                id: 'approved-by-input',
                label: 'Approved by',
                placeholder: 'Name',
            },
            approved_date: {
                component: 'input-field',
                id: 'approved-date-input',
                label: 'Approved date',
                type: 'date',
            },
            approval_record: {
                component: 'file-field',
                id: 'approval-record-input',
                label: 'Approval record',
                mimeTypes: [],
                uploadedFileType: 'projects',
            },
            payment_status: {
                component: 'select-field',
                id: 'payment-status-input',
                label: 'Payment status',
                options: [
                    { label: 'Paid', value: 'paid' },
                    { label: 'Unpaid', value: 'unpaid' },
                ],
            },
        },
    },
    {
        fields: {
            notes: {
                component: 'input-field',
                id: 'notes-input',
                label: 'Notes',
                type: 'textarea',
                blockLabel: true,
            },
            is_published: {
                component: 'checkbox-field',
                id: 'project-is-published-input',
                label: 'Would you like to publish this Project?',
                helpText:
                    'Authorised users will only see Projects that are set to Published. Note, publishing a Project will also publish the Organisation.',
            },
        },
    },
];

export default Vue.component('project-form', {
    components: {
        ProjectFormIsExistingGroup,
        ProjectFormSelectExistingGroup,
    },
    extends: ApiDrivenForm,
    props: {
        arcgisSearchApiUrl: VueTypes.string.isRequired,
        arcgisGroupId: VueTypes.string,
        organisationPk: VueTypes.integer,
        organisationOptionsUrl: VueTypes.string,
        isCreating: Boolean,
    },
    data() {
        return {
            isGroupRequired: true,
            isExistingGroup: false,
        };
    },
    created() {
        this.formSchema = SCHEMA;
        this.formSchema[0].fields.select_existing_group.fieldApiUrl =
            this.arcgisSearchApiUrl;

        if (!this.isCreating) {
            delete this.formSchema[0].fields.is_existing_group;
            this.isExistingGroup = true;
            this.formSchema[0].fields.arcgis_group_name.readonly = true;
            this.formSchema[0].fields.select_existing_group.arcgisGroupId =
                this.arcgisGroupId;

            if (!this.arcgisGroupId) {
                this.formSchema[0].fields.arcgis_group_name.hidden = true;
            }
        }

        if (!this.organisationPk) {
            this.formSchema[0].fields.organisation = {
                component: 'select-field',
                id: 'project-organisation-input',
                label: 'Organisation',
                placeholder: 'Project Organisation',
                required: true,
                multiple: false,
                optionsUrl: this.organisationOptionsUrl,
                hidden: false,
            };
        }
    },
    mounted() {
        // Listen for the selected value from ProjectFormIsExistingGroup.
        this.$root.$on('is-existing-group', (value) => {
            this.disabled = false;

            if (value === 'N/A') {
                this.isGroupRequired = false;
                this.formSchema[0].fields.arcgis_group_name.hidden = true;
            } else {
                this.isGroupRequired = true;
                this.isExistingGroup = !!value;
                this.formSchema[0].fields.arcgis_group_name.readonly = !!value;
                this.formSchema[0].fields.arcgis_group_name.hidden = false;
                this.formSchema[0].fields.arcgis_group_name.required = true;
            }
        });

        // Listen for the selected value from ProjectFormSelectExistingGroup.
        this.$root.$on('row-selected', (data) => {
            const updatedFields = {
                arcgis_group_id: null,
                arcgis_group_name: null,
            };
            if (data && data.length) {
                updatedFields.arcgis_group_id = data[0].arcgis_group_id;
                updatedFields.arcgis_group_name = data[0].name;
                this.disabled = false;
            } else {
                this.disabled = true;
            }

            this.formData = {
                ...this.formData,
                ...updatedFields,
            };
        });
    },
    methods: {
        getFreshFormData() {
            const formData = {
                approved_date: DateTime.now().toFormat('yyyy-MM-dd'),
                payment_status: 'unpaid',
            };
            if (this.organisationPk) {
                formData.organisation = this.organisationPk;
            }
            return formData;
        },
        getFormData() {
            const formData = structuredClone(this.formData);

            if (!this.isGroupRequired) {
                delete formData.arcgis_group_id;
                delete formData.arcgis_group_name;
            } else if (!this.isExistingGroup) {
                delete formData.arcgis_group_id;
            }

            return formData;
        },
        getSuccessMessage() {
            return `Project '${this.formData.name}' was ${this.actionString} successfully.`;
        },
    },
});
</script>
