var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.modalId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modal",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              { staticClass: "modal-title", attrs: { id: "modalLabel" } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.modalTitle) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm._t("body", function () {
                return [
                  _vm._v(
                    "\n                    Are you sure you want to continue?\n                "
                  ),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "b-button",
                { attrs: { variant: "secondary", "data-dismiss": "modal" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.dismissButtonLabel) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    variant: _vm.buttonVariant,
                    "data-dismiss": "modal",
                  },
                  on: { click: _vm.actionAccepted },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.buttonLabel) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }