<script>
import Vue from 'vue/dist/vue.esm.js';

import { getDeserializedData } from '../../utils';
import DualTableSearchSelectForm from '../forms/DualTableSearchSelectForm.vue';

export default Vue.component('project-users-search-form', {
    extends: DualTableSearchSelectForm,
    data() {
        return {
            searchLabel: 'Search users.',
            sourceTableLabel: 'Click a user to add.',
            targetTableLabel: 'Click a user to remove.',
            tableFields: [
                { key: 'username', label: 'Users', tdClass: 'align-left' },
            ],
            selectedTableFields: [
                {
                    key: 'username',
                    label: 'Project users',
                    tdClass: 'align-left',
                },
            ],
            allowEmptySearch: true,
        };
    },
    methods: {
        getExistingSelections() {
            if (this.value) {
                return structuredClone(this.value);
            }
            if (this.initialFormDataElementId) {
                return getDeserializedData(this.initialFormDataElementId).users;
            }
            return [];
        },
    },
});
</script>
