<template>
    <b-modal
        :visible="showModal"
        title="Reorder project components"
        ok-title="Save"
        size="lg"
        centered
        scrollable
        @hidden="$emit('hidden')"
    >
        <b-alert v-if="errorMessage" :variant="alertVariant" dismissible show>
            {{ errorMessage }}
        </b-alert>

        <table class="table table-striped">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>URL</th>
                    <th></th>
                </tr>
            </thead>

            <draggable
                v-model="items"
                tag="tbody"
                :class="{ 'is-dragging': dragging }"
                @start="dragging = true"
                @end="dragging = false"
            >
                <tr v-for="item in items" :key="item.pk">
                    <td class="align-middle">
                        {{ item.pk }}
                    </td>
                    <td class="align-middle">
                        {{ item.name }}
                    </td>
                    <td class="align-middle">
                        {{ getCapitalisedText(item.type) }}
                    </td>
                    <td class="align-middle">
                        {{ item.url }}
                    </td>
                    <td class="align-middle">
                        <b-icon
                            icon="grid-3x2-gap"
                            variant="dark"
                            rotate="90"
                        ></b-icon>
                    </td>
                </tr>
            </draggable>
        </table>

        <template #modal-footer="{ cancel, close }">
            <b-button variant="secondary" @click="cancel()"> Cancel </b-button>
            <b-button variant="primary" @click="saveOrder(close)">
                Save
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import draggable from 'vuedraggable';

import { DEFAULT_ERROR_MESSAGE } from '../../constants';
import { csrfHeader } from '../../cookies';
import { getCapitalisedText } from '../../utils';

export default Vue.component('project-component-order-form', {
    components: { draggable },
    props: {
        apiUrl: VueTypes.string.isRequired,
        showModal: VueTypes.bool.isRequired,
    },
    data() {
        return {
            dragging: false,
            errorMessage: '',
            items: [],
        };
    },
    computed: {
        alertVariant() {
            return this.errorMessage ? 'danger' : 'info';
        },
        selectedIds() {
            return this.items.map((item) => item.pk);
        },
    },
    watch: {
        showModal(newValue) {
            if (newValue) {
                this.getData();
            }
        },
    },
    methods: {
        getCapitalisedText,
        getData() {
            axios
                .get(this.apiUrl)
                .then((response) => {
                    this.items = response.data.components;
                })
                .catch((error) => {
                    this.errorMessage = _get(
                        error,
                        'response.data.detail',
                        DEFAULT_ERROR_MESSAGE,
                    );
                });
        },
        saveOrder(closeFunction) {
            axios
                .post(
                    this.apiUrl,
                    { components: this.selectedIds },
                    { headers: { ...csrfHeader } },
                )
                .then(() => {
                    this.$emit('refresh-table');
                    closeFunction();
                })
                .catch((error) => {
                    this.errorMessage = _get(
                        error,
                        'response.data.detail',
                        DEFAULT_ERROR_MESSAGE,
                    );
                });
        },
    },
});
</script>

<style lang="scss" scoped>
.is-dragging tr {
    cursor: grabbing;
}

tr {
    cursor: grab;
}
</style>
