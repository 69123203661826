<template>
    <b-form-checkbox
        :value="adminModeEnabled"
        :button-variant="buttonVariant"
        name="admin-mode-toggle"
        class="admin-mode-button"
        button
        @change="toggleAdminMode"
    >
        {{ buttonText }}
    </b-form-checkbox>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { createNamespacedHelpers } from 'vuex';

import {
    MODULE_ADMIN,
    MUTATION_TOGGLE_IS_ADMIN_ENABLED,
} from '../store/modules/admin';

import IsAdminEnabledMixin from './mixins/is-admin-enabled';

const { mapMutations } = createNamespacedHelpers(MODULE_ADMIN);

export default Vue.component('admin-mode-toggle-button', {
    mixins: [IsAdminEnabledMixin],
    computed: {
        buttonText() {
            return this.adminModeEnabled
                ? 'Show Client View'
                : 'Show Admin View';
        },
        buttonVariant() {
            return this.adminModeEnabled ? 'outline-danger' : 'danger';
        },
    },
    methods: {
        toggleAdminMode() {
            this[MUTATION_TOGGLE_IS_ADMIN_ENABLED]();
            localStorage.setItem('adminModeEnabled', this.adminModeEnabled);
        },
        ...mapMutations([MUTATION_TOGGLE_IS_ADMIN_ENABLED]),
    },
});
</script>

<style lang="scss" scoped>
.admin-mode-button {
    // Override the `d-inline-block` that Vue Bootstrap tacks onto the component.
    display: block !important;

    ::v-deep .btn {
        display: block;
        width: 100%;
    }
}
</style>
