<template>
    <validation-provider
        #default="validationContext"
        :vid="name"
        :name="label"
        :rules="rules"
    >
        <b-form-group
            :label-for="id"
            :invalid-feedback="validationContext.errors[0]"
            :description="helpText"
            label-cols-md="4"
        >
            <template #label>
                {{ label }} <required-asterisk v-if="required" />
            </template>

            <b-form-textarea
                :id="id"
                v-model="jsonData"
                rows="4"
                :name="name"
                :required="required"
                :state="getValidationState(validationContext)"
                lazy-formatter
                :formatter="jsonFormatter"
                @blur="parseData"
            />
        </b-form-group>
    </validation-provider>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import { ValidationProvider } from 'vee-validate';

import RequiredAsterisk from './RequiredAsterisk.vue';

export default Vue.component('json-input-field', {
    components: {
        RequiredAsterisk,
        ValidationProvider,
    },
    props: {
        customValidationRules: VueTypes.object,
        id: VueTypes.string.isRequired,
        label: VueTypes.string.isRequired,
        name: VueTypes.string.isRequired,
        helpText: VueTypes.string,
        required: Boolean,
        type: VueTypes.string.def('text'),
        value: VueTypes.oneOfType([VueTypes.object, VueTypes.array]),
    },
    data() {
        return {
            jsonData: null,
            isValidState: null,
        };
    },
    computed: {
        rules() {
            return {
                required: this.required,
                validJson: this.jsonData,
                ...this.customValidationRules,
            };
        },
    },
    created() {
        if (this.value) {
            this.jsonData = JSON.stringify(this.value, null, 2);
        }
    },
    methods: {
        getValidationState({ dirty, errors, validated, valid = null }) {
            const state = dirty || validated ? valid && !errors.length : null;
            this.isValidState = state;
            return state;
        },
        parseData() {
            if (this.isValidState) {
                const parsedData = JSON.parse(this.jsonData);
                this.$emit('input', parsedData);
            }
        },
        jsonFormatter(text) {
            if (this.isValidState) {
                return JSON.stringify(JSON.parse(text), null, 2);
            }
            return text;
        },
    },
});
</script>
