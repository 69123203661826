<template>
    <div>
        <b-form @submit.prevent="search">
            <label for="search-field">
                {{ searchLabel }}
            </label>
            <div class="search-container">
                <b-form-input
                    id="search-field"
                    v-model="searchTermInProgress"
                    name="search-field"
                    :disabled="sourceTableIsBusy"
                />
                <a
                    v-if="searchTerm"
                    class="clear-search-button"
                    href="#"
                    draggable="false"
                    @click="clearSearchTerm()"
                >
                    <b-icon-x class="h4 mb-0" />
                </a>
                <b-button
                    :block="breakpoints.xs"
                    class="float-right col-sm-2"
                    type="submit"
                    :disabled="sourceTableIsBusy"
                >
                    Search
                </b-button>
            </div>
        </b-form>

        <div class="dual-table-container">
            <div class="source-table-container">
                {{ sourceTableLabel }}
                <continuous-scroll-api-search-table
                    ref="table"
                    class="table-container"
                    :api-url="apiUrl"
                    :fields="tableFields"
                    :params="params"
                    :selectable="selectable"
                    :select-mode="selectMode"
                    sticky-header
                    borderless
                    hover
                    small
                    @is-busy="(value) => (sourceTableIsBusy = value)"
                    @is-loading-more="
                        (value) => (sourceTableIsLoadingMore = value)
                    "
                    @row-selected="onRowSelected"
                >
                    <template #username="cellProps">
                        <b-spinner
                            v-if="sourceTableIsLoadingMore"
                            small
                            class="align-middle"
                        />
                        <a
                            v-else-if="cellProps.item.type === 'loadMore'"
                            href="#"
                            @click="() => loadNextPage(cellProps.item.nextPage)"
                        >
                            <i>Load more...</i>
                        </a>
                    </template>
                </continuous-scroll-api-search-table>
            </div>
            <div class="target-table-container">
                {{ targetTableLabel }}
                <b-table
                    class="table-container"
                    :items="selectedItems"
                    :fields="selectedTableFields"
                    selectable
                    select-mode="single"
                    :sticky-header="TABLE_DEFAULT_HEIGHT"
                    borderless
                    hover
                    small
                    :filter="searchTerm"
                    @row-selected="deselectItem"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import ContinuousScrollApiSearchTable from '../ContinuousScrollApiSearchTable.vue';
import BreakpointsMixin from '../mixins/breakpoints';
import { TABLE_DEFAULT_HEIGHT } from '../../constants';

export default Vue.component('dual-table-search-select-form', {
    components: { ContinuousScrollApiSearchTable },
    mixins: [BreakpointsMixin],
    props: {
        apiUrl: VueTypes.string,
        initialFormDataElementId: VueTypes.string,
        name: VueTypes.string.isRequired,
        value: VueTypes.any,
    },
    data() {
        return {
            TABLE_DEFAULT_HEIGHT,
            searchLabel: 'Search by name.',
            sourceTableLabel: '',
            targetTableLabel: '',
            items: [],
            tableFields: [],
            searchTerm: '',
            searchTermInProgress: '',
            params: {},
            selectable: true,
            selectMode: 'single',
            selectedItems: [],
            selectedTableFields: [],
            sourceTableIsBusy: false,
            sourceTableIsLoadingMore: false,
        };
    },
    watch: {
        selectedItems(newValue) {
            this.$root.$emit(`${this.name}-selection-updated`, newValue);
        },
    },
    created() {
        this.tableComponent = this.paginated
            ? 'paginated-api-search-table'
            : 'api-search-table';
    },
    mounted() {
        this.setParams();
        this.selectedItems = this.getExistingSelections();
    },
    methods: {
        getExistingSelections() {
            // Override within the component to modify how existing selection data is retrieved.
            return [];
        },
        search() {
            this.setParams();
            this.searchTerm = this.searchTermInProgress;
        },
        setParams() {
            this.params = {
                search: this.searchTermInProgress,
            };
        },
        onRowSelected(items) {
            // Ensure the duplicate items are not added to selectedItems list.
            // Not using a Set as Vue 2 does not support reactivity on Sets.
            items
                .filter((item) => item.type !== 'loadMore')
                .forEach((item) => {
                    if (
                        !this.selectedItems.find(
                            (selectedItem) => item.pk === selectedItem.pk,
                        )
                    ) {
                        this.selectedItems.push(item);
                    }
                });
            this.$refs.table.$refs.table.clearSelected();
        },
        deselectItem(item) {
            const index = this.selectedItems.indexOf(item[0]);
            if (index !== -1) {
                this.selectedItems.splice(index, 1);
            }
        },
        loadNextPage(nextPage) {
            this.params = {
                ...this.params,
                page: nextPage,
            };
        },
        clearSearchTerm() {
            this.searchTermInProgress = '';
            this.search();
        },
    },
});
</script>
<style lang="scss" scoped>
.search-container {
    display: flex;
    align-items: center;
}

.dual-table-container {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-areas: 'to-add to-remove';
    column-gap: 2%;
    padding-top: 15px;

    .source-table-container {
        grid-area: to-add;
    }

    .target-table-container {
        grid-area: to-remove;
    }

    .table-container {
        border: solid 1px #ccc;
        height: 60vh;
    }
}

.clear-search-button {
    transform: translateX(-30px);
    width: 0px;
    padding-top: 3px;
}
</style>
