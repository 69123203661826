import Vue from 'vue/dist/vue.esm.js';
import Vuex from 'vuex';

import { adminModule } from './modules/admin';
import { messagesModule } from './modules/messages';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: { adminModule, messagesModule },
});
