<template>
    <div v-show="enabled">
        <div class="clearfix">
            <b-form @submit.prevent="search">
                <b-form-group
                    label-for="search-field"
                    label-cols-md="4"
                    :description="helpText"
                >
                    <template #label>
                        {{ searchLabel }}
                    </template>
                    <div style="display: flex">
                        <b-form-input
                            id="search-field"
                            v-model="searchTermInProgress"
                            name="search-field"
                        />
                        <b-button
                            :block="breakpoints.xs"
                            class="float-right col-sm-2"
                            type="submit"
                        >
                            Search
                        </b-button>
                    </div>
                </b-form-group>
            </b-form>
        </div>

        <div
            v-if="showWarning && warningMessage"
            class="text-danger warning-message mb-1"
        >
            {{ warningMessage }}
        </div>

        <b-alert dismissible :show="!!displayMessage">
            {{ displayMessage }}
        </b-alert>

        <div class="table-container">
            <component
                :is="tableComponent"
                ref="table"
                class="mb-0"
                :api-url="apiUrl"
                :fields="tableFields"
                :params="params"
                :selectable="selectable"
                :select-mode="selectMode"
                :sticky-header="stickyHeader"
                @is-busy="onIsBusy"
                @row-selected="onRowSelected"
            >
                <template
                    v-for="{ key } in tableFields"
                    #[`cell(${key})`]="data"
                >
                    <slot v-bind="data" :name="key">
                        {{ key }}
                    </slot>
                </template>
                <template #url="cellProps">
                    <a :href="cellProps.item.url" target="_blank"
                        >View in ArcGIS</a
                    >
                </template>
                <template #actionsColumn="cellProps">
                    <b-button
                        :aria-label="`select ${cellProps.item.name}`"
                        class="rounded-circle"
                        variant="primary"
                        @click="actionItem(cellProps.item)"
                    >
                        <b-icon-check-lg />
                    </b-button>
                </template>
            </component>
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import ApiSearchTable from '../ApiSearchTable.vue';
import BreakpointsMixin from '../mixins/breakpoints';

export default Vue.component('api-search-select-form', {
    components: { ApiSearchTable },
    mixins: [BreakpointsMixin],
    props: {
        apiUrl: VueTypes.string,
        noSearchTermMessage: VueTypes.string.def(
            'Please enter a search term and press "Search".',
        ),
    },
    data() {
        return {
            helpText: '',
            searchLabel: '',
            items: [],
            tableFields: [],
            searchTerm: '',
            searchTermInProgress: '',
            enabled: true,
            displayMessage: '',
            params: {},
            selectable: false,
            selectMode: 'multi',
            tableComponent: '',
            paginated: false,
            allowEmptySearch: false,
            stickyHeader: true,
            showWarning: false,
            warningMessage: '',
        };
    },
    created() {
        this.tableComponent = this.paginated
            ? 'paginated-api-search-table'
            : 'api-search-table';
    },
    methods: {
        search() {
            if (this.searchTermInProgress || this.allowEmptySearch) {
                // Prevent searching when clearing the search field after having filled it,
                // unless explicitly allowed
                this.setParams();
            } else {
                this.displayMessage = this.noSearchTermMessage;
            }
        },
        actionItem(item) {
            this.$root.$emit('action', structuredClone(item));
        },
        setParams() {
            this.params = { search_term: this.search_term };
        },
        onIsBusy(value) {}, // eslint-disable-line no-unused-vars
        onRowSelected(items) {
            this.$root.$emit('row-selected', items);
        },
    },
});
</script>

<style lang="scss" scoped>
.warning-message {
    font-weight: bold;
    text-align: center;
}

.selected-items-container {
    .selected-item {
        border-radius: 25px;
        padding: 2px 10px;
        float: left;
        border: 2px solid #ebebeb;
        margin: 0 5px 10px 0;
        color: #6c757d;
    }

    .add-selected-button {
        margin-right: 15px;
    }
}

.table-container {
    border: solid 1px #ccc;
    margin-bottom: 20px;
}
</style>
