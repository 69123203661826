var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("import-project-users-file-form", {
        attrs: { "api-url": _vm.apiUrl },
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "sample-table clearfix" },
            [
              _c("span", { staticClass: "float-left col-sm-10 pl-0" }, [
                _vm._v(
                  "\n                Header (first row) should contain the following field names\n                exactly as they appear below\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "float-right col-sm-2",
                  attrs: { disabled: _vm.loadingSampleData },
                  on: { click: _vm.downloadCsvTemplate },
                },
                [
                  _vm._v(
                    "\n                Download CSV template\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("h5", [_vm._v("Required Fields")]),
          _vm._v(" "),
          _c("b-table", {
            attrs: {
              items: _vm.sampleData,
              busy: _vm.loadingSampleData,
              bordered: "",
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _vm._v(" "),
                        _c("strong", [_vm._v("Loading...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }