var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.search.apply(null, arguments)
            },
          },
        },
        [
          _c("label", { attrs: { for: "search-field" } }, [
            _vm._v("\n            " + _vm._s(_vm.searchLabel) + "\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-container" },
            [
              _c("b-form-input", {
                attrs: {
                  id: "search-field",
                  name: "search-field",
                  disabled: _vm.sourceTableIsBusy,
                },
                model: {
                  value: _vm.searchTermInProgress,
                  callback: function ($$v) {
                    _vm.searchTermInProgress = $$v
                  },
                  expression: "searchTermInProgress",
                },
              }),
              _vm._v(" "),
              _vm.searchTerm
                ? _c(
                    "a",
                    {
                      staticClass: "clear-search-button",
                      attrs: { href: "#", draggable: "false" },
                      on: {
                        click: function ($event) {
                          return _vm.clearSearchTerm()
                        },
                      },
                    },
                    [_c("b-icon-x", { staticClass: "h4 mb-0" })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "float-right col-sm-2",
                  attrs: {
                    block: _vm.breakpoints.xs,
                    type: "submit",
                    disabled: _vm.sourceTableIsBusy,
                  },
                },
                [_vm._v("\n                Search\n            ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dual-table-container" }, [
        _c(
          "div",
          { staticClass: "source-table-container" },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.sourceTableLabel) + "\n            "
            ),
            _c("continuous-scroll-api-search-table", {
              ref: "table",
              staticClass: "table-container",
              attrs: {
                "api-url": _vm.apiUrl,
                fields: _vm.tableFields,
                params: _vm.params,
                selectable: _vm.selectable,
                "select-mode": _vm.selectMode,
                "sticky-header": "",
                borderless: "",
                hover: "",
                small: "",
              },
              on: {
                "is-busy": function (value) {
                  return (_vm.sourceTableIsBusy = value)
                },
                "is-loading-more": function (value) {
                  return (_vm.sourceTableIsLoadingMore = value)
                },
                "row-selected": _vm.onRowSelected,
              },
              scopedSlots: _vm._u([
                {
                  key: "username",
                  fn: function (cellProps) {
                    return [
                      _vm.sourceTableIsLoadingMore
                        ? _c("b-spinner", {
                            staticClass: "align-middle",
                            attrs: { small: "" },
                          })
                        : cellProps.item.type === "loadMore"
                        ? _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function () {
                                  return _vm.loadNextPage(
                                    cellProps.item.nextPage
                                  )
                                },
                              },
                            },
                            [_c("i", [_vm._v("Load more...")])]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "target-table-container" },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.targetTableLabel) + "\n            "
            ),
            _c("b-table", {
              staticClass: "table-container",
              attrs: {
                items: _vm.selectedItems,
                fields: _vm.selectedTableFields,
                selectable: "",
                "select-mode": "single",
                "sticky-header": _vm.TABLE_DEFAULT_HEIGHT,
                borderless: "",
                hover: "",
                small: "",
                filter: _vm.searchTerm,
              },
              on: { "row-selected": _vm.deselectItem },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }