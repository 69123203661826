<template>
    <b-form-group :label-for="id" :description="helpText" label-cols-md="4">
        <template #label>
            {{ label }}
        </template>

        <div class="modal-value-preview">
            <i>{{ modalValuePreview }}</i>
        </div>

        <b-button :id="id" v-b-modal="modalId" :disabled="disabled">
            {{ modalLabel }}
        </b-button>

        <b-modal :id="modalId" :title="modalLabel" size="lg" @ok="handleOk">
            <component
                :is="modalComponent"
                :api-url="modalApiUrl"
                :name="name"
                :value="modalValue"
            />
        </b-modal>
    </b-form-group>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

export default Vue.component('modal-field', {
    props: {
        id: VueTypes.string.isRequired,
        label: VueTypes.string.isRequired,
        name: VueTypes.string.isRequired,
        helpText: VueTypes.string,
        modalLabel: VueTypes.string,
        modalComponent: VueTypes.string.isRequired,
        modalApiUrl: VueTypes.string,
        value: VueTypes.any,
        disabled: Boolean,
    },
    data() {
        return {
            isValidState: null,
            modalValue: null,
            currentmodalValue: null,
        };
    },
    computed: {
        modalId() {
            return `modal-${this.id}`;
        },
        modalValuePreview() {
            if (this.modalValue) {
                let displayValue = this.modalValue
                    .slice(0, 10)
                    .map((item) => item.name)
                    .join(', ');
                displayValue += this.modalValue.length > 10 ? '…' : '';
                return displayValue;
            }
            return '';
        },
    },
    mounted() {
        this.modalValue = this.value;
        // Listen for the selected projects from modal component.
        this.$root.$on(`${this.name}-selection-updated`, (value) => {
            this.currentmodalValue = value;
        });
    },
    methods: {
        handleOk() {
            this.modalValue = this.currentmodalValue;
            this.$root.$emit(`${this.name}-value-submitted`, this.modalValue);
        },
    },
});
</script>
<style lang="scss" scoped>
.modal-value-preview {
    color: #6c757d;
}
</style>
