import { DateTime } from 'luxon';

export const getDeserializedData = (dataElementId) =>
    JSON.parse(document.getElementById(dataElementId).textContent);

/**
 * Format a datetime string using moment-timezone.
 *
 * @param datetimeString The datetime string to format.
 * @param toFormat The desired output format.
 * @param fromFormat The expected input format, defaults to ISO 8601 format `yyyy-MM-ddTHH:mm:ss.SSSSZ`.
 * @returns {String}
 *
 * Note: Strict mode parsing is one, so errors will be raised if
 * the `datetimeString` format doesn't match `fromFormat`.
 */
export const formatDatetime = (datetimeString, toFormat, fromFormat = null) => {
    const dt = fromFormat
        ? DateTime.fromFormat(datetimeString, fromFormat)
        : DateTime.fromISO(datetimeString);
    return dt.toFormat(toFormat);
};

export const formatDatetimeToDefault = (datetimeString) =>
    datetimeString ? formatDatetime(datetimeString, 'dd/MM/yyyy HH:mm') : '';

export const formatDateToDefault = (dateString) =>
    dateString ? formatDatetime(dateString, 'dd/MM/yyyy') : '';

export const getBooleanIcon = (item, fieldName) => {
    return item[fieldName] ? 'b-icon-check-circle' : 'b-icon-x-circle';
};

export const getCapitalisedText = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getBooleanText = (item, fieldName) => {
    return item[fieldName] ? 'Yes' : 'No';
};

export const getBooleanVariant = (item, fieldName) => {
    return item[fieldName] ? 'success' : 'danger';
};

export const getPublishedIcon = (item) => {
    return getBooleanIcon(item, 'is_published');
};

export const getPublishedText = (item) => {
    return item.is_published ? 'Published' : 'Unpublished';
};

export const getPublishedVariant = (item) => {
    return getBooleanVariant(item, 'is_published');
};

export const getDateFromDatetimeString = (item) => {
    return item ? formatDatetime(item, 'dd/MM/yyyy') : '';
};

export const stripHtml = (value) => {
    const div = document.createElement('div');
    div.innerHTML = value;
    const text = div.innerText;
    div.remove();
    return text;
};
