// Vuex store for admin related state/actions.
export const MODULE_ADMIN = 'adminModule';

export const IS_ADMIN_ENABLED = 'isAdminEnabled';

export const MUTATION_SET_IS_ADMIN_ENABLED = 'setIsAdminEnabled';
export const MUTATION_TOGGLE_IS_ADMIN_ENABLED = 'toggleIsAdminEnabled';

const state = {
    [IS_ADMIN_ENABLED]: false,
};

const mutations = {
    [MUTATION_SET_IS_ADMIN_ENABLED](state, value) {
        state[IS_ADMIN_ENABLED] = value;
    },
    [MUTATION_TOGGLE_IS_ADMIN_ENABLED](state) {
        state[IS_ADMIN_ENABLED] = !state[IS_ADMIN_ENABLED];
    },
};

export const adminModule = {
    namespaced: true,
    state,
    mutations,
};
