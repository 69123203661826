<template>
    <div>
        <span class="text-danger"> *<em class="sr-only">Required</em> </span>
        indicates a required field.
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';

export default Vue.component('required-field-hint', {});
</script>
