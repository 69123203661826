var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.adminModeEnabled
        ? _c("paginated-api-table", {
            attrs: {
              refresh: _vm.refreshTable,
              "api-url": _vm.apiUrl,
              fields: _vm.adminTableFields,
              "initial-count": _vm.initialCount,
              "initial-data-element-id": _vm.initialDataElementId,
              "initial-num-pages": _vm.initialNumPages,
              "initial-page-size": _vm.initialPageSize,
            },
            on: {
              refreshed: function ($event) {
                _vm.refreshTable = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "name",
                  fn: function (cellProps) {
                    return [
                      _c(
                        "a",
                        { attrs: { href: cellProps.item.absolute_url } },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(cellProps.item.name) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "actionsColumn",
                  fn: function (cellProps) {
                    return [
                      _c(
                        "b-button",
                        {
                          staticClass: "rounded-circle",
                          attrs: {
                            href: cellProps.item.update_url,
                            "aria-label": "Edit " + cellProps.item.name,
                            variant: "primary",
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                      _vm._v(" "),
                      _c("delete-button", {
                        attrs: {
                          "item-to-delete": cellProps.item,
                          "item-name": cellProps.item.name,
                        },
                        on: {
                          "item-deleted": function ($event) {
                            _vm.refreshTable = true
                          },
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              405829492
            ),
          })
        : _c("paginated-card-list", {
            attrs: {
              "api-url": _vm.apiUrl,
              "initial-count": _vm.initialCount,
              "initial-data-element-id": _vm.initialDataElementId,
              "initial-num-pages": _vm.initialNumPages,
              "initial-page-size": _vm.initialPageSize,
            },
            scopedSlots: _vm._u([
              {
                key: "listBody",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      { staticClass: "row mt-5 d-flex" },
                      _vm._l(props, function (project) {
                        return _c("card-button", {
                          key: project.pk,
                          attrs: {
                            title: project.name,
                            subheading: project.subheading,
                            url: project.absolute_url,
                            logo: project.logo,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
              {
                key: "noData",
                fn: function () {
                  return [
                    _vm._v(
                      "\n            There are currently no projects available. Please refresh the\n            page and try again, or contact an administrator.\n        "
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }