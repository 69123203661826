import Vue from 'vue';
import debounce from 'lodash/debounce';

const screens = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
};

const xs = (val) => val <= screens.sm;
const sm = (val) => val >= screens.sm && val <= screens.md;
const md = (val) => val >= screens.md && val <= screens.lg;
const lg = (val) => val >= screens.lg && val <= screens.xl;
const xl = (val) => val >= screens.xl && val <= screens.xxl;
const xxl = (val) => val >= screens.xl;

const getBreakpoint = (w) => {
    if (xs(w)) {
        return 'xs';
    } else if (sm(w)) {
        return 'sm';
    } else if (md(w)) {
        return 'md';
    } else if (lg(w)) {
        return 'lg';
    } else if (xl(w)) {
        return 'xl';
    } else if (xxl(w)) {
        return 'xxl';
    } else {
        return 'all';
    }
};

const breakpoints = Vue.observable({
    w: window.innerWidth,
    h: window.innerHeight,
    is: getBreakpoint(window.innerWidth),
    xs: getBreakpoint(window.innerWidth) === 'xs',
    sm: getBreakpoint(window.innerWidth) === 'sm',
    md: getBreakpoint(window.innerWidth) === 'md',
    lg: getBreakpoint(window.innerWidth) === 'lg',
    xl: getBreakpoint(window.innerWidth) === 'xl',
    xxl: getBreakpoint(window.innerWidth) === 'xxl',
});

window.addEventListener(
    'resize',
    debounce(
        () => {
            breakpoints.w = window.innerWidth;
            breakpoints.h = window.innerHeight;
            breakpoints.is = getBreakpoint(window.innerWidth);

            breakpoints.xs = false;
            breakpoints.sm = false;
            breakpoints.md = false;
            breakpoints.lg = false;
            breakpoints.xl = false;
            breakpoints.xxl = false;
            breakpoints[breakpoints.is] = true;
        },
        400,
        { trailing: true },
    ),
    { passive: true },
);

export default breakpoints;
