<template>
    <radio-field-group
        id="is-existing-group"
        v-model="value"
        help-text="A new Group in Portal will be created if required."
        label="Is there an existing Portal Group?"
        name="is_existing_group"
        required
        :options="[
            { text: 'Yes', value: true },
            { text: 'No', value: false },
            { text: 'Not required', value: 'N/A' },
        ]"
    />
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';

import RadioFieldGroup from '../forms/RadioFieldGroup.vue';

export default Vue.component('project-form-is-existing-group', {
    components: {
        RadioFieldGroup,
    },
    props: {
        initial: VueTypes.any,
    },
    data() {
        return {
            value: this.initial,
        };
    },
    watch: {
        value(newValue) {
            this.$root.$emit('is-existing-group', newValue);
        },
    },
});
</script>
