var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.adminModeEnabled
        ? _c("paginated-api-table", {
            attrs: {
              "api-url": _vm.apiUrl,
              fields: _vm.adminTableFields,
              "initial-count": _vm.initialCount,
              "initial-data-element-id": _vm.initialDataElementId,
              "initial-num-pages": _vm.initialNumPages,
              "initial-page-size": _vm.initialPageSize,
              refresh: _vm.refreshTable,
            },
            on: {
              refreshed: function ($event) {
                _vm.refreshTable = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "name",
                  fn: function (cellProps) {
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(cellProps.item.name) +
                          "\n        "
                      ),
                    ]
                  },
                },
                {
                  key: "links",
                  fn: function (cellProps) {
                    return [
                      _c("div", { staticClass: "row d-flex flex-column" }, [
                        cellProps.item.url
                          ? _c("a", { attrs: { href: cellProps.item.url } }, [
                              _vm._v(
                                "\n                    Component URL\n                "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  },
                },
                {
                  key: "actionsColumn",
                  fn: function (cellProps) {
                    return [
                      _c(
                        "b-button",
                        {
                          staticClass: "rounded-circle",
                          attrs: {
                            href: cellProps.item.update_url,
                            "aria-label": "Edit " + cellProps.item.name,
                            variant: "primary",
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                      _vm._v(" "),
                      _c("delete-button", {
                        attrs: {
                          "item-to-delete": cellProps.item,
                          "item-name": cellProps.item.name,
                        },
                        on: {
                          "item-deleted": function ($event) {
                            _vm.refreshTable = true
                          },
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              3546297216
            ),
          })
        : _c("paginated-card-list", {
            attrs: {
              "api-url": _vm.apiUrl,
              "initial-count": _vm.initialCount,
              "initial-data-element-id": _vm.initialDataElementId,
              "initial-num-pages": _vm.initialNumPages,
              "initial-page-size": _vm.initialPageSize,
            },
            scopedSlots: _vm._u([
              {
                key: "listBody",
                fn: function (props) {
                  return _vm._l(props, function (component) {
                    return _c(
                      "div",
                      { key: component.pk, staticClass: "d-flex" },
                      [
                        component.url
                          ? _c("project-component-card-button", {
                              key: component.pk,
                              attrs: {
                                title: component.name,
                                subheading: component.type,
                                description: component.description,
                                url: component.url,
                                logo: component.image,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  })
                },
              },
              {
                key: "noData",
                fn: function () {
                  return [
                    _vm._v(
                      "\n            There are currently no components available. Please refresh the\n            page and try again, or contact an administrator.\n        "
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
      _vm._v(" "),
      _c("project-component-order-form", {
        attrs: { "api-url": _vm.reorderApiUrl, "show-modal": _vm.showModal },
        on: {
          hidden: function ($event) {
            _vm.showModal = false
          },
          "refresh-table": function ($event) {
            _vm.refreshTable = true
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }