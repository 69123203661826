<template>
    <div>
        <b-button
            class="float-right mb-2"
            :disabled="loadingCsvData"
            @click="downloadCsv"
        >
            Export CSV
        </b-button>
        <paginated-api-table
            :api-url="apiUrl"
            :fields="tableFields"
            :initial-count="initialCount"
            :initial-data-element-id="initialDataElementId"
            :initial-num-pages="initialNumPages"
            :initial-page-size="initialPageSize"
        >
            <template #number="cellProps">
                <a :href="cellProps.item.update_url">
                    {{ cellProps.item.number }}
                </a>
            </template>
        </paginated-api-table>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import VueTypes from 'vue-types';
import axios from 'axios';
import { DateTime } from 'luxon';

import { formatDateToDefault } from '../../utils';
import PaginatedApiTable from '../PaginatedApiTable.vue';

export default Vue.component('proposal-list', {
    components: { PaginatedApiTable },
    props: {
        apiUrl: VueTypes.string.isRequired,
        initialCount: VueTypes.integer.def(0),
        initialDataElementId: VueTypes.string.isRequired,
        initialNumPages: VueTypes.integer.def(1),
        initialPageSize: VueTypes.integer.def(20),
        downloadApiUrl: VueTypes.string.isRequired,
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'number',
                    label: 'Proposal Number',
                    tdClass: 'align-middle',
                    filter: { type: 'header-search-field' },
                },
                {
                    key: 'name',
                    label: 'Proposal Name',
                    tdClass: 'align-middle',
                    filter: { type: 'header-search-field' },
                },
                {
                    key: 'date',
                    label: 'Proposal Date',
                    formatter: formatDateToDefault,
                    tdClass: 'align-middle',
                    sortable: true,
                },
                {
                    key: 'proposed_by',
                    label: 'Proposed By',
                    tdClass: 'align-middle',
                },
                {
                    key: 'estimated_project_cost',
                    label: 'Estimated Project Cost',
                    formatter: (value) => value && `$${value}`,
                    tdClass: 'align-middle',
                    sortable: true,
                },
                {
                    key: 'service_fee',
                    label: 'Service Fee',
                    formatter: (value) => value && `$${value}`,
                    tdClass: 'align-middle',
                    sortable: true,
                },
                {
                    key: 'total_fee',
                    label: 'Total Fee',
                    formatter: (value) => value && `$${value}`,
                    tdClass: 'align-middle',
                    sortable: true,
                },
                {
                    key: 'approved_by',
                    label: 'Approved By',
                    tdClass: 'align-middle',
                },
                {
                    key: 'status',
                    label: 'Status',
                    tdClass: 'align-middle',
                },
            ],
            loadingCsvData: false,
            loadedData: null,
        };
    },
    methods: {
        downloadCsv() {
            axios.get(this.downloadApiUrl).then(({ data }) => {
                let blob = new Blob([data], {
                    type: 'text/csv',
                });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `Proposal_Fee_${DateTime.now().toFormat(
                    'yyyy_MM_dd',
                )}.csv`;
                link.click();

                this.loadingCsvData = false;
            });
        },
    },
});
</script>
